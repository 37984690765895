import React, { useContext } from "react";
import { Settings } from "react-feather";
import { TableSettingsContext } from "../contexts/TableSettingsContext";

const TCSettingsIcon = ({ path }) => {
  const { setPath } = useContext(TableSettingsContext);

  return (
    <Settings
      className="feather align-middle me-3 float-end"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        setPath(path);
      }}
    />
  );
};

export default TCSettingsIcon;
