import { useState } from "react";
import { Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { formatDate } from "../../utils";
import moment from "moment-timezone";
import "react-datepicker/dist/react-datepicker.css";

const DateField = ({ value, onChange, onEditEnd, column, editClassName }) => {
  const [date, setDate] = useState(
    value ? moment.tz(value, "America/New_York").toDate() : undefined
  );

  const submit = () => {
    setTimeout(() => {
      onEditEnd();

      if (
        !date ||
        !moment(date).isValid() ||
        formatDate(date, column.dateType) === formatDate(value, column.dateType)
      ) {
        return;
      }
      onChange(moment(date).toISOString());
    }, 100);
  };

  return (
    <DatePicker
      selected={date}
      portalId="root-portal"
      startOpen
      autoFocus
      className={`edit ${editClassName}`}
      onChange={setDate}
      onBlur={onEditEnd}
      onSelect={(newValue) => {
        setDate(newValue);
        submit();
      }}
      onClickOutside={() => {
        setDate(undefined);
        onEditEnd();
      }}
      onCalendarClose={submit}
    >
      <div className="text-end p-2">
        <Button
          variant="outline-danger"
          size="sm"
          onClick={() => {
            onEditEnd();
            onChange(null);
          }}
        >
          Clear
        </Button>
      </div>
    </DatePicker>
  );
};

export default DateField;
