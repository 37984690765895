import React, { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { FilterModes, FilterModeTitles } from "../../../constants";

const FilterWithMode = ({
  name,
  type,
  placeholder,
  value: pValue,
  onChange,
}) => {
  const [value, setValue] = useState(pValue);

  const setDateValue = (field) => (event) => {
    const jsonValue = JSON.parse(value || "{}");
    jsonValue[field] = event.currentTarget.value;
    if (!jsonValue.mode) {
      jsonValue.mode = 1;
    }
    const newValue = JSON.stringify(jsonValue);
    setValue(newValue);
    onChange({ target: { value: newValue, name } });
  };

  const getDateValue = (field, defaultValue) => {
    const jsonValue = JSON.parse(value || "{}");
    return jsonValue[field] || defaultValue;
  };

  const mode = getDateValue("mode", 1);
  const isBetweenMode = +mode === FilterModes.Between;

  return (
    <InputGroup>
      <InputGroup.Text
        as={Form.Select}
        value={mode}
        className="date-filter-mode"
        onChange={setDateValue("mode")}
      >
        {Object.values(FilterModes).map((type) => (
          <option key={`${name}-${type}`} value={type}>
            {FilterModeTitles[type]}
          </option>
        ))}
      </InputGroup.Text>
      <Form.Control
        type={type}
        placeholder={placeholder}
        onChange={setDateValue(isBetweenMode ? "minValue" : "value")}
        value={getDateValue(isBetweenMode ? "minValue" : "value", "").substr(
          0,
          10
        )}
        name={name}
      />
      {isBetweenMode && (
        <Form.Control
          type={type}
          placeholder={placeholder}
          onChange={setDateValue("maxValue")}
          value={getDateValue("maxValue", "").substr(0, 10)}
          name={name}
        />
      )}
    </InputGroup>
  );
};

export default FilterWithMode;
