import { useContext, useState, useEffect } from "react";
import { Button, Spinner } from "react-bootstrap";
import Popconfirm from "../Popconfirm";
import { MessagesQueueContext } from "../../contexts/MessagesQueueContext";

const Messages = () => {
  const {
    queueLength,
    fetchQueueLength,
    fetchLoading,
    processLoading,
    execProcessQueue,
    deleteQueueMessages,
  } = useContext(MessagesQueueContext);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const handleMessageDelete = async () => {
    setShowDeleteConfirm(false);
    await deleteQueueMessages();
  };

  useEffect(() => {
    fetchQueueLength();
  }, [fetchQueueLength]);

  return (
    <div>
      <Popconfirm
        title="Are you sure?"
        show={showDeleteConfirm}
        onOk={handleMessageDelete}
        onCancel={() => setShowDeleteConfirm(false)}
      />
      <span style={{ marginRight: 20 }}>{queueLength} Messages in queue</span>
      <span style={{ marginRight: 20 }}>
        {queueLength ? (
          <>
            <Button onClick={execProcessQueue} variant="info">
              {processLoading ? (
                <>
                  Processing
                  <Spinner animation="border" size="sm" className="ms-2" />
                </>
              ) : (
                "Process"
              )}
            </Button>
            <Button variant="link" onClick={() => setShowDeleteConfirm(true)}>
              clear queue
            </Button>
          </>
        ) : (
          <Button onClick={fetchQueueLength} variant="info">
            refresh queue
            {fetchLoading && (
              <Spinner animation="border" size="sm" className="ms-2" />
            )}
          </Button>
        )}
      </span>
    </div>
  );
};

export default Messages;
