import {
  useState,
  useContext,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { Upload, X } from "react-feather";
import { PresetMessagesContext } from "../../../contexts/PresetMessagesContext";

const CampaignForm = forwardRef(({ onSubmit }, ref) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClose = () => setShow(false);

  const [campaign, setCampaign] = useState({});
  const [selectedFile, setSelectedFile] = useState();
  const { presetMessages, loadPresetMessages } = useContext(
    PresetMessagesContext
  );

  useImperativeHandle(
    ref,
    () => ({
      open(values) {
        setCampaign(values || {});
        setShow(true);
      },
    }),
    []
  );

  useEffect(() => {
    loadPresetMessages();
  }, [loadPresetMessages]);

  const onSelectFile = (e) => {
    if (!e.target.files?.length) {
      setSelectedFile(null);
      return;
    }

    setSelectedFile(e.target.files[0]);
    e.target.value = null;
  };

  const handleChange = (event) => {
    setCampaign({
      ...campaign,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{campaign.id ? "Update" : "Create"} Campaign</Modal.Title>
      </Modal.Header>
      <Form
        className="m-3"
        onSubmit={(event) => {
          event.preventDefault();
          setLoading(true);
          onSubmit(campaign, selectedFile)
            .then(handleClose)
            .finally(() => {
              setLoading(false);
            });
        }}
      >
        <Form.Group className="mb-3">
          <Form.Control
            className="mb-3"
            type="text"
            placeholder="Keyword"
            name="keyword"
            value={campaign.keyword}
            required
            onChange={handleChange}
          />
          <Form.Select
            className="mb-3"
            required
            name="message"
            defaultValue={campaign.message}
            onChange={handleChange}
          >
            <option value="" disabled>
              Message
            </option>
            {presetMessages.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        {!campaign.id && (
          <Form.Group>
            <input
              id="create-campaign-file-upload"
              type="file"
              hidden
              onChange={onSelectFile}
            />
            <label
              htmlFor="create-campaign-file-upload"
              className={`btn btn-${selectedFile ? "success" : "light"} mb-3`}
              style={{ cursor: "pointer" }}
            >
              {selectedFile ? (
                <X
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setSelectedFile(null);
                  }}
                />
              ) : (
                <Upload size={24} />
              )}
              <span className="ms-2">
                Select a <code>.csv</code> file
              </span>
            </label>
          </Form.Group>
        )}
        <Form.Check
          id="campaign-form-active-check"
          onChange={(event) =>
            setCampaign({
              ...campaign,
              active: event.target.checked,
            })
          }
          name="active"
          checked={campaign.active ?? true}
          label="Active"
        />
        <div style={{ display: "flex", justifyContent: "right" }}>
          <Button
            variant="secondary"
            type="button"
            style={{ marginRight: "10px" }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            disabled={
              loading ||
              !campaign.message ||
              !campaign.keyword ||
              (!campaign.id && !selectedFile)
            }
          >
            {loading && (
              <Spinner animation="border" size="sm" className="me-2" />
            )}
            {campaign.id ? "Update" : "Create"}
          </Button>
        </div>
      </Form>
    </Modal>
  );
});

export default CampaignForm;
