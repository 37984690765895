import React, { useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { PauseCircle, PlayCircle } from "react-feather";

const formatTime = (num) => num.toString().padStart(2, "0");

const CustomAudioPlayer = ({ src }) => {
  /** @type {React.MutableRefObject<HTMLAudioElement>} */
  const ref = useRef();
  const [playing, setPlaying] = useState(false);
  const [canPlay, setCanPlay] = useState(false);

  const seconds = ref.current?.duration;
  const minutes = Math.floor(seconds / 60);

  return (
    <>
      <audio ref={ref} hidden onCanPlay={() => setCanPlay(true)} src={src} />
      <Button
        onClick={() => {
          setPlaying(!playing);
          !playing ? ref.current.play() : ref.current.pause();
        }}
        variant="link"
        disabled={!canPlay}
      >
        {playing ? <PauseCircle /> : <PlayCircle />}
      </Button>
      {!!seconds && (
        <span>
          {minutes}:{formatTime(Math.floor(seconds % 60))}
        </span>
      )}
    </>
  );
};

export default CustomAudioPlayer;
