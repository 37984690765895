import React, { forwardRef, useRef, useState } from "react";
import { Popover, Form } from "react-bootstrap";
import FilterWithMode from "./FilterWithMode";
import EnumFilter from "./EnumFilter";
import FilterBoolean from "./FilterBoolean";
import { Check } from "react-feather";
import useClickOutside from "../../../hooks/useClickOutside";
import EmptyIcon from "./EmptyIcon";

const FilterPopup = forwardRef(
  (
    {
      close,
      onFilter,
      noEmpty,
      labels,
      filter,
      type,
      options,
      height,
      onFilterClear,
      enumType,
      ...props
    },
    ref
  ) => {
    const [value, setValue] = useState(filter || "");
    const popoverRef = useRef(null);
    useClickOutside(popoverRef, close);

    const handleCloseAndFilter = () => {
      close();
      if (type === "boolean" && value === "") {
        onFilterClear();
        return;
      }
      onFilter(value);
    };

    const handleFilterEmpty = () => {
      if (["date", "number"].includes(type)) {
        const obj = JSON.parse(value || "{}");
        obj.isEmpty = true;
        const newValue = JSON.stringify(obj);
        setValue(newValue);
        close();
        onFilter(newValue);
      } else {
        const newValue = "-1";
        setValue(newValue);
        close();
        onFilter(newValue);
      }
    };

    /** @type {React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>} */
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        handleCloseAndFilter();
      }
    };

    return (
      <Popover ref={ref} onClick={(e) => e.stopPropagation()} {...props}>
        <Popover.Body
          ref={popoverRef}
          className={`d-flex ${
            type === "enum" ? " overflow-auto flex-column" : "flex-row"
          }`}
          style={{ maxHeight: height - 60 }}
        >
          {["date", "number"].includes(type) ? (
            <Form.Control
              as={FilterWithMode}
              onChange={(e) => setValue(e.target.value)}
              value={value}
              type={type}
            />
          ) : type === "enum" ? (
            <Form.Control
              as={EnumFilter}
              onChange={(e) => setValue(e.target.value)}
              value={value}
              noEmpty={noEmpty}
              options={options}
              enumType={enumType}
            />
          ) : type === "boolean" ? (
            <Form.Control
              as={FilterBoolean}
              onChange={(e) => setValue(e.target.value)}
              value={value}
              labels={labels}
              options={options}
            />
          ) : (
            <Form.Control
              onKeyPress={handleKeyPress}
              onChange={(e) => setValue(e.target.value)}
              value={value}
            />
          )}
          <div className="d-flex flex-column">
            <Check
              onClick={handleCloseAndFilter}
              className="ms-2 hoverable"
              size={24}
              style={{ minHeight: 24 }}
            />
            {!["enum", "boolean"].includes(type) && !noEmpty && (
              <EmptyIcon
                onClick={handleFilterEmpty}
                className="ms-2 hoverable"
              />
            )}
          </div>
        </Popover.Body>
      </Popover>
    );
  }
);

export default FilterPopup;
