import { useState, useEffect, useContext, useRef } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { Upload, X } from "react-feather";
import CustomAudioPlayer from "../../../components/CustomAudioPlayer";
import { PresetMessagesContext } from "../../../contexts/PresetMessagesContext";

function MessagesForm({ show, handleClose, handleShow, message }) {
  const { createOrUpdateMessage } = useContext(PresetMessagesContext);
  const [newMessage, setNewMessage] = useState(message);
  const [loading, setLoading] = useState(false);

  const [selectedFile, setSelectedFile] = useState();
  const [fileObjectUrl, setFileObjectUrl] = useState();
  const fileObjectUrlRef = useRef();
  fileObjectUrlRef.current = fileObjectUrl;

  useEffect(() => {
    return () => {
      fileObjectUrlRef.current && URL.revokeObjectURL(fileObjectUrlRef.current);
    };
  }, []);

  useEffect(() => {
    setNewMessage(message);
    return () => {
      setSelectedFile(null);
    };
  }, [message]);

  const submitForm = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await createOrUpdateMessage(newMessage, selectedFile);
      handleClose();
    } catch (err) {}
    setLoading(false);
  };

  const newValues = (event) => {
    setNewMessage({
      ...newMessage,
      [event.target.name]: event.target.value,
    });
  };

  const onSelectFile = (e) => {
    if (!e.target.files?.length) {
      setSelectedFile(null);
      return;
    }

    setSelectedFile(e.target.files[0]);
    fileObjectUrl && URL.revokeObjectURL(fileObjectUrl);
    setFileObjectUrl(URL.createObjectURL(e.target.files[0]));
    e.target.value = null;
  };

  function checkIsActive(event) {
    setNewMessage({
      ...newMessage,
      [event.target.name]: event.target.checked,
    });
  }

  return (
    <>
      <Button variant="primary" onClick={handleShow} className="mb-3">
        Create Message
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {!message.id ? "New Message" : "Update Message"}
          </Modal.Title>
        </Modal.Header>
        <Form className="m-3" onSubmit={submitForm}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              className="mb-3"
              type="text"
              placeholder="Name"
              name="name"
              value={newMessage.name}
              required
              onChange={newValues}
            />
              <Form.Control
                className="mb-3"
                type="text"
                placeholder="Email subject"
                name="emailSubject"
                value={newMessage.emailSubject}
                onChange={newValues}
              />
            <Form.Control
              className="mb-3"
              as="textarea"
              type="text"
              placeholder="Email body"
              name="emailBody"
              value={newMessage.emailBody}
              onChange={newValues}
            />
            <Form.Control
              className="mb-3"
              as="textarea"
              type="text"
              placeholder="Text to speach/after playing file"
              name="say"
              value={newMessage.say}
              onChange={newValues}
            />
            <div className="d-flex justify-content-between">
              <div>
                {(fileObjectUrl || newMessage.playFileUrl) && (
                  <CustomAudioPlayer
                    src={fileObjectUrl || newMessage.playFileUrl}
                  />
                )}
              </div>
              <input
                id="play-file-upload"
                type="file"
                accept=".mp3,.wav"
                hidden
                onChange={onSelectFile}
              />
              <label
                htmlFor="play-file-upload"
                className={`btn btn-${
                  selectedFile ? "success" : "primary"
                } mb-3`}
                style={{ cursor: "pointer" }}
              >
                <span className="me-2">Upload audio file</span>
                {selectedFile ? (
                  <X
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setSelectedFile(null);
                      fileObjectUrl && URL.revokeObjectURL(fileObjectUrl);
                      setFileObjectUrl(null);
                    }}
                  />
                ) : (
                  <Upload size={24} />
                )}
              </label>
            </div>
            <Form.Control
              className="mb-3"
              as="textarea"
              rows={3}
              placeholder="Message"
              name="message"
              value={newMessage.message}              
              onChange={newValues}
            />
            <Form.Check
              id="message-active-checkbox"
              onChange={checkIsActive}
              name="active"
              label="Active"
            />
          </Form.Group>
          <div style={{ display: "flex", justifyContent: "right" }}>
            <Button
              variant="secondary"
              type="button"
              style={{ marginRight: "10px" }}
              onClick={handleClose}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={loading}
              className="d-flex align-items-center"
            >
              {loading && (
                <Spinner animation="border" size="sm" className="me-2" />
              )}
              {message.id ? "Update" : "Create"}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}
export default MessagesForm;
