import React, { useState } from "react";
import { Form, InputGroup, Button } from "react-bootstrap";
import { Eye, EyeOff } from "react-feather";

/** @param {Omit<import("react-bootstrap").FormControlProps, "type">} props */
const PasswordField = (props) => {
  const [show, setShow] = useState(false);

  const toggleShow = () => {
    setShow(!show);
  };

  return (
    <InputGroup className={props.isInvalid ? "is-invalid" : ""}>
      <Form.Control {...props} type={show ? "text" : "password"} />
      <Button
        size={props.size}
        variant="outline"
        className={`form-component-border${
          props.isInvalid ? " is-invalid" : ""
        }`}
        onClick={toggleShow}
      >
        {show ? <Eye /> : <EyeOff />}
      </Button>
    </InputGroup>
  );
};

export default PasswordField;
