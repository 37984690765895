import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Button, Modal, Form, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import { Upload, X } from "react-feather";
import { PresetMessagesContext } from "../../contexts/PresetMessagesContext";

const formatMessage = (message = "") => {
  if (message.length < 72) {
    return message;
  }

  const words = message.split(" ");

  const formatted = [];

  for (const word of words) {
    if (formatted.join(" ").length > 32) {
      break;
    }
    formatted.push(word);
  }
  formatted.push(". . .");
  const endIndex = formatted.length;
  for (const word of words.reverse()) {
    if (formatted.join(" ").length > 70) {
      break;
    }
    formatted.splice(endIndex, 0, word);
  }

  return formatted.join(" ");
};

const DistributionEventFrom = forwardRef((props, ref) => {
  const { onCreate } = props;
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const { presetMessages, loadPresetMessages } = useContext(
    PresetMessagesContext
  );
  const [familiesFile, setFamiliesFile] = useState();
  const initialValuesRef = useRef({});

  useImperativeHandle(
    ref,
    () => ({
      open(values) {
        initialValuesRef.current = values || {};
        setShow(true);
      },
    }),
    []
  );

  useEffect(() => {
    loadPresetMessages();
  }, [loadPresetMessages]);

  useEffect(() => {
    if (!show) {
      initialValuesRef.current = {};
    }
  }, [show]);

  const onSelectFile = (e) => {
    if (!e.target.files?.length) {
      setFamiliesFile(null);
      return;
    }

    setFamiliesFile(e.target.files[0]);
    e.target.value = null;
  };

  const formType = initialValuesRef.current.id ? "Update" : "Create";

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>{formType} Event</Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValuesRef.current}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            setLoading(true);
            try {
              await onCreate({ ...values, file: familiesFile });
              setShow(false);
            } catch (error) {
              const message =
                error?.[0]?.description ||
                error.message ||
                "Something went wrong";

              setStatus({ success: false });
              setErrors({ submit: message });
              setSubmitting(false);
            }
            setLoading(false);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
          }) => (
            <Form id="my-form" onSubmit={handleSubmit}>
              {errors.submit && (
                <div className="text-danger mb-2">{errors.submit}</div>
              )}
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  name="eventName"
                  value={values.eventName || ""}
                  isInvalid={Boolean(touched.eventName && errors.eventName)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <input
                id="families-file-upload"
                type="file"
                hidden
                onChange={onSelectFile}
              />
              {!initialValuesRef.current.id && (
                <label
                  htmlFor="families-file-upload"
                  className={`btn btn-${
                    familiesFile ? "success" : "primary"
                  } mb-3`}
                  style={{ cursor: "pointer" }}
                >
                  <span className="me-2">Import doordash file</span>
                  {familiesFile ? (
                    <X
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setFamiliesFile(null);
                      }}
                    />
                  ) : (
                    <Upload size={24} />
                  )}
                </label>
              )}
              {!initialValuesRef.current.id && (
                <Form.Group className="mb-3">
                  <Form.Label>Message</Form.Label>
                  <Form.Select
                    name="messageId"
                    value={values.messageId || ""}
                    isInvalid={Boolean(touched.messageId && errors.messageId)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  >
                    <option key="0" value="0">
                      -- select message --
                    </option>
                    {presetMessages.map((presetMessage) => (
                      <option key={presetMessage.id} value={presetMessage.id}>
                        {formatMessage(
                          presetMessage.name || presetMessage.message
                        )}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              )}
              <Form.Group className="mb-3">
                <Form.Label>Notes</Form.Label>
                <Form.Control
                  as="textarea"
                  name="notes"
                  value={values.notes || ""}
                  isInvalid={Boolean(touched.notes && errors.notes)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Form.Group>
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          disabled={loading}
          onClick={() => setShow(false)}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="primary"
          disabled={loading}
          form="my-form"
          style={{ textTransform: "uppercase" }}
        >
          {loading && <Spinner animation="border" size="sm" className="me-2" />}
          {formType}
        </Button>
      </Modal.Footer>
    </Modal>
  );
});

export default DistributionEventFrom;
