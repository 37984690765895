import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import {
  getFamilyHistory,
  postFamilyHistory,
} from "../../../services/families";
import { formatDate } from "../../../utils";

const HistoryTab = ({ item }) => {
  const [histories, setHistories] = useState([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newHistory, setNewHistory] = useState("");

  useEffect(() => {
    getFamilyHistory(item.id).then(setHistories);
  }, [item.id]);

  const handleHistoryPost = () => {
    setLoading(true);
    postFamilyHistory(item.id, newHistory)
      .then((result) => {
        console.log(result, "result");
      })
      .finally(() => {
        setShow(false);
        setLoading(false);
      });
  };

  return (
    <>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>Post family history</Modal.Header>
        <Modal.Body>
          <Form.Control
            as="textarea"
            placeholder="Family history . . ."
            value={newHistory}
            onChange={(e) => setNewHistory(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            disabled={loading}
            onClick={() => setShow(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={loading}
            onClick={handleHistoryPost}
          >
            {loading && (
              <Spinner animation="border" size="sm" className="me-2" />
            )}
            Post
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="d-flex justify-content-end">
        <Button size="sm" className="my-2" onClick={() => setShow(true)}>
          Post history
        </Button>
      </div>
      <div style={{ height: "calc(100% - 31px)", overflowX: "auto" }}>
        {histories.map((item) => (
          <React.Fragment key={item.id}>
            <div
              style={{ fontSize: 12 }}
              className="d-flex justify-content-between"
            >
              <div className="details-table-column-title">{item.log}</div>
              <div style={{ width: 110 }}>
                {formatDate(item.created, "dayTime")}
              </div>
            </div>
            <hr className="border my-1" />
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

export default HistoryTab;
