import React, { useEffect, useState } from "react";
import Select from "./Select";

const EnumField = ({
  value: defaultValue,
  onChange = () => {},
  column = {},
  onEditEnd,
}) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    if (defaultValue !== value) {
      setValue(defaultValue);
    }
    // for `value` cuz this useEffect is used for watching changes of `defaultValue`
    // eslint-disable-next-line
  }, [defaultValue]);

  return (
    <Select
      close={onEditEnd}
      onChange={(newValue) => {
        setValue(newValue);
        onChange(newValue);
      }}
      value={value}
      column={column}
    />
  );
};

export default EnumField;
