import axios from "../utils/axios";

/**
 * @typedef {{
 *  id: string;
 *  role: string;
 *  userName: string;
 *  email: string;
 *  phoneNumber: string;
 *  emailConfirmed: boolean;
 *  phoneNumberConfirmed: boolean;
 *  securityStamp: string;
 *  concurrencyStamp: string;
 *  twoFactorEnabled: boolean;
 *  lockoutEnd: any;
 *  lockoutEnabled: boolean;
 *  accessFailedCount: number;
 *  normalizedUserName: string;   // probably will not need
 *  normalizedEmail: string;      // probably will not need
 * }} User
 */

export function getUsers() {
  return axios.get("/users/getUsers");
}

export function login(email, password) {
  return axios.post("/auth/login", { email, password });
}

export function me() {
  return axios.get("/auth/me");
}

/**
 * @param {User} user
 * @returns {Promise<User>}
 */
export function registerUser(user) {
  return axios.post("/auth/register", user);
}

/**
 * @param {Partial<User>} user
 * @returns {Promise<User>}
 */
export function updateUser(user) {
  return axios.post("/users/updateUser", user);
}

/**
 * @param {string} id
 * @returns {Promise<void}
 */
export function deleteUser(id) {
  return axios.post("/auth/deleteUser", { id });
}

/**
 * @param {string} currentPassword
 * @param {string} newPassword
 * @returns {Promise<void}
 */
export function changePassword(currentPassword, newPassword) {
  return axios.post("/auth/changePassword", { currentPassword, newPassword });
}
