import React, { useContext } from "react";
import { Dropdown, Form, FormCheck } from "react-bootstrap";
import { UtilityContext } from "../../../contexts/UtilityContext";

const MoreFilters = ({
  onFilter,
  filters,
  onFilterClear,
  searchTerm,
  onSearchTermChange,
  onSearch,
}) => {
  const { dropdownValues } = useContext(UtilityContext);

  const activeOnlyChanged = (e) => {
    if (e.target.checked) {
      onFilter({ active: 1 });
    } else {
      onFilterClear("active");
    }
  };

  const excludeExceptionsChanged = (e) => {
    if (e.target.checked) {
      onFilter({ exception: "[-1]" });
    } else {
      onFilterClear("exception");
    }
  };

  return (
    <div className="d-flex align-items-center flex-wrap gap-2">
      <div className="d-flex">
        <Form.Control
          value={searchTerm}
          placeholder="Search term"
          style={{ width: 200, borderRadius: 5, marginRight: 4 }}
          onChange={(e) => onSearchTermChange(e.target.value)}
          onKeyPress={(e) => {
            if (e.code.indexOf("Enter") > -1) {
              onSearch();
            }
          }}
        />
        <Dropdown className="me-2 nav-item" align="end">
          <Dropdown.Toggle variant="light" id="dropdown-basic">
            {dropdownValues.masbiahLocationIdMap?.[
              JSON.parse(filters.masbiahLocationId || "[]")[0]
            ] || "Masbia Location"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {dropdownValues.masbiahLocationId?.map((item) => (
              <Dropdown.Item
                key={item.value}
                onClick={() =>
                  onFilter({ masbiahLocationId: JSON.stringify([item.value]) })
                }
                active={
                  JSON.parse(filters.masbiahLocationId || "[]")[0] ===
                  item.value
                }
              >
                {item.title}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="d-flex">
        <FormCheck
          id="active-families-only-filter"
          style={{ width: "max-content" }}
          label="Active families only"
          onChange={activeOnlyChanged}
          checked={filters.active === "1"}
        />
        <FormCheck
          id="exclude-exceptions-filter"
          style={{ width: "max-content" }}
          className="ms-2"
          label="Exclude Exceptions"
          checked={filters.exception === "[-1]"}
          onChange={excludeExceptionsChanged}
        />
      </div>
    </div>
  );
};

export default MoreFilters;
