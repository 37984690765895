import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Tab, Nav, Button } from "react-bootstrap";
import { X } from "react-feather";

const DetailsSidebar = forwardRef(
  (
    {
      height,
      tabs,
      activeKey,
      setActiveKey,
      onCloseRequest,
      singleRender = false,
    },
    ref
  ) => {
    const [open, setOpen] = useState(false);

    useImperativeHandle(
      ref,
      () => ({
        toggle(value) {
          setOpen((open) => !open || value);
        },
      }),
      []
    );

    useEffect(() => {
      if (open && tabs.length) {
        setActiveKey(tabs[0].key);
      }
    }, [open]);

    return (
      <div
        className={`details-container${open ? " details-container-open" : ""}`}
        style={{ height }}
      >
        <Tab.Container onSelect={setActiveKey} activeKey={activeKey}>
          <div className="close-button-container">
            <Nav variant="tabs">
              {tabs.map((tab) => (
                <Nav.Item key={`nav-item-${tab.key}`}>
                  <Nav.Link eventKey={tab.key}>{tab.title}</Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
            <Button variant="light" className="shadow btn-sm">
              <X onClick={onCloseRequest} />
            </Button>
          </div>
          <Tab.Content className="p-0" style={{ height: "calc(100% - 42px)" }}>
            {tabs.map((tab) => (
              <Tab.Pane
                key={`tab-pane-${tab.key}`}
                eventKey={tab.key}
                style={{ height: "100%" }}
              >
                {(singleRender || activeKey === tab.key) && <tab.Content />}
              </Tab.Pane>
            ))}
          </Tab.Content>
        </Tab.Container>
      </div>
    );
  }
);

export default DetailsSidebar;
