import { useContext, useEffect, useMemo, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { TableSettingsContext } from "../contexts/TableSettingsContext";
import getTaxCommissionColumns from "../pages/tables/columns/taxCommission";
import getRPIEColumns from "../pages/tables/columns/RPIE";
import families from "../pages/tables/columns/family";
import distributionColumns from "../pages/tables/columns/distribution";

const disableColumns = ["index"];

const columnsMap = {
  "tax-commission": getTaxCommissionColumns(),
  rpie: getRPIEColumns(),
  families: families(),
  distributions: distributionColumns().filter((i) => i.key !== "actions"),
};

const TCSettingsModal = () => {
  const {
    path,
    setPath,
    hiddenColumns: hiddenColumnsContext,
    setHiddenColumns: saveHiddenColumns,
  } = useContext(TableSettingsContext);
  const [hiddenColumns, setHiddenColumns] = useState({});
  const columns = useMemo(() => columnsMap[path] || [], [path]);

  useEffect(() => {
    if (!path) {
      return;
    }
    setHiddenColumns(hiddenColumnsContext[path] || {});
  }, [path]);

  const handleCheck = (e) => {
    setHiddenColumns({
      ...hiddenColumns,
      [e.target.name]: !e.target.checked,
    });
  };

  const handleSave = () => {
    saveHiddenColumns({ ...hiddenColumnsContext, [path]: hiddenColumns });
    setPath("");
  };

  const handleClose = () => {
    setPath("");
    setHiddenColumns(hiddenColumnsContext[path] || {});
  };
  return (
    <Modal show={!!path} onHide={handleClose} size="sm" scrollable>
      <Modal.Header closeButton />
      <Modal.Body className="m-3">
        {columns.map((column) => (
          <Form.Check
            id={`tc-hidden-columns-setting-modal-${column.key}`}
            key={`tc-hidden-columns-setting-modal-${column.key}`}
            type="checkbox"
            disabled={disableColumns.includes(column.key)}
            label={column.title}
            name={column.key}
            checked={!hiddenColumns[column.key]}
            onChange={handleCheck}
          />
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TCSettingsModal;
