import React, { useContext, useMemo } from "react";
import { Outlet } from "react-router-dom";

import Wrapper from "../components/Wrapper";
import Sidebar from "../components/sidebar/Sidebar";
import Main from "../components/Main";
import Navbar from "../components/navbar/Navbar";
import Content from "../components/Content";
import { AuthContext } from "../contexts/JWTContext";

import dashboardItems from "../components/sidebar/dashboardItems";

const Dashboard = ({ children }) => {
  const { user } = useContext(AuthContext);

  const dashboardItemsFiltered = useMemo(
    () =>
      dashboardItems.map((item) => ({
        title: item.title,
        pages: item.pages.filter(
          (page) => !page.role || (user && page.role.includes(user.role))
        ),
      })),
    [user]
  );

  return (
    <React.Fragment>
      <Wrapper>
        <Sidebar items={dashboardItemsFiltered} />
        <Main>
          <Navbar />
          <Content>
            {children}
            <Outlet />
          </Content>
        </Main>
      </Wrapper>
    </React.Fragment>
  );
};

export default Dashboard;
