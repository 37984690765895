import axios from "../utils/axios";
import { formatFamilyFilters } from "../utils";

export function getQueueLength() {
  return axios.get("/messageQueue/getQueueLength");
}

export function processQueue() {
  return axios.get("/messageQueue/process");
}

export function clearQueue() {
  return axios.post("/messageQueue/clearQueue");
}

/**
 * @typedef {{
 *  phone: string;
 *  created: string;
 * }} NotRecievedReportItem
 * @typedef {{
 *  date: string;
 *  items: Array<NotRecievedReportItem>
 * }} NotRecievedReport
 * @returns {Promise<{ count: number; items: Array<NotRecievedReport> }>}
 */
export function getNotRecievedReport(filters) {
  return axios.post("/Messages", formatFamilyFilters(filters));
}
