import React, { useCallback, useContext, useRef, useState } from "react";
import {
  createCampaignWithFile,
  getCampaignInfo,
  updateCampaign as updateCampaignApi,
} from "../services/campaign";
import { MessagesQueueContext } from "./MessagesQueueContext";

const initialState = {
  campaigns: [],
  loading: false,
  loadCampaigns: ({ forceLoad = false } = {}) => {},
  addCampaign: async (campaign, file) => {},
  updateCampaign: async (campaign) => {},
};

const CampaignContext = React.createContext(initialState);

function CampaignProvider({ children }) {
  const { fetchQueueLength } = useContext(MessagesQueueContext);
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState();
  const initedRef = useRef(false);

  const loadCampaigns = useCallback(({ forceLoad = false } = {}) => {
    if (initedRef.current && !forceLoad) {
      return;
    }
    setLoading(true);
    const wasInited = initedRef.current;
    initedRef.current = true;
    getCampaignInfo()
      .then(setCampaigns)
      .catch(() => {
        initedRef.current = wasInited;
      })
      .finally(() => setLoading(false));
  }, []);

  const addCampaign = async (campaign, file) => {
    setLoading(true);
    try {
      const newCampaign = await createCampaignWithFile(campaign, file);
      if (newCampaign) {
        setCampaigns((oldValue) => [...oldValue, newCampaign]);
      }
      fetchQueueLength();
    } catch (_err) {}
    setLoading(false);
  };

  const updateCampaign = async (campaign) => {
    setLoading(true);
    try {
      await updateCampaignApi(campaign);
      setCampaigns((oldValue) =>
        oldValue.map((item) =>
          item.id === campaign.id ? { ...item, ...campaign } : item
        )
      );
    } catch (_err) {}
    setLoading(false);
  };

  return (
    <CampaignContext.Provider
      value={{
        campaigns,
        loadCampaigns,
        loading,
        addCampaign,
        updateCampaign,
      }}
    >
      {children}
    </CampaignContext.Provider>
  );
}

export { CampaignProvider, CampaignContext };
