import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { Form, Overlay } from "react-bootstrap";
import { Check } from "react-feather";
import useClickOutside from "../../hooks/useClickOutside";
import { getEnumOption } from "../../utils";

const OverlayContent = forwardRef(
  (
    {
      options,
      handleOptionSelect,
      selectedOption,
      close,
      arrowProps: _arrowProps,
      allowCreate = false,
      ...props
    },
    ref
  ) => {
    const [optionToAdd, setOptionToAdd] = useState("");
    const containerRef = useRef();
    useClickOutside(containerRef, close);

    return (
      <div ref={ref} {...props} style={{ ...props.style, zIndex: 999 }}>
        <div ref={containerRef} className="custom-select-container">
          {options.map((option) => {
            const { value, title } = getEnumOption(option);

            return (
              <span
                key={value}
                onClick={() => handleOptionSelect(value)}
                className={`custom-select-option me-1${
                  value === selectedOption ? "" : " ms-3"
                }`}
              >
                {value === selectedOption && <Check size={12} />}{" "}
                {title || "(EMPTY)"}
              </span>
            );
          })}
          {allowCreate && (
            <Form.Control
              value={optionToAdd}
              size="sm"
              className="mt-2 mb-2"
              onChange={(e) => setOptionToAdd(e.target.value)}
              placeholder="Other . . ."
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleOptionSelect(optionToAdd);
                  setOptionToAdd("");
                }
              }}
            />
          )}
        </div>
      </div>
    );
  }
);

const booleanOptions = [
  { title: "Yes", value: true },
  { title: "No", value: false },
];

const Select = ({ column, value, close, onChange }) => {
  const [show, setShow] = useState(false);
  const target = useRef();
  const title = useMemo(() => {
    const values = column.values || booleanOptions;
    if (typeof values[0] === "object") {
      const selectedOption = values.find((item) => item.value === value);
      if (selectedOption) return selectedOption.title;
    }
    return value;
  }, [value]);

  const handleOptionSelect = (option) => {
    onChange(option);
    close();
  };

  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <>
      <span
        ref={target}
        onClick={close}
        className={
          column.getClassName ? column.getClassName(value) : "edit-placeholder"
        }
      >
        {title}
      </span>
      <Overlay target={target.current} show={show} placement="auto">
        <OverlayContent
          options={column.values || booleanOptions}
          handleOptionSelect={handleOptionSelect}
          selectedOption={value}
          close={close}
          allowCreate={column.form?.allowCreate}
        />
      </Overlay>
    </>
  );
};

export default Select;
