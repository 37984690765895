import { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Table, Column, Cell, HeaderCell } from "rsuite-table";
import { Trash, Edit2 } from "react-feather";
import CustomAudioPlayer from "../../../components/CustomAudioPlayer";
import Popconfirm from "../../../components/Popconfirm";
import MessagesForm from "./MessagesForm";
import useScreenSize from "../../../hooks/useScreenSize";
import { PresetMessagesContext } from "../../../contexts/PresetMessagesContext";

const initialState = {
  message: "",
  emailSubject: "",
  emailBody: "",
  playFileUrl: "",
  say: "",
  active: false,
};

function Messages() {
  const { presetMessages, loadPresetMessages, deleteMessage } = useContext(
    PresetMessagesContext
  );
  const [messageToDelete, setMessageToDelete] = useState();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [show, setShow] = useState(false);
  const { height } = useScreenSize();
  const tableHeight = Math.max(height - 150, 360);
  const [selectedMessage, setSelectedMessage] = useState(initialState);
  const handleClose = () => {
    setSelectedMessage(initialState);
    setShow(false);
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    loadPresetMessages();
  }, [loadPresetMessages]);

  const handleMessageDelete = () => {
    setShowDeleteConfirm(false);
    deleteMessage(messageToDelete);
  };

  return (
    <div>
      <div className="d-flex justify-content-between">
        <MessagesForm
          show={show}
          handleClose={handleClose}
          handleShow={handleShow}
          message={selectedMessage}
        />
      </div>
      <Popconfirm
        title="Are you sure?"
        show={showDeleteConfirm}
        onOk={handleMessageDelete}
        onCancel={() => setShowDeleteConfirm(false)}
      />
      <Table data={presetMessages} height={tableHeight}>
        <Column flexGrow={1} width={300}>
          <HeaderCell>Name</HeaderCell>
          <Cell dataKey="name" />
        </Column>
        <Column flexGrow={1} width={300}>
          <HeaderCell>Message</HeaderCell>
          <Cell dataKey="message" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Email Body</HeaderCell>
          <Cell dataKey="emailBody" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Email Subject</HeaderCell>
          <Cell dataKey="emailSubject" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Say</HeaderCell>
          <Cell dataKey="say" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Audio file</HeaderCell>
          <Cell dataKey="playFileUrl">
            {(rowData) =>
              !!rowData.playFileUrl && (
                <CustomAudioPlayer src={rowData.playFileUrl} />
              )
            }
          </Cell>
        </Column>
        <Column flexGrow={1} align="center" fixed="right">
          <HeaderCell>Actions</HeaderCell>
          <Cell>
            {(rowData) => (
              <>
                <Button
                  variant="outlined"
                  className="text-danger"
                  size="sm"
                  onClick={() => {
                    setShowDeleteConfirm(true);
                    setMessageToDelete(rowData.id);
                  }}
                >
                  <Trash size={18} />
                </Button>
                <Edit2
                  onClick={() => {
                    setSelectedMessage(rowData);
                    handleShow();
                  }}
                  style={{ cursor: "pointer", width: "18px" }}
                />
              </>
            )}
          </Cell>
        </Column>
      </Table>
    </div>
  );
}

export default Messages;
