import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import NotyfContext from "../../../contexts/NotyfContext";
import { PresetMessagesContext } from "../../../contexts/PresetMessagesContext";
import { messageFamilies } from "../../../services/families";

/**
 * @param {{
 *  count: number;
 *  getIdsByFilter: () => Promise<Array<number>>;
 * }} props
 * @returns
 */
const SendMessageToFamilies = (props) => {
  const notyf = useContext(NotyfContext);
  const { presetMessages, loadPresetMessages } = useContext(
    PresetMessagesContext
  );
  const [show, setShow] = useState(false);
  const [messageId, setMessageId] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadPresetMessages();
  }, [loadPresetMessages]);

  const handleClose = () => {
    setShow(false);
    setMessageId(0);
  };

  const handleSend = async () => {
    try {
      const familyIds = await props.getIdsByFilter();
      await messageFamilies(messageId, familyIds);
      handleClose();
      notyf.success("Messages added to queue!");
    } catch (err) {
      notyf.error("Failed to send the message!");
    }
    setLoading(false);
  };

  return (
    <div>
      <Modal show={show && !!props.count} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {`Send Message to ${props.count} ${
              props.count === 1 ? "family" : "families"
            }`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Message</Form.Label>
            <Form.Select
              name="messageId"
              value={messageId}
              onChange={(e) => setMessageId(+e.target.value)}
            >
              <option key="0" value="0">
                -- select message --
              </option>
              {presetMessages.map((presetMessage) => (
                <option key={presetMessage.id} value={presetMessage.id}>
                  {presetMessage.name || presetMessage.message}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" disabled={loading} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={!messageId || loading}
            onClick={handleSend}
          >
            {loading && (
              <Spinner animation="border" size="sm" className="me-2" />
            )}
            SEND
          </Button>
        </Modal.Footer>
      </Modal>
      <Button onClick={() => setShow(true)}>Send message</Button>
    </div>
  );
};

export default SendMessageToFamilies;
