import * as React from "react";
import { Navigate } from "react-router-dom";
import Loader from "../../components/Loader";

import useAuth from "../../hooks/useAuth";

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }) {
  const { isAuthenticated, isInitialized } = useAuth();

  if (!isInitialized) {
    return <Loader />;
  }

  if (isInitialized && !isAuthenticated) {
    return <Navigate to="/auth/login" />;
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export default AuthGuard;
