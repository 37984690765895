import { createContext, useCallback, useRef, useState } from "react";
import { getAllDefinitions } from "../services/RPIE";
import { getAllOptions, tcOptionsKeys } from "../services/utility";
import { getDropdownValues, getExceptions } from "../services/utils";
import keyBy from "lodash/keyBy";

const rpieNumberDropdowns = [
  "SupplementalSFStatus",
  "StorefrontRegistryStatus",
  "RentRollStatus",
  "PayStatus",
  "IEStatusOptions",
  "FilingStatusOptions",
];

export const dropdownValuesKeyMap = {
  PhoneContactMethod: "phoneContactMethod",
  locations: "masbiahLocationId",
};

const enumToArray = (e, stringAsValue = false) =>
  Object.keys(e).map((key) => ({
    title: e[key].name
      ? `${e[key].displayNumber || e[key].number} - ${e[key].name}`
      : e[key],
    value: e[key].name ? (stringAsValue ? e[key].name : e[key].number) : e[key],
  }));

const enumToBGValues = (e) => {
  const bgValues = {};
  Object.keys(e).forEach((key) => {
    bgValues[e[key].number] = e[key].color || "none";
  });
  return bgValues;
};

const UtilityContext = createContext(null);

function UtilityProvider({ children }) {
  const [utilities, setUtilities] = useState({ inited: false });
  const [rpieUtilities, setRpieUtilities] = useState({ rpieInited: false });
  const [dropdownValues, setDropdownValues] = useState({ inited: false });
  const [exceptionOptions, setExceptionOptions] = useState([]);
  const exceptionOptionsInitedRef = useRef(false);

  const init = async () => {
    if (utilities.inited) {
      return;
    }
    const enums = await getAllOptions();
    const optionArrays = {};
    const backgroundByValues = {};

    Object.keys(enums).forEach((key) => {
      optionArrays[key] = enumToArray(
        enums[key],
        key === "clipadData.iestatusNotRequiredReason"
      );
    });

    Object.keys(enums).forEach((key) => {
      backgroundByValues[key] = enumToBGValues(enums[key]);
    });

    Object.keys(enums).forEach((key) => {
      if (tcOptionsKeys.includes(key)) {
        delete enums[key];
      }
    });

    setUtilities({
      enums,
      optionArrays,
      backgroundByValues,
      inited: true,
    });
  };

  const rpieInit = async () => {
    if (rpieUtilities.rpieInited) {
      return;
    }
    const newRpieUtilities = {
      rpieOptionArrays: {},
      rpieDefinitions: {},
      rpieEnums: {},
      rpieInited: true,
      rpieBackgroundByValue: {},
    };
    const definitions = await getAllDefinitions();
    definitions.forEach((definition) => {
      if (definition.key === "colorMap") {
        const colorMap = {};
        definition.value.forEach((colorItem) => {
          colorMap[colorItem.key] = `#${colorItem.value}`;
        });
        newRpieUtilities.rpieBackgroundByValue = colorMap;
        return;
      }
      newRpieUtilities.rpieEnums[definition.key] = keyBy(
        definition.value,
        "value"
      );
      newRpieUtilities.rpieDefinitions[definition.key] = definition.value;
      newRpieUtilities.rpieOptionArrays[definition.key] = definition.value.map(
        (i) =>
          rpieNumberDropdowns.includes(definition.key)
            ? { title: `${i.value} - ${i.key}`, value: i.value }
            : i.value
      );
    });

    setRpieUtilities(newRpieUtilities);
  };

  const initDropdownValues = async () => {
    const values = await getDropdownValues();
    const newDropdownValues = {};
    Object.keys(values).forEach((key) => {
      newDropdownValues[dropdownValuesKeyMap[key] || key] = Object.keys(
        values[key]
      ).map((value) => ({ title: values[key][value], value }));
      if (dropdownValuesKeyMap[key]) {
        newDropdownValues[`${dropdownValuesKeyMap[key]}Map`] = values[key];
      }
    });
    setDropdownValues({ ...newDropdownValues, inited: true });
  };

  const initExceptionOptions = useCallback((force = false) => {
    if (exceptionOptionsInitedRef.current && !force) {
      return;
    }
    const oldInitedValue = exceptionOptionsInitedRef.current;
    exceptionOptionsInitedRef.current = true;
    getExceptions()
      .then(setExceptionOptions)
      .catch(() => {
        exceptionOptionsInitedRef.current = oldInitedValue;
      });
  }, []);

  return (
    <UtilityContext.Provider
      value={{
        ...utilities,
        init,
        ...rpieUtilities,
        rpieInit,
        initDropdownValues,
        dropdownValues,
        initExceptionOptions,
        exceptionOptions,
      }}
    >
      {children}
    </UtilityContext.Provider>
  );
}

export { UtilityContext, UtilityProvider };
