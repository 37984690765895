import axios from "../utils/axios";

const utilityOptions = [
  "ApplicationStatus",
  "InHouseStatus",
  "RR_Status",
  "NewClientStatus",
  // "IncomeExpensesStatus",
  "TC_150_Status",
  "TC_200_230_Status",
  "TC_309_Status",
];
const tcOptions = [
  "IENotRequiredReasons",
  "PassReasons",
  "TC200Reasons",
  "TCHearings",
  "BuildingClasses",
  "TaxClasses",
];

const options = {
  applicationStatus: "ApplicationStatus",
  inHouseStatus: "InHouseStatus",
  rrstatus: "RR_Status",
  newClientStatus: "NewClientStatus",
  // "clipadData.iestatusNotRequiredReason": "IncomeExpensesStatus",
  "clipadData.tc150status": "TC_150_Status",
  "clipadData.tc200status": "TC_200_230_Status",
  "clipadData.tc309status": "TC_309_Status",
  "clipadData.iestatusNotRequiredReason": "IENotRequiredReasons",
  "clipadData.currentYearPassReason": "PassReasons",
  "clipadData.tc200reason": "TC200Reasons",
  "clipadData.previousYearHearingStatus": "TCHearings",
  "clipadData.propertyBldgClass": "BuildingClasses",
  "clipadData.propertyTaxClass": "TaxClasses",
  iestatus: "IEStatuses",
};

export const tcOptionsKeys = Object.keys(options).filter((key) =>
  tcOptions.includes(options[key])
);

const getOptions = (option) => axios.get(`/utility/get${option}Options`);
const getTcOptions = (option) => axios.get(`/taxCommision/get${option}`);

/**
 * @param {Array<string>} arr
 * @returns {Record<number, string>}
 */
const arrayToObject = (arr) => {
  const obj = {};
  arr.forEach((item, index) => {
    obj[index + 1] = item;
  });
  return obj;
};

/**
 * @typedef {keyof typeof options} OptionKeys
 * @typedef {Record<OptionKeys, Array<string>} AllOptions
 * @return {Promise<AllOptions>}
 */
export async function getAllOptions() {
  const optionsKeys = Object.keys(options);
  const result = await Promise.all([
    ...utilityOptions.map(getOptions),
    ...tcOptions.map(getTcOptions),
    getTcOptions("IEStatuses"),
  ]);
  const resultOblect = {};
  result.forEach((item, index) => {
    resultOblect[optionsKeys[index]] = arrayToObject(item);
  });
  return resultOblect;
}
