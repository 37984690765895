import React, { useCallback, useEffect, useState } from "react";
import { Accordion, Form } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import LoadingWrapper from "../../../components/LoadingWrapper";
import { getNotRecievedReport } from "../../../services/messages";
import moment from "moment";

/**
 * @typedef {import("../../../services/messages").NotRecievedReport} NotRecievedReportType
 */

const NotRecievedReport = () => {
  /** @type {[NotRecievedReportType[], React.Dispatch<React.SetStateAction<NotRecievedReportType[]>>]} */
  const [notRecievedReports, setNotRecievedReports] = useState([]);
  const [phoneFilter, setPhoneFilter] = useState("");
  const [loading, setLoading] = useState(false);

  const getData = useCallback((filters = {}) => {
    setLoading(true);
    getNotRecievedReport(filters)
      .then((data) => {
        setNotRecievedReports(data.items);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <div>
      <Helmet title="Not Recieved Reports" />
      <div>
        <Form.Control
          value={phoneFilter}
          placeholder="Search by phone"
          style={{ width: 200, borderRadius: 5, margin: 4 }}
          onChange={(e) => setPhoneFilter(e.target.value)}
          onKeyPress={(e) => {
            if (e.code.indexOf("Enter") > -1) {
              getData({ phone: phoneFilter });
            }
          }}
        />
      </div>
      <LoadingWrapper loading={loading}>
        <Accordion flush className="m-sm-4">
          {notRecievedReports.map((report) => (
            <Accordion.Item key={report.date} eventKey={report.date}>
              <Accordion.Header>
                <div className="d-flex justify-content-between w-100 pe-4 text-dark">
                  <span>{moment(report.date).format("MM/DD/YYYY")}</span>
                  <span>
                    {report.items.length} item{report.items.length !== 1 && "s"}
                  </span>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="d-flex justify-content-between px-2 mb-2 text-dark">
                  <span>Time</span>
                  <span>Phone</span>
                </div>
                <hr className="my-2" />
                {report.items.map((item, index) => (
                  <div key={index}>
                    {!!index && <hr className="my-2" />}
                    <div className="d-flex justify-content-between px-2">
                      <span>{moment(item.created).format("hh:mm A")}</span>
                      <a target="_blank" href={`/families?searchTerm=${item.phone}`}>{item.phone}</a>
                    </div>
                  </div>
                ))}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </LoadingWrapper>
    </div>
  );
};

export default NotRecievedReport;
