import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { OR_FILTER } from "../../../constants";
import { getEnumOption } from "../../../utils";

const EnumFilter = ({
  name,
  value: pValue,
  onChange,
  options,
  noEmpty,
  enumType = "radio",
}) => {
  const [value, setValue] = useState(pValue);
  let jsonValue = JSON.parse(value || "[]");
  const checkJsonIncludes = (value) => {
    if (jsonValue.length > 1 && enumType === "checkbox") {
      return jsonValue.some((i) => i.value === value);
    }
    return jsonValue.includes(value);
  };

  const setEnumValue = (event) => {
    if (enumType === "radio") {
      if (value === "[-1]") {
        jsonValue = [];
      }
      const optionChecked = event.target.checked;
      const optionName = event.target.name;
      if (optionChecked) {
        jsonValue = [optionName];
      } else {
        jsonValue = [];
      }
      const newValue = JSON.stringify(jsonValue);
      setValue(newValue);
      onChange({ target: { value: newValue, name } });
    } else {
      const optionChecked = event.target.checked;
      const optionName = event.target.name;
      if (optionChecked) {
        jsonValue.push(optionName);
      } else {
        jsonValue.splice(jsonValue.indexOf(optionName), 1);
      }
      if (jsonValue.length > 1) {
        jsonValue = jsonValue.map((i) =>
          i.type === OR_FILTER ? i : { type: OR_FILTER, value: i.value ?? i }
        );
      } else {
        jsonValue = jsonValue.map((i) => (!!i.type ? i.value : i));
      }
      const newValue = JSON.stringify(jsonValue);
      setValue(newValue);
      onChange({ target: { value: newValue, name } });
    }
  };

  const label = enumType === "checkbox" ? "none" : "EMPTY";

  return (
    <>
      {!noEmpty && (
        <Form.Check
          id={`${name}-empty`}
          type={enumType}
          label={`(${label})`}
          className="enum-filter-check"
          name={label}
          checked={checkJsonIncludes(enumType === "checkbox" ? label : -1)}
          onChange={(event) => {
            if (enumType === "checkbox") {
              setEnumValue(event);
              return;
            }
            const newValue = event.target.checked ? "[-1]" : "[]";
            onChange({ target: { value: newValue, name } });
            setValue(newValue);
          }}
        />
      )}
      {options.map((option) => {
        const { value, title } = getEnumOption(option);
        return (
          <Form.Check
            id={value}
            key={value}
            type={enumType}
            label={title}
            title={title}
            className="enum-filter-check"
            value={value}
            name={value}
            checked={checkJsonIncludes(value.toString())}
            onChange={setEnumValue}
          />
        );
      })}
    </>
  );
};

export default EnumFilter;
