import { Card, ListGroup } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";

function DashboardCard({
  activeFamilies,
  deactivatedFamilies,
  deletedFamilies,
  deliveries,
  totalFamilies,
  locationId,
  locationName,
  pendingAddressUpdate,
}) {
  return (
    <Col>
      <Card>
        <Card.Header>
          <Card.Title tag="h5" className="mb-0">
            <Link to={`/families?masbiahLocationId=[${locationId}]&active=[1]`}>
              {locationName}
            </Link>
          </Card.Title>
        </Card.Header>
        <ListGroup variant="flush">
          <InfoItem
            title="Active families"
            info={activeFamilies}
            link={`/families?masbiahLocationId=[${locationId}]&active=[1]`}
          />
          <InfoItem
            title="Inactive families"
            info={deactivatedFamilies}
            link={`/families?masbiahLocationId=[${locationId}]&active=[0]`}
          />
          <InfoItem
            title="Deleted families"
            info={deletedFamilies}
            link={`/families?masbiahLocationId=[${locationId}]&deleted=[1]`}
          />
          <InfoItem title="Deliveries" info={deliveries} link="" />
          <InfoItem
            title="Pending address update"
            info={pendingAddressUpdate}
            link={`/families?masbiahLocationId=[${locationId}]&active=[1]&newAddressAwaitingToBeSaved=1`}
          />
          <InfoItem
            title="Total families"
            info={totalFamilies}
            link={`/families?masbiahLocationId=[${locationId}]`}
          />
        </ListGroup>
      </Card>
    </Col>
  );
}

const InfoItem = ({ title, info, link = "" }) => (
  <ListGroup.Item>
    {title}:
    <Link to={link} className="ms-1" style={{ fontWeight: "bold" }}>
      {info}
    </Link>
  </ListGroup.Item>
);

export default DashboardCard;
