import React, { useEffect, useRef, useState } from "react";

import SaveFilterModal from "./SaveFilterModal";
import FilterValues from "./FilterValues";

import { getFilters, deleteFilter } from "../../../services/filters";
import { lowerFirst } from "../../../utils";
import Popconfirm from "../../../components/Popconfirm";

/**
 * @typedef {Record<string, any>} Values
 * @param {{
 *  queryParams: Values;
 *  onSubmit: (values: Values) => Promise<void>;
 *  filterType: number;
 *  leftComponent?: React.ReactNode;
 *  rightComponent?: React.ReactNode;
 *  rightComponentPre?: React.ReactNode;
 * }} props
 */
const Header = ({
  queryParams,
  filterType,
  onSubmit,
  leftComponent,
  rightComponent,
  rightComponentPre,
}) => {
  const [savedFilters, setSavedFilters] = useState([]);
  const [filtersChanged, setFiltersChanged] = useState(false);
  const [deleteFilterId, setDeleteFilterId] = useState("");
  const filterFormRef = useRef({ open() {} });
  const selectedFilterId = queryParams.filterId ? +queryParams.filterId : null;

  useEffect(() => {
    const clonedQueryParams = getFilterParams();
    if (!Object.keys(clonedQueryParams).length) {
      setFiltersChanged(false);
    } else if (!selectedFilterId) {
      setFiltersChanged(true);
    } else {
      const selectedFilterItem = savedFilters.find(
        (item) => item.id === selectedFilterId
      );
      if (!selectedFilterItem) {
        return;
      }
      const selectedFilter = selectedFilterItem.json;
      for (const _key of Object.keys(selectedFilter)) {
        const key = lowerFirst(_key);
        if (["detailsPopup", "savedFilter", "filterId"].includes(key)) {
          continue;
        }
        const filterValue = selectedFilter[_key];
        const compareValue =
          typeof filterValue === "number"
            ? +queryParams[key]
            : queryParams[key];
        if ((filterValue || compareValue) && filterValue !== compareValue) {
          setFiltersChanged(true);
          return;
        }
      }
      setFiltersChanged(false);
    }
  }, [queryParams, savedFilters.length, selectedFilterId]);

  // useEffect(() => {
  //   getAndSetFilters();
  // }, []);

  const getFilterParams = () => {
    const clonedQueryParams = { ...queryParams };
    delete clonedQueryParams.maxRows;
    delete clonedQueryParams.page;
    delete clonedQueryParams.savedFilter;
    delete clonedQueryParams.detailsPopup;
    return clonedQueryParams;
  };

  const getAndSetFilters = async () => {
    const filters = await getFilters(filterType);
    setSavedFilters(filters);
    return filters;
  };

  const handleSaveFilter = async (name) => {
    const data = {
      name, // for api
      saveFilter: true, // for api
      savedFilter: name, // for local use (save in query params)
      ...queryParams,
    };
    if (selectedFilterId && filtersChanged) {
      data.filterId = selectedFilterId;
    }
    await onSubmit(data);

    const oldFilters = [...savedFilters];
    const newFilters = await getAndSetFilters();
    const [newFilter] = newFilters.filter(
      (item) => !oldFilters.some((filter) => filter.id === item.id)
    );
    if (newFilter) {
      // calling `onSubmit` with the same `queryParams` shall not trigger api call
      onSubmit({
        filterId: newFilter.id, // for local use (save in query params)
        savedFilter: name, // for local use (save in query params)
        ...queryParams,
      });
    }
  };

  const handleRemoveFilter = async (id) => {
    await deleteFilter(id);

    if (+queryParams.filterId === id) {
      delete queryParams.name;
      delete queryParams.savedFilter;
      delete queryParams.filterId;
      onSubmit({ ...queryParams });
      setFiltersChanged(true);
    }
    setSavedFilters((filters) => [...filters.filter((item) => item.id !== id)]);
  };

  return (
    <div className="d-flex mb-1 justify-content-between flex-wrap">
      <div className="d-flex flex-column">
        {leftComponent}
        <FilterValues filters={queryParams} onChange={onSubmit} />
      </div>
      <div className="header-buttons-container d-flex justify-content-end align-items-start">
        <SaveFilterModal
          ref={filterFormRef}
          onSave={handleSaveFilter}
          onRemove={setDeleteFilterId}
        />
        {rightComponentPre}

        {rightComponent}
      </div>
      <Popconfirm
        title="Delete this filter?"
        show={!!deleteFilterId}
        onCancel={() => setDeleteFilterId("")}
        onOk={() => {
          handleRemoveFilter(deleteFilterId);
          setDeleteFilterId("");
        }}
      />
    </div>
  );
};

export default Header;
