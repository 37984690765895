import axios from "../utils/axios";
import { formatFamilyFilters } from "../utils";

export function getFamilyHistory(id) {
  return axios.get(`/family/getFamilyHistory/${id}`);
}

export function postFamilyHistory(id, data) {
  return axios.post(`/family/postFamilyHistory/${id}`, data);
}
export function getMessagesForFamily(id) {
  return axios.get(`/Family/GetMessages/${id}`);
}
export function GetFamilyDistributions(id) {
  return axios.get(`/family/GetFamilyDistributions/${id}`);
}

export function createFamily(data) {
  return axios.post("/family/create", data);
}

export function deleteByPhoneNumber(phone, reason) {
  return axios.post(`/family/deleteByPhoneNumber/${phone}/${reason}`);
}

export function disableFamily(id, reason) {
  return axios.post(`/family/disableFamily/${id}/${reason}`);
}

export function enableFamily(id) {
  return axios.post(`/family/enableFamily/${id}`);
}

/**
 * @param {{
 *  id: number;
 *  propertyName: string;
 *  currentValue: string;
 *  newValue: string;
 * }} data
 * @returns {Promise<void>}
 */
export function updateFamily(data) {
  return axios.post("/Family/Update", data);
}

export function filterFamilies(filters) {
  return axios.post("/family/filter", formatFamilyFilters(filters));
}

export function fetchIdsByFilter(filters) {
  return axios.post("/family/idsByFilter", formatFamilyFilters(filters));
}

export function exportFamilies(filters, exportType = 1) {
  return axios.post(
    "/family/export/" + exportType,
    formatFamilyFilters(filters),
    {
      responseType: "blob",
    }
  );
}

export function messageFamilies(messageId, familyIds) {
  return axios.post(`/Family/MessageFamilies/${messageId}`, familyIds);
}

export function exportImportHeaders() {
  return axios.post("/Family/ExportImportHeaders", undefined, {
    responseType: "blob",
  });
}

export function importFamilies({ locationId, sunset, file }) {
  const formData = new FormData();
  formData.append("file", file);
  let sunsetStr = new Date(sunset).toISOString().split('T')[0]
  return axios.post(`/Family/Import/${locationId}/${ encodeURIComponent(sunsetStr)}`, formData);
}
