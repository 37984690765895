import React, { useContext, useRef, useState } from "react";
import { Button, Card, Table } from "react-bootstrap";
import { PlusSquare } from "react-feather";
import { Helmet } from "react-helmet-async";
import FormModal from "../../../components/FormModal";
import Popconfirm from "../../../components/Popconfirm";
import UserCard from "./components/UserCard";
import { deleteUser, registerUser, updateUser } from "../../../services/users";
import { AuthContext } from "../../../contexts/JWTContext";

const Users = () => {
  const { users, refreshUsers } = useContext(AuthContext);
  const [userToDeleteName, setUserToDeleteName] = useState("");
  const [userToDeleteId, setUserToDeleteId] = useState("");
  const [updating, setUpdating] = useState(false);
  const formRef = useRef({ open(values) {} });

  const handleUserRemove = async () => {
    await deleteUser(userToDeleteId);
    await refreshUsers();
    setUserToDeleteId("");
  };

  const handleUserCreate = async (values) => {
    if (updating) {
      await updateUser(values);
    } else {
      await registerUser(values);
    }
    await refreshUsers();
  };

  // const startUserEdit = (user) => {
  //   setUpdating(true);
  //   formRef.current.open(user);
  // };

  return (
    <Card>
      <Helmet title="Users" />
      <Card.Header className="d-flex justify-content-between">
        <Card.Title tag="h5">Users</Card.Title>
        <Button
          className="shadow btn"
          onClick={() => {
            setUpdating(false);
            formRef.current.open();
          }}
        >
          <PlusSquare size={20} />
          <span className="ms-2">Create</span>
        </Button>
      </Card.Header>
      <Table striped>
        <thead>
          <tr>
            <th>User name</th>
            <th>Email</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <UserCard
              key={user.id}
              user={user}
              onRemove={({ id, userName }) => {
                setUserToDeleteId(id);
                setUserToDeleteName(userName);
              }}
              // onEdit={startUserEdit}
            />
          ))}
        </tbody>
      </Table>
      <Popconfirm
        title={`Delete "${userToDeleteName}"?`}
        show={!!userToDeleteId}
        onCancel={() => setUserToDeleteId("")}
        onOk={handleUserRemove}
      />
      <FormModal
        ref={formRef}
        title={`${updating ? "Update" : "Create"} user`}
        submitText={updating ? "Update" : "Create"}
        onSubmit={handleUserCreate}
        columns={[
          {
            key: "email",
            title: "Email",
            form: { required: true },
          },
          // {
          //   key: "role",
          //   title: "Role",
          //   type: "enum",
          //   values: ["", roles.MANAGER, roles.ADMIN],
          //   form: { required: true },
          // },
          {
            key: "password",
            title: "Password",
            form: !updating && { required: true },
          },
        ]}
      />
    </Card>
  );
};

export default Users;
