/** @type {() => Array<import("./family").TableColumnConfig>} */
const distributionEventColumns = () => [
  {
    key: "name",
    title: "Name",
    type: "string",
    noFilter: true,
    flexGrow: 1,
    noResize: true,
    form: {},
  },
  {
    key: "families",
    title: "Families",
    type: "number",
    noFilter: true,
    flexGrow: 1,
    noResize: true,
    showValue: (value) => <>{value || "No"} Families</>,
  },
  {
    key: "created",
    title: "Created At",
    type: "date",
    noFilter: true,
    flexGrow: 1,
    noResize: true,
  },
];

/** @type {() => Array<import("./family").TableColumnConfig>} */
export const distributionEventFamilyColumns = () => [
  // {
  //   key: "id",
  //   noFilter: true,
  //   title: "ID",
  //   type: "string",
  // },
  {
    key: "firstName",
    noFilter: true,
    title: "First Name",
    type: "string",
  },
  {
    key: "lastName",
    noFilter: true,
    title: "Last Name",
    type: "string",
  },
  {
    key: "phone",
    noFilter: true,
    title: "Phone",
    type: "string",
  },
  {
    key: "addressLine1",
    noFilter: true,
    title: "Address",
    type: "string",
  },
];

export default distributionEventColumns;
