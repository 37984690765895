import axios from "axios";
import { notyf } from "../contexts/NotyfContext";

const axiosInstance = axios.create({
  //baseURL: "https://localhost:44362/api",
  baseURL: "https://portal.masbia.webarysites.com/api",
});

axiosInstance.interceptors.response.use(
  (response) => response.data,
  (result) => {
    const err =
      (result.response && result.response.data) || "Something went wrong";
    const message = typeof err === "string" ? err.split("\n")[0] : err.title;

    if (!message.includes("[START ERRORS]")) {
      notyf.error(message);
    }
    return Promise.reject(err);
  }
);

export default axiosInstance;
