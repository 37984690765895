import { Button } from "react-bootstrap";
import { Trash2 } from "react-feather";

/** @type {(enums: any, props?: { onDeleteClick: (data: any) => void }) => Array<import("./family").TableColumnConfig>} */
const distributionColumns = (enums, props) => {
  const ShowValue = ({ value = null, enumKey }) =>
    enums?.[`${enumKey}Map`] ? (
      <span>{enums?.[`${enumKey}Map`]?.[value]}</span>
    ) : (
      value
    );
  return [
    {
      key: "name",
      title: "Name",
      type: "string",
      noFilter: true,
      flexGrow: 1,
      noResize: true,
      form: {},
    },
    {
      key: "locationId",
      title: "Masbia Location",
      type: "enum",
      noFilter: true,
      flexGrow: 1,
      noResize: true,
      showValue: (value) => (
        <ShowValue enumKey="masbiahLocationId" value={value} />
      ),
      values: enums?.masbiahLocationId || [],
      form: {},
    },
    // {
    //   key: "families",
    //   title: "Families",
    //   type: "number",
    //   noFilter: true,
    //   flexGrow: 1,
    //   noResize: true,
    //   showValue: (value) => <>{value || "No"} Families</>,
    // },
    {
      key: "events",
      title: "Events",
      type: "number",
      noFilter: true,
      flexGrow: 1,
      noResize: true,
      showValue: (value) => <>{value || "No"} Events</>,
    },
    {
      key: "created",
      title: "Created At",
      type: "date",
      noFilter: true,
      flexGrow: 1,
      noResize: true,
    },
    {
      key: "actions",
      title: "Actions",
      type: "string",
      noFilter: true,
      flexGrow: 1,
      noResize: true,
      render: (_keyData, rowData) => (
        <>
          <Button
            variant="link"
            size="sm"
            className="text-danger"
            onClick={(e) => {
              e.stopPropagation();
              props?.onDeleteClick(rowData);
            }}
          >
            <Trash2 size={16} />
          </Button>
        </>
      ),
    },
  ];
};

export default distributionColumns;
