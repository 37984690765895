import { memo, useCallback, useMemo, useRef, useState } from "react";
import { Table as RTable, Column, HeaderCell, Cell } from "rsuite-table";
import { getValueToShow } from "../../utils";
import Filter from "./Filter";
import "rsuite-table/dist/css/rsuite-table.css";
import get from "lodash/get";
import { Form } from "react-bootstrap";

const CheckCell = ({ rowData, onChange, checkedKeys, ...props }) => (
  <Cell {...props} style={{ padding: 0 }}>
    <Form.Check
      name={rowData.id}
      className="mt-2"
      onChange={onChange}
      checked={checkedKeys.some((item) => item === rowData.id)}
    />
  </Cell>
);

const Table = memo(
  ({
    data,
    columns = [],
    handleShowDetails,
    onItemChange,
    height,
    name,
    onColumnFilter = () => {},
    onColumnFilterClear = () => {},
    filters = {},
    noDetails = false,
    onBottomReached = () => {},
    onTopReached = () => {},
    rowsPerPage,
    onTopTrInit,
    onBottomTrInit,
    withCheckbox = false,
    checkedKeys,
    onCheck = () => {},
    allChecked = false,
    onCheckAll = () => {},
    ...rest
  }) => {
    const tableRef = useRef();
    const [sortColumn, setSortColumn] = useState();
    const [sortType, setSortType] = useState();
    const columnWidths = JSON.parse(
      localStorage.getItem(`${name}-column-widths`) || "{}"
    );

    const sortedData = useMemo(() => {
      if (sortColumn && sortType) {
        return data.sort((a, b) => {
          let x = a[sortColumn];
          let y = b[sortColumn];
          if (typeof x === "string") {
            x = x.charCodeAt();
          }
          if (typeof y === "string") {
            y = y.charCodeAt();
          }
          if (sortType === "asc") {
            return x - y;
          } else {
            return y - x;
          }
        });
      }
      return data;
    }, [sortColumn, sortType, data]);

    const handleSortColumn = useCallback((sortColumn, sortType) => {
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, []);

    const handleResize = (columnWidth, dataKey) => {
      const newValue = {
        ...columnWidths,
        [dataKey]: columnWidth,
      };
      localStorage.setItem(`${name}-column-widths`, JSON.stringify(newValue));
    };

    const handleScroll = (_x, y) => {
      // 50 is height of headers and bottom scroll bar
      // 46 is height of each row
      if (y + height - 40 >= 46 * data.length) {
        onBottomReached();
      }
      if (y === 0) {
        onTopReached(function syncScroll() {
          tableRef.current.scrollTop(46 * rowsPerPage);
        });
      }
    };

    const topId = sortedData[0]?.id;
    const bottomId = sortedData[sortedData.length - 1]?.id;

    return (
      <RTable
        ref={tableRef}
        data={sortedData}
        className="odd-background"
        height={height}
        hover={false}
        renderRow={(children, rowData) =>
          [topId, bottomId].includes(rowData?.id) ? (
            <div ref={rowData?.id === topId ? onTopTrInit : onBottomTrInit}>
              {children}
            </div>
          ) : (
            children
          )
        }
        shouldUpdateScroll={false}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        onScroll={handleScroll}
        virtualized
        {...rest}
      >
        {withCheckbox && (
          <Column width={50} align="center" fixed>
            <HeaderCell style={{ padding: 0 }}>
              <div style={{ lineHeight: "40px" }}>
                <Form.Check
                  className="mt-2"
                  checked={allChecked}
                  onChange={onCheckAll}
                />
              </div>
            </HeaderCell>
            <CheckCell
              dataKey="id"
              checkedKeys={checkedKeys}
              onChange={onCheck}
            />
          </Column>
        )}
        {columns.map(
          (item, index) =>
            !item.hidden && (
              <Column
                key={item.key}
                dataKey={item.key}
                fixed={(!noDetails && index === 0) || item.fixed}
                width={columnWidths[item.key] || item.width || 140}
                resizable={item.noResize ? false : true}
                onResize={handleResize}
                sortable={item.noSort ? false : true}
                flexGrow={item.flexGrow}
              >
                <HeaderCell title={item.title}>
                  {!item.noFilter && (
                    <Filter
                      onFilter={(value) => onColumnFilter(item.key, value)}
                      onFilterClear={() => onColumnFilterClear(item.key)}
                      filter={filters[item.key]}
                      type={item.type}
                      options={item.values}
                      height={height}
                      enumType={item.enumType}
                    />
                  )}
                  {item.title}
                </HeaderCell>
                <Cell dataKey={item.key} className="data-cell">
                  {(rowData, rowIndex) =>
                    item.render ? (
                      <div className="cell-container">
                        <div className="cell-content">
                          {item.render(rowData[item.key], rowData, rowIndex)}
                        </div>
                      </div>
                    ) : index === 0 && !noDetails ? (
                      <div className="cell-container">
                        <div
                          className="cell-content btn btn-link"
                          onClick={() => handleShowDetails(rowData)}
                        >
                          {getValueToShow({
                            value: get(rowData, item.key),
                            dateType: item.dateType,
                            showValue: item.showValue,
                            raw: item.raw,
                            type: item.type,
                          })}
                        </div>
                      </div>
                    ) : (
                      <div
                        className="cell-content"
                        style={
                          item.backgroundByValue
                            ? {
                                backgroundColor:
                                  item.backgroundByValue[rowData[item.key]],
                              }
                            : {}
                        }
                      >
                        {getValueToShow({
                          value: get(rowData, item.key),
                          dateType: item.dateType,
                          showValue: item.showValue,
                          raw: item.raw,
                          type: item.type,
                        })}
                      </div>
                    )
                  }
                </Cell>
              </Column>
            )
        )}
      </RTable>
    );
  }
);

export default Table;

export { default as DetailsTable } from "./DetailsTable";
