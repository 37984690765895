import React from "react";
import { Modal, Button } from "react-bootstrap";

/**
 * @param {Object} props
 * @param {string} props.title
 * @param {boolean} props.show
 * @param {() => void} props.onOk
 * @param {() => void} props.onCancel
 * @param {string} [props.okText] defaults to `Yes`
 * @param {string} [props.okVariant] defaults to `danger`
 * @param {string} [props.cancelText] defaults to `No`
 */
const Popconfirm = ({
  title,
  show,
  onOk,
  onCancel,
  okText = "Yes",
  okVariant = "danger",
  cancelText = "No",
}) => (
  <Modal size="sm" show={show} onHide={onCancel}>
    <Modal.Header closeButton />
    <Modal.Body className="text-center m-3">
      <h3>{title}</h3>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={onCancel}>
        {cancelText}
      </Button>
      <Button variant={okVariant} onClick={onOk}>
        {okText}
      </Button>
    </Modal.Footer>
  </Modal>
);

export default Popconfirm;
