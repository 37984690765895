import { memo, useMemo, useState } from "react";
import DateField from "./DateField";
import EnumField from "./EnumField";
import TextField from "./TextField";
import { getValueToShow } from "../../utils";

/**
 * @typedef {Object} ContentEditableProps
 * @property {any} value
 * @property {string} [editClassName]
 * @property {string} [placeholder]
 * @property {(value: any) => void} [onChange]
 * @property {import("../../pages/tables/columns/family").TableColumnConfig} column
 */
/** @type {import("react").NamedExoticComponent<ContentEditableProps>} */
const ContentEditable = memo((props) => {
  const [edit, setEdit] = useState(false);
  const Field = useMemo(() => {
    switch (props.column.type) {
      case "date": {
        return DateField;
      }
      case "boolean":
      case "enum": {
        return EnumField;
      }
      default: {
        return TextField;
      }
    }
  }, [props.column.type]);

  const valueToShow = useMemo(
    () =>
      getValueToShow({
        value: props.value,
        dateType: props.column.dateType,
        showValue: props.column.showValue,
        placeholder: props.placeholder,
        raw: props.column.raw,
        type: props.column.type,
      }),
    [props.value, props.column.type]
  );

  return (
    <>
      {edit ? (
        <Field {...props} onEditEnd={() => setEdit(false)} />
      ) : (
        <span
          className={
            props.column.getClassName
              ? props.column.getClassName(props.value)
              : "edit-placeholder"
          }
          onClick={() => !props.column.simple && setEdit(true)}
        >
          {valueToShow}
        </span>
      )}
    </>
  );
});

export default ContentEditable;
