import { useContext, useMemo, useRef } from "react";
import { Button } from "react-bootstrap";
import { Plus } from "react-feather";
import FormModal from "../../../components/FormModal";
import { UtilityContext } from "../../../contexts/UtilityContext";
import { createFamily } from "../../../services/families";
import familyColumns from "../../tables/columns/family";

const CreateCustomer = ({ afterCreate }) => {
  const { dropdownValues, exceptionOptions } = useContext(UtilityContext);
  const createFormRef = useRef({ open() {} });

  const columns = useMemo(
    () => familyColumns(dropdownValues, exceptionOptions),
    [dropdownValues, exceptionOptions]
  );

  const handleCreate = async (data) => {
    await createFamily(data);
    await afterCreate();
  };

  return (
    <>
      <FormModal
        ref={createFormRef}
        title="Create family"
        columns={columns}
        onSubmit={handleCreate}
      />
      <Button
        className="shadow"
        onClick={() =>
          createFormRef.current.open({
            masbiahLocationId: dropdownValues.masbiahLocationId[0].value,
          })
        }
      >
        <Plus className="me-1" size={20} /> Create family
      </Button>
    </>
  );
};

export default CreateCustomer;
