import React from "react";
import { Navigate } from "react-router-dom";

// All pages that rely on 3rd party components (other than Bootstrap) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";

import Families from "./pages/pages/Families";
import Distributions from "./pages/distributions/Distributions";

// Pages
import Settings from "./pages/pages/Settings";
import Tasks from "./pages/pages/Tasks";
import Users from "./pages/pages/Users";
import Dashboard from "./pages/pages/Dashboard/Dashboard";

// Auth
import Page404 from "./pages/auth/Page404";
import SignIn from "./pages/auth/SignIn";
import AuthGuard from "./components/guards/AuthGuard";
import DistributionDetails from "./pages/distributions/DistributionDetails";
import DistributionFamilies from "./pages/distributions/DistributionFamilies";
import Messages from "./pages/pages/Messages/Messages";
import Campaign from "./pages/pages/Campaign/Campaign";
import NotRecievedReport from "./pages/pages/NotRecievedReport/NotRecievedReport";

// import roles from "./utils/roles";

const routes = [
  {
    path: "/auth/",
    element: <AuthLayout />,
    children: [
      {
        path: "login",
        element: <SignIn />,
      },
      {
        path: "",
        index: true,
        element: <Page404 />,
      },
    ],
  },
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "families",
        element: <Families />,
      },
      {
        path: "distributions",
        element: <Distributions />,
      },
      {
        path: "distributions/:id",
        element: <DistributionDetails />,
      },
      {
        path: "distributions/:id/families",
        element: <DistributionFamilies />,
      },
      {
        path: "settings",
        element: <Settings />,
      },
      {
        path: "tasks",
        element: <Tasks />,
      },
      {
        path: "users",
        // role: [roles.ADMIN], // uncomment this when roles are implemented
        element: <Users />,
      },
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "messages",
        element: <Messages />,
      },
      {
        path: "campaign",
        element: <Campaign />,
      },
      {
        path: "notRecievedReports",
        element: <NotRecievedReport />,
      },
      {
        path: "",
        index: true,
        element: <Navigate to="families" />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
