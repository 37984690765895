import axios from "../utils/axios";

export function getRPIE() {
  return axios.get("/RPIE");
}

export function filterRPIE({
  year,
  maxRows = 50,
  page = 1,
  name,
  saveFilter,
  filterId,
  ...filter
}) {
  const searchParamsInit = { year };

  if (name && saveFilter) {
    searchParamsInit.name = name;
    searchParamsInit.saveFilter = saveFilter;
    if (filterId) {
      searchParamsInit.filterId = filterId;
    }
  }

  return axios.post(`/RPIE?${new URLSearchParams(searchParamsInit)}`, {
    maxRows: +maxRows,
    page: +page,
    ...filter,
  });
}

export function getAllDefinitions() {
  return axios.get("/RPIE/GetAllDefinitions");
}

export function exportRPIE() {
  return axios.get("/RPIE/Export", { responseType: "blob" });
}

export function getNYCUnprocessedBBLs() {
  return axios.get("/RPIE/GetNYCUnprocessedBBLs");
}

/**
 *
 * @param {{
 *  bbl: string;
 *  hasError: Boolean;
 *  requiredFlag: string;
 *  rrRequiredFlag: string;
 *}} data
 * @returns
 */
export function postNYCScrappedData(data) {
  return axios.post("/RPIE/PostNYCScrappedData", data);
}

export function googleSheetWriteAll(year) {
  return axios.post(`/RPIE/GoogleSheetWriteAll?year=${year}`);
}

export function googleSheetComapreData(year) {
  return axios.post(`/RPIE/GoogleSheetComapreData?year=${year}`);
}

export function googleSheetLoadAllData() {
  return axios.post("/RPIE/GoogleSheetLoadAllData");
}
