import React, { useCallback, useRef, useState } from "react";
import {
  createDistribution,
  updateDistribution as updateDistributionApi,
  getDistributions,
  deleteDistribution as deleteDistributionApi,
} from "../services/distributions";

const initialState = {
  distributions: [],
  loading: false,
  loadDistributions: ({ forceLoad = false } = {}) => {},
  async addDistribution(locationId, name) {},
  async updateDistribution(id, locationId, name) {},
  async deleteDistribution(id) {},
};

const DistributionContext = React.createContext(initialState);

function DistributionProvider({ children }) {
  const [distributions, setDistributions] = useState([]);
  const initedRef = useRef(false);
  const [loading, setLoading] = useState();

  const loadDistributions = useCallback(({ forceLoad = false } = {}) => {
    if (initedRef.current && !forceLoad) {
      return;
    }
    setLoading(true);
    const wasInited = initedRef.current;
    initedRef.current = true;
    getDistributions()
      .then(setDistributions)
      .catch(() => {
        initedRef.current = wasInited;
      })
      .finally(() => setLoading(false));
  }, []);

  const addDistribution = async (locationId, name) => {
    setLoading(true);
    try {
      const newDistribution = await createDistribution(locationId, name);
      if (newDistribution) {
        setDistributions([
          { ...newDistribution, locationId: newDistribution.masbiaLocationId },
          ...distributions,
        ]);
      }
    } catch (_err) {}
    setLoading(false);
  };

  const updateDistribution = async (id, locationId, name) => {
    setLoading(true);
    try {
      const updatedDistribution = await updateDistributionApi(
        id,
        locationId,
        name
      );
      if (updatedDistribution) {
        setDistributions((oldValue) =>
          oldValue.map((item) => (item.id === id ? updatedDistribution : item))
        );
      }
    } catch (_err) {}
    setLoading(false);
  };

  const deleteDistribution = async (id) => {
    setLoading(true);
    try {
      await deleteDistributionApi(id);
      setDistributions((oldValue) => oldValue.filter((item) => item.id !== id));
    } catch (_err) {}
    setLoading(false);
  };

  return (
    <DistributionContext.Provider
      value={{
        distributions,
        loadDistributions,
        loading,
        addDistribution,
        updateDistribution,
        deleteDistribution,
      }}
    >
      {children}
    </DistributionContext.Provider>
  );
}

export { DistributionProvider, DistributionContext };
