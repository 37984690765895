import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

/**
 * @param {import("react-bootstrap").ButtonProps & {
 *  hint: string;
 *  Icon: import("react-feather").Icon;
 * }} props
 */
const ActionButton = ({ Icon, hint, ...props }) => (
  <OverlayTrigger delay={{ show: 500 }} overlay={<Tooltip>{hint}</Tooltip>}>
    <Button size="sm" variant="link" {...props}>
      <Icon size={18} />
    </Button>
  </OverlayTrigger>
);

export default ActionButton;
