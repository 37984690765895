import { getDashboardInfo } from "../../../services/dashboard";
import { useEffect } from "react";
import { useState } from "react";
import DashboardCard from "./DashboardCard";
import Row from "react-bootstrap/Row";
function Dashboard() {
  const [dashboardData, setDashboardData] = useState([]);
  useEffect(
    () => getDashboardInfo().then((data) => setDashboardData(data)),
    []
  );
  return (
    <Row md={2}>
      {dashboardData[0] &&
        dashboardData.map((item, index) => (
          <DashboardCard
            key={index}
            activeFamilies={item.activeFamilies}
            deactivatedFamilies={item.deactivatedFamilies}
            deletedFamilies={item.deletedFamilies}
            deliveries={item.deliveries}
            totalFamilies={item.totalFamilies}
            locationId={item.locationId}
            locationName={item.locationName}
            pendingAddressUpdate={item.pendingAddressUpdate}
          />
        ))}
    </Row>
  );
}
export default Dashboard;
