import { useEffect, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import { Edit2 } from "react-feather";
import { Button } from "react-bootstrap";
import { Table, Column, Cell, HeaderCell } from "rsuite-table";
import CampaignForm from "./CampaignForm";
import { CampaignContext } from "../../../contexts/CampaignContext";
import { PresetMessagesContext } from "../../../contexts/PresetMessagesContext";
import useKeyBy from "../../../hooks/useKeyBy";
import useScreenSize from "../../../hooks/useScreenSize";

function Campaign() {
  const { campaigns, loadCampaigns, addCampaign, updateCampaign } =
    useContext(CampaignContext);
  const { presetMessages, loadPresetMessages } = useContext(
    PresetMessagesContext
  );
  const { height } = useScreenSize();
  const tableHeight = Math.max(height - 150, 360);
  const campaignFormRef = useRef({ open() {} });

  useEffect(() => {
    loadCampaigns({ forceLoad: true });
    loadPresetMessages();
  }, [loadCampaigns, loadPresetMessages]);

  const messagesMap = useKeyBy(presetMessages, "id");

  const handleSubmit = async (campaign, selectedFile) => {
    if (!campaign.id) {
      await addCampaign(campaign, selectedFile);
    } else {
      await updateCampaign(campaign);
    }
  };

  return (
    <>
      <CampaignForm ref={campaignFormRef} onSubmit={handleSubmit} />
      <div style={{ display: "flex", justifyContent: "right" }}>
        <Button
          variant="primary"
          onClick={() => campaignFormRef.current.open()}
          className="mb-3 "
        >
          Create Campaign
        </Button>
      </div>
      <Table height={tableHeight} data={campaigns}>
        <Column flexGrow={0.5} align="center">
          <HeaderCell>Id</HeaderCell>
          <Cell dataKey="id">
            {(rowData) => (
              <Link to={`/families?campaignId=${rowData.id}`}>
                {rowData.id}
              </Link>
            )}
          </Cell>
        </Column>
        <Column flexGrow={0.5}>
          <HeaderCell>Keyword</HeaderCell>
          <Cell dataKey="keyword" />
        </Column>
        <Column flexGrow={0.5}>
          <HeaderCell>Active</HeaderCell>
          <Cell>{(rowData) => (rowData.active ? "Yes" : "No")}</Cell>
        </Column>
        {
          <Column flexGrow={1}>
            <HeaderCell>Message</HeaderCell>
            <Cell>{(rowData) => messagesMap[rowData.message]?.name}</Cell>
          </Column>
        }
        <Column flexGrow={1} align="center" fixed="right">
          <HeaderCell>Actions</HeaderCell>
          <Cell>
            {(rowData) => (
              <Edit2
                onClick={() => campaignFormRef.current.open(rowData)}
                style={{ cursor: "pointer", width: "18px" }}
              />
            )}
          </Cell>
        </Column>
      </Table>
    </>
  );
}

export default Campaign;
