import React, {
  useEffect,
  useMemo,
  useState,
  useRef,
  useContext,
  useCallback,
} from "react";
import { Helmet } from "react-helmet-async";
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Container,
  Form,
  Row,
  ToggleButton,
  Modal,
} from "react-bootstrap";
import dragula from "react-dragula";
import Select from "react-select";
import { PlusSquare } from "react-feather";
import ReactPaginate from "react-paginate";

import { useSearchParams } from "react-router-dom";
import NoteCard from "../../components/DetailTabs/Notes/Card";
import NoteForm from "../../components/DetailTabs/Notes/Form";
import Popconfirm from "../../components/Popconfirm";
import {
  getPagedNotesByFilter,
  updateNote,
  createNote,
  deleteNote,
} from "../../services/notes";
import useStateWithRef from "../../hooks/useStateWithRef";
import { AuthContext } from "../../contexts/JWTContext";
import keyBy from "lodash/keyBy";
import debounce from "lodash/debounce";

/** @typedef {import("../../services/notes").Note} Note */

const Tasks = () => {
  const [tasks, setTasks] = useStateWithRef([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [taskToEdit, setTaskToEdit] = useState(0);
  const [taskToDelete, setTaskToDelete] = useState(0);
  const [taskToResolve, setTaskToResolve] = useState(0);
  const [showAddModal, setShowAddModal] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const { users, fullUser } = useContext(AuthContext);
  const usersMap = useMemo(() => keyBy(users, "id"), [users]);
  const containers = useRef([]);
  /** @type {React.MutableRefObject<dragula.Drake>} */
  const drake = useRef();

  const [completed, inProgress, todo] = useMemo(() => {
    const completed = [];
    const inProgress = [];
    const todo = [];
    tasks.current.sort((a) => (a.assignedTo === fullUser.id ? -1 : 1));
    tasks.current.forEach((task) => {
      switch (task.status) {
        case 3: {
          completed.push(task);
          break;
        }
        case 2: {
          inProgress.push(task);
          break;
        }
        default: {
          todo.push(task);
          break;
        }
      }
    });
    return [completed, inProgress, todo];
  }, [tasks.current, fullUser.id]);

  const onContainerReady = (container) => {
    containers.current.push(container);
  };

  useEffect(() => {
    drake.current = dragula(containers.current);
  }, []);

  const taskUpdate = (taskId, toStatus, values = {}) => {
    const taskIndex = tasks.current.findIndex((item) => item.id === taskId);
    const task = tasks.current[taskIndex];
    drake.current.cancel(true);
    if (!task || (toStatus === task.status && !Object.keys(values).length)) {
      return;
    }
    const taskUpdate = {
      ...task,
      ...values,
      status: toStatus,
    };
    if (toStatus === 3) {
      taskUpdate.completedDate = new Date().toISOString();
    } else {
      taskUpdate.completedDate = null;
    }
    updateNote(taskUpdate);
    tasks.current[taskIndex] = taskUpdate;
    setTasks([...tasks.current]);
  };

  useEffect(() => {
    drake.current.on("drop", (el, target, _source, _sibling) => {
      taskUpdate(
        +el.getAttribute("data-id"),
        +target.getAttribute("data-status")
      );
    });
  }, []);

  const handleFilterChange = (name, value) => {
    setSearchParams({
      ...queryParams,
      [name]: value,
    });
  };

  const queryParams = useMemo(() => {
    const _queryParams = {};
    searchParams.forEach((value, key) => {
      if (!value) {
        return;
      }
      _queryParams[key] = value;
    });
    return _queryParams;
  }, [searchParams]);

  useEffect(() => {
    getPagedNotesByFilter({
      ...queryParams,
      noteType: 2,
    }).then(({ data, totalRows }) => {
      setTotalRows(totalRows);
      setTasks(data);
    });
  }, [queryParams]);

  const handleNoteCreate = async (data) => {
    const newTask = await createNote(data);
    setTasks([newTask, ...tasks.current]);
  };

  const handleTaskAction = (action, id) => {
    switch (action) {
      case "edit": {
        setTaskToEdit(id);
        setShowAddModal(true);
        break;
      }
      case "delete": {
        setTaskToDelete(id);
        break;
      }
      case "resolve": {
        setTaskToResolve(id);
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleNoteCreateOrUpdate = (values) => {
    values.assignedTo = values.assignedTo?.value || values.assignedTo;
    if (taskToEdit) {
      taskUpdate(taskToEdit, values.status, values);
    } else {
      handleNoteCreate(values);
    }
  };

  const handleTaskDelete = async () => {
    await deleteNote(taskToDelete);
    const deletedIndex = tasks.current.findIndex(
      (item) => item.id === taskToDelete
    );
    tasks.current.splice(deletedIndex, 1);
    setTasks([...tasks.current]);
    setTaskToDelete(0);
  };

  const handleTaskResolve = () => {
    taskUpdate(taskToResolve, 3);
    setTaskToResolve(0);
  };

  const closeModal = () => {
    setShowAddModal(false);
    setTaskToEdit(0);
  };

  const initialValues = useMemo(() => {
    const value = taskToEdit
      ? tasks.current.find((item) => item.id === taskToEdit)
      : {};
    if (value?.assignedTo) {
      value.assignedTo = {
        value: usersMap[value.assignedTo]?.id,
        label: usersMap[value.assignedTo]?.userName,
      };
    }
    return value;
  }, [taskToEdit, usersMap, tasks]);

  const onPageChange = (page) => {
    setSearchParams({ ...queryParams, page });
  };

  return (
    <>
      <Helmet title="Tasks" />
      <Container fluid className="p-0">
        <Popconfirm
          title="Delete this task?"
          show={!!taskToDelete}
          onCancel={() => setTaskToDelete(0)}
          onOk={handleTaskDelete}
        />
        <Popconfirm
          title="Resolve this task?"
          show={!!taskToResolve}
          onCancel={() => setTaskToResolve(0)}
          onOk={handleTaskResolve}
          okText="Resolve"
          okVariant="success"
          cancelText="Cancel"
        />
        <Modal show={showAddModal} onHide={closeModal}>
          <Modal.Header closeButton>Create a Task</Modal.Header>
          <Modal.Body>
            <NoteForm
              onClose={closeModal}
              users={users}
              noteType={2}
              onSubmit={handleNoteCreateOrUpdate}
              initialValues={initialValues}
            />
          </Modal.Body>
        </Modal>
        <TaskFilters
          usersMap={usersMap}
          filters={queryParams}
          onFilterChange={handleFilterChange}
        />
        <h3 className="mb-4">Tasks</h3>
        <Button className="mb-2" onClick={() => setShowAddModal(true)}>
          <PlusSquare size={20} className="me-2" />
          Add new task
        </Button>
        <Row>
          <Col lg="4">
            <Lane name="To Do" status="1" onContainerLoaded={onContainerReady}>
              {todo.map((task) => (
                <Task
                  key={task.id}
                  task={task}
                  usersMap={usersMap}
                  userId={fullUser.id}
                  onAction={handleTaskAction}
                />
              ))}
            </Lane>
          </Col>
          <Col lg="4">
            <Lane
              name="In Progress"
              status="2"
              onContainerLoaded={onContainerReady}
            >
              {inProgress.map((task) => (
                <Task
                  key={task.id}
                  task={task}
                  usersMap={usersMap}
                  userId={fullUser.id}
                  onAction={handleTaskAction}
                />
              ))}
            </Lane>
          </Col>
          <Col lg="4">
            <Lane name="Done" status="3" onContainerLoaded={onContainerReady}>
              {completed.map((task) => (
                <Task
                  key={task.id}
                  task={task}
                  usersMap={usersMap}
                  userId={fullUser.id}
                  onAction={handleTaskAction}
                />
              ))}
            </Lane>
          </Col>
        </Row>
        {!!totalRows && (
          <ReactPaginate
            forcePage={(searchParams.get("page") || 1) - 1}
            pageCount={+Math.ceil(totalRows / 10)}
            onPageChange={({ selected }) => onPageChange(selected + 1)}
            pageRangeDisplayed={3}
            nextLabel=">"
            previousLabel="<"
            breakLabel="..."
            containerClassName="pagination mt-3 mb-0"
            activeClassName="active"
            pageClassName="page-item"
            nextClassName="page-item"
            previousClassName="page-item"
            breakClassName="page-item"
            pageLinkClassName="page-link"
            previousLinkClassName="page-link"
            breakLinkClassName="page-link"
            nextLinkClassName="page-link"
          />
        )}
      </Container>
    </>
  );
};

export default Tasks;

const Lane = ({ name, children, onContainerLoaded, status }) => {
  const handleContainerLoaded = (container) => {
    if (container) {
      onContainerLoaded(container);
    }
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title tag="h5">{name}</Card.Title>
      </Card.Header>
      <Card.Body>
        <div
          ref={handleContainerLoaded}
          data-status={status}
          style={{ minHeight: 64 }}
        >
          {children}
        </div>
      </Card.Body>
    </Card>
  );
};

const Task = ({ userId, task, ...rest }) => (
  <Card
    className={`mb-3 ${
      task.assignedTo === userId ? "bg-light " : ""
    }cursor-grab border`}
    data-id={task.id}
  >
    <NoteCard full note={task} {...rest} />
  </Card>
);

const completeOptions = [
  {
    value: 0,
    name: "All",
  },
  {
    value: 1,
    name: "To Do",
  },
  {
    value: 2,
    name: "In Progress",
  },
  {
    value: 3,
    name: "Done",
  },
];

/**
 * @param {Object} props
 * @param {Object} props.filters
 * @param {0|1|2} props.filters.complete
 * @param {string} [props.filters.assignedTo]
 * @param {string} [props.filters.clientId]
 * @param {string} [props.filters.bbl]
 * @param {(name: string, value: string) => void} props.onFilterChange
 * @param {Array<import("../../services/users").User} props.usersMap
 */
function TaskFilters(props) {
  const handelChangeDebounce = useCallback(
    debounce((e) => props.onFilterChange(e.target.name, e.target.value), 700),
    [props.filters]
  );

  return (
    <div className="float-end d-flex">
      <Form.Control
        defaultValue={props.filters.clientId}
        name="clientId"
        onChange={handelChangeDebounce}
        placeholder="Customer"
        className="me-2"
      />
      <Form.Control
        defaultValue={props.filters.bbl}
        name="bbl"
        onChange={handelChangeDebounce}
        placeholder="Property"
        className="me-2"
      />
      <div style={{ minWidth: 160 }} className="me-2">
        <Select
          className="react-select-container form-control-md w-100"
          classNamePrefix="react-select"
          value={
            props.filters.assignedTo && {
              value: props.filters.assignedTo,
              label:
                props.filters.assignedTo === "all"
                  ? "All"
                  : props.usersMap[props.filters.assignedTo]?.userName,
            }
          }
          placeholder="Assigned to"
          options={[
            { value: "all", label: "All" },
            ...Object.values(props.usersMap).map((user) => ({
              value: user.id,
              label: user.userName,
            })),
          ]}
          onChange={(item) => props.onFilterChange("assignedTo", item.value)}
        />
      </div>
      <ButtonGroup>
        {completeOptions.map((option, idx) => (
          <ToggleButton
            key={idx}
            style={{ whiteSpace: "nowrap" }}
            id={`status-${idx}`}
            type="radio"
            variant="outline-primary"
            name="radio"
            size="lg"
            value={option.value}
            checked={
              +props.filters.status === option.value ||
              (!props.filters.status && !option.value)
            }
            onChange={(e) =>
              props.onFilterChange("status", +e.currentTarget.value)
            }
          >
            {option.name}
          </ToggleButton>
        ))}
      </ButtonGroup>
    </div>
  );
}
