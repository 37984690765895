import axios from "../utils/axios";

/**
 * @typedef {Object} Note
 * @property {number} id
 * @property {string} clientId
 * @property {string} text
 * @property {string} bbl
 * @property {number} year
 * @property {1|2} relationshipType
 * @property {1|2} noteType
 * @property {string} assignedBy
 * @property {string} assignedTo
 * @property {string} created  A date as a string value in ISO format.
 * @property {string} updated  A date as a string value in ISO format.
 * @property {string} assignedDate  A date as a string value in ISO format.
 * @property {string} completedDate  A date as a string value in ISO format.
 * @property {number} status
 */

/** @returns {Promise<Array<Note>>} */
export function getAllNotes() {
  return axios.get("/note/getAllNotes");
}

/**
 * @param {string} id
 * @returns {Promise<void>}
 */
export function deleteNote(id) {
  return axios.post(`/note/deleteNote?noteId=${id}`);
}

/**
 * @param {Partial<Note>} data
 * @returns {Promise<Note>}
 */
export function updateNote(data) {
  return axios.post("/note/updateNote", data);
}

/**
 * @param {Partial<Note>} data
 * @returns {Promise<Note>}
 */
export function createNote(data) {
  if (data.noteType === 2) {
    data.status = 1;
  }
  return axios.post("/note/createNote", data);
}

/**
 * @param {Partial<Note>} data
 * @returns {Promise<Array<Note>>}
 */
export function getNotesByFilter(data) {
  if (data.status && typeof data.status === "string") {
    data.status = +data.status;
  }
  if (data.status === 0) {
    delete data.status;
  }
  return axios.post("/note/getNotesByFilter", data);
}

/**
 * @param {Partial<Note>} data
 * @returns {Promise<{ data: Array<Note>; totalRows: number }>}
 */
export function getPagedNotesByFilter({ maxRows = 10, page = 1, ...data }) {
  if (data.status && typeof data.status === "string") {
    data.status = +data.status;
  }
  if (data.status === 0) {
    delete data.status;
  }
  return axios.post("/note/getPagedNotesByFilter", {
    maxRows: +maxRows,
    page: +page,
    assignedTo: "all",
    ...data,
  });
}

/** @returns {Promise<Array<{ summary: string; assignedTo: string; }>>} */
export function getTasksSummary() {
  return axios.get("/note/getTasksSummary");
}

export async function getNotesByBbl(bbl) {
  return {
    data: await axios.get(`/Note/GetNotesByBbl?bbl=${bbl}`),
  };
}

export async function getNotesByClient(clientId) {
  return {
    data: await axios.get(`/Note/GetNotesByClient?clientId=${clientId}`),
  };
}
