import React, { useCallback, useState } from "react";
import { clearQueue, getQueueLength, processQueue } from "../services/messages";

const initialState = {
  queueLength: 0,
  fetchQueueLength: async () => {},
  fetchLoading: false,
  processLoading: false,
  execProcessQueue: async () => {},
  deleteQueueMessages: async () => {},
};

const MessagesQueueContext = React.createContext(initialState);

function MessagesQueueProvider({ children }) {
  const [queueLength, setQueueLength] = useState([]);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [processLoading, setProcessLoading] = useState(false);

  const fetchQueueLength = useCallback(async () => {
    setFetchLoading(true);
    const result = await getQueueLength();
    setFetchLoading(false);
    setQueueLength(result);
  }, []);

  const execProcessQueue = useCallback(() => {
    setProcessLoading(true);
    const interval = setInterval(() => {
      fetchQueueLength();
    }, 1000);

    processQueue().then((x) => {
      setProcessLoading(false);
      clearInterval(interval);
    });
  }, [fetchQueueLength]);

  const deleteQueueMessages = useCallback(async () => {
    await clearQueue();
    fetchQueueLength();
  }, [fetchQueueLength]);

  return (
    <MessagesQueueContext.Provider
      value={{
        queueLength,
        fetchQueueLength,
        fetchLoading,
        processLoading,
        execProcessQueue,
        deleteQueueMessages,
      }}
    >
      {children}
    </MessagesQueueContext.Provider>
  );
}

export { MessagesQueueProvider, MessagesQueueContext };
