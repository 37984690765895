import { useState } from "react";
import { useEffect } from "react";
import { GetFamilyDistributions } from "../../../services/families";

import { Table } from "react-bootstrap";

function DistributionsTab({ item }) {
  const [curreentDistribution, setCurreentDistribution] = useState([]);
  useEffect(
    () => GetFamilyDistributions(item.id).then(setCurreentDistribution),
    [item.id]
  );
  return (
    <Table size="sm">
      <tbody>
      {curreentDistribution?.reverse()?.map((x) => (          
          <tbody>
              <tr>
                <td style={{width: 300}}>{x.distributionName} / {x.eventName}</td>
                <td>
                  <span>{new Date(x.created).toLocaleString()}</span>
                </td>
              </tr>                           
          </tbody>
        ))}
      </tbody>
    </Table>
  );
}

export default DistributionsTab;
