import React from "react";
import { Trash } from "react-feather";

const UserCard = ({ user, onRemove }) => (
  <tr>
    <td>{user.userName}</td>
    <td>{user.email && <a href={`mailto:${user.email}`}>{user.email}</a>}</td>
    <td className="table-action">
      <Trash
        className="hoverable align-middle"
        size={18}
        onClick={() => onRemove(user)}
      />
    </td>
  </tr>
);

export default UserCard;
