import React, { useCallback, useRef, useState } from "react";
import {
  updatePresetMessage,
  createPresetMessage,
  getPresetMessages,
  uploadAudioFile,
} from "../services/presetMessages";

/**
 * @typedef {import("../services/presetMessages").PresetMessage} PresetMessage
 */

const initialState = {
  /** @type {Array<PresetMessage>} */
  presetMessages: [],
  loadPresetMessages: ({ forceLoad = false } = {}) => {},
  /**
   * @param {string} message
   * @param {File} [file]
   */
  async createOrUpdateMessage(message, file) {},
  async deleteMessage(id) {},
};

const PresetMessagesContext = React.createContext(initialState);

function PresetMessagesProvider({ children }) {
  const [presetMessages, setPresetMessages] = useState(
    initialState.presetMessages
  );
  const initedRef = useRef(false);

  const loadPresetMessages = useCallback(({ forceLoad = false } = {}) => {
    if (initedRef.current && !forceLoad) {
      return;
    }
    const wasInited = initedRef.current;
    initedRef.current = true;
    getPresetMessages()
      .then(setPresetMessages)
      .catch(() => {
        initedRef.current = wasInited;
      });
  }, []);

  const createOrUpdateMessage = async (data, file) => {
    if (!data.id) {
      const result = await createPresetMessage(data);
      if (result.id && file) {
        await uploadAudioFile(result.id, file);
        loadPresetMessages({ forceLoad: true });
      }
      setPresetMessages((oldMessages) => [...oldMessages, result]);
    } else {
      updatePresetMessage(data);
      if (file) {
        await uploadAudioFile(data.id, file);
        loadPresetMessages({ forceLoad: true });
      }
      setPresetMessages((oldMessages) =>
        oldMessages.map((message) =>
          message.id === data.id
            ? {
                ...message,
                ...data,
              }
            : message
        )
      );
    }
  };

  const deleteMessage = async (id) => {
    updatePresetMessage({ id, deleted: true });
    setPresetMessages((oldData) => oldData.filter((m) => m.id !== id));
  };

  return (
    <PresetMessagesContext.Provider
      value={{
        presetMessages,
        loadPresetMessages,
        createOrUpdateMessage,
        deleteMessage,
      }}
    >
      {children}
    </PresetMessagesContext.Provider>
  );
}

export { PresetMessagesProvider, PresetMessagesContext };
