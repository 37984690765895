import React, { useContext, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Formik } from "formik";
import { Upload, X } from "react-feather";
import { UtilityContext } from "../../../contexts/UtilityContext";
import {
  exportImportHeaders,
  importFamilies,
} from "../../../services/families";
import { downloadFile } from "../../../utils";

/**
 * @param {{
 *  show: boolean;
 *  onHide: () => void;
 * }} props
 */
const ImportFamiliesModal = ({ show, onHide }) => {
  return (
    <Modal show={show} onHide={onHide}>
      <ImportModalContent onHide={onHide} />
    </Modal>
  );
};

/**
 * @param {{
 *  onHide: () => void;
 * }} props
 */
const ImportModalContent = ({ onHide }) => {
  const { dropdownValues } = useContext(UtilityContext);
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState();

  const onSelectFile = (e) => {
    if (!e.target.files?.length) {
      setSelectedFile(null);
      return;
    }

    setSelectedFile(e.target.files[0]);
    e.target.value = null;
  };

  const handleExportImportHeaders = async () => {
    const data = await exportImportHeaders();
    downloadFile(data, "import-headers.csv");
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Import Families</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Button
          variant="light"
          className="mb-2"
          onClick={handleExportImportHeaders}
        >
          Export the import template
        </Button>
        <Formik
          initialValues={{
            sunset: new Date(),
            locationId: 0,
            submit: "",
          }}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            setLoading(true);
            try {
              await importFamilies({
                ...values,
                file: selectedFile,
              });
              onHide();
            } catch (error) {
              let message =
                error?.[0]?.description ||
                error.message ||
                "Something went wrong";

              if (error.includes("[START ERRORS]")) {
                message = error
                  .split("[START ERRORS]")[1]
                  .split("[END ERRORS]")[0];
              }
              setStatus({ success: false });
              setErrors({ submit: message });
              setSubmitting(false);
            }
            setLoading(false);
          }}
        >
          {({ handleChange, handleSubmit, values, errors }) => (
            <Form id="doordash-export-form" onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Allow signup end date</Form.Label>
                <DatePicker
                  className="form-control"
                  selected={values.sunset}
                  onChange={(value) =>
                    handleChange({ target: { name: "sunset", value } })
                  }
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Location</Form.Label>
                <Form.Select
                  onChange={handleChange}
                  name="locationId"
                  value={values.locationId}
                >
                  {dropdownValues.masbiahLocationId.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.title}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group>
                <input
                  id="import-families-csv-file-upload"
                  type="file"
                  accept=".csv"
                  hidden
                  onChange={onSelectFile}
                />
                <label
                  htmlFor="import-families-csv-file-upload"
                  className={`btn btn-${
                    selectedFile ? "success" : "light"
                  } mb-3`}
                  style={{ cursor: "pointer" }}
                >
                  {selectedFile ? (
                    <X
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setSelectedFile(null);
                      }}
                    />
                  ) : (
                    <Upload size={24} />
                  )}
                  <span className="ms-2">
                    Select a <code>.csv</code> file
                  </span>
                </label>
              </Form.Group>
              {errors.submit && (
                <div
                  className="text-danger mt-2"
                  dangerouslySetInnerHTML={{ __html: errors.submit }}
                />
              )}
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" disabled={loading} onClick={onHide}>
          Cancel
        </Button>
        <Button
          type="submit"
          variant="primary"
          disabled={loading}
          form="doordash-export-form"
        >
          {loading && <Spinner animation="border" size="sm" className="me-2" />}
          import
        </Button>
      </Modal.Footer>
    </>
  );
};

export default ImportFamiliesModal;
