import React, { forwardRef, useImperativeHandle, useState } from "react";

import { Button, Modal, Form } from "react-bootstrap";

const SaveFilterModal = forwardRef(({ onSave, onRemove }, ref) => {
  const [show, setShow] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [filterId, setFilterId] = useState("");

  useImperativeHandle(
    ref,
    () => ({
      open(initialData) {
        setShow(true);
        if (initialData) {
          setFilterName(initialData.name);
          setFilterId(initialData.filterId);
        }
      },
    }),
    []
  );

  return (
    <Modal show={show} size="sm" onHide={() => setShow(false)}>
      <Modal.Body>
        <Form.Label>Filter name</Form.Label>
        <Form.Control
          value={filterName}
          onChange={(e) => setFilterName(e.target.value)}
        />
        <div className="d-grid gap-2 text-center mt-3">
          <Button
            onClick={() => {
              onSave(filterName);
              setFilterName("");
              setShow(false);
            }}
          >
            SAVE
          </Button>
          {filterId && (
            <Button
              variant="danger"
              onClick={() => {
                onRemove(filterId);
                setFilterName("");
                setShow(false);
              }}
            >
              REMOVE
            </Button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
});

export default SaveFilterModal;
