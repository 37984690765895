import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  ListGroup,
  Row,
  Spinner,
  Tab,
} from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { changePassword } from "../../services/users";

const tabs = ["Account", "Password"];

const Navigation = ({ activeTab, setActiveTab }) => (
  <Card>
    <Card.Header>
      <Card.Title tag="h5" className="mb-0">
        Profile Settings
      </Card.Title>
    </Card.Header>
    <ListGroup variant="flush">
      {tabs.map((tab) => (
        <ListGroup.Item
          key={tab}
          tag="a"
          href="#"
          action
          eventKey={tab}
          active={activeTab === tab}
          onClick={() => setActiveTab(tab)}
        >
          {tab}
        </ListGroup.Item>
      ))}
    </ListGroup>
  </Card>
);

const PublicInfo = () => (
  <Card>
    <Card.Header>
      <Card.Title tag="h5" className="mb-0">
        User info
      </Card.Title>
    </Card.Header>
    <Card.Body>
      <Form>
        <Row>
          <Col md="8">
            <Form.Group className="mb-3">
              <Form.Label htmlFor="inputUsername">Username</Form.Label>
              <Form.Control
                type="text"
                id="inputUsername"
                disabled
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </Card.Body>
  </Card>
);

const UpdatePassword = () => {
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  return (
    <Card>
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          Update Password
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col md="6">
            <Formik
              initialValues={{
                password: "",
                newPassword: "",
                passwordConfirmation: "",
                submit: false,
              }}
              validationSchema={Yup.object().shape({
                password: Yup.string()
                  .max(255)
                  .required("Password is required"),
                newPassword: Yup.string().required(
                  "Please enter a new password"
                ),
                passwordConfirmation: Yup.string().test(
                  "passwords-match",
                  "Passwords must match",
                  function (value) {
                    return this.parent.newPassword === value;
                  }
                ),
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
              ) => {
                try {
                  await changePassword(values.password, values.newPassword);
                  setShowSuccessAlert(true);
                  setTimeout(() => setShowSuccessAlert(false), 2500);
                  setErrors({ submit: false });
                } catch (error) {
                  const message =
                    error?.[0]?.description ||
                    error.message ||
                    "Something went wrong";

                  setStatus({ success: false });
                  setErrors({ submit: message });
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                setErrors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <Form onSubmit={handleSubmit}>
                  {showSuccessAlert && (
                    <Alert
                      className="my-3"
                      variant="success"
                      onClose={() => setShowSuccessAlert(false)}
                      dismissible
                    >
                      <div className="alert-icon">
                        <FontAwesomeIcon icon={faCheck} fixedWidth />
                      </div>
                      <div className="alert-message">
                        Password successfully updated!
                      </div>
                    </Alert>
                  )}
                  {errors.submit && (
                    <Alert
                      className="my-3"
                      variant="danger"
                      onClose={() => setErrors({ submit: false })}
                      dismissible
                    >
                      <div className="alert-icon">
                        <FontAwesomeIcon
                          icon={faExclamationTriangle}
                          fixedWidth
                        />
                      </div>
                      <div className="alert-message">{errors.submit}</div>
                    </Alert>
                  )}

                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="update-password-field-current">
                      Current Password
                    </Form.Label>
                    <Form.Control
                      id="update-password-field-current"
                      size="lg"
                      type="password"
                      name="password"
                      placeholder="Enter your password"
                      value={values.password}
                      isInvalid={Boolean(touched.password && errors.password)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {!!touched.password && (
                      <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="update-password-field-new">
                      New Password
                    </Form.Label>
                    <Form.Control
                      id="update-password-field-new"
                      size="lg"
                      type="password"
                      name="newPassword"
                      placeholder="New password"
                      value={values.newPassword}
                      isInvalid={Boolean(
                        touched.newPassword && errors.newPassword
                      )}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {!!touched.newPassword && (
                      <Form.Control.Feedback type="invalid">
                        {errors.newPassword}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="update-password-field-confirm">
                      Confirm Password
                    </Form.Label>
                    <Form.Control
                      id="update-password-field-confirm"
                      size="lg"
                      type="password"
                      name="passwordConfirmation"
                      placeholder="Confirm Password"
                      value={values.passwordConfirmation}
                      isInvalid={Boolean(
                        touched.passwordConfirmation &&
                          errors.passwordConfirmation
                      )}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {!!touched.passwordConfirmation && (
                      <Form.Control.Feedback type="invalid">
                        {errors.passwordConfirmation}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <div className="text-center mt-3">
                    <Button
                      type="submit"
                      variant="primary"
                      size="lg"
                      disabled={isSubmitting}
                    >
                      {isSubmitting && (
                        <Spinner
                          animation="border"
                          size="sm"
                          className="me-1"
                        />
                      )}
                      Update password
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

const Settings = () => {
  const [activeTab, setActiveTab] = useState("Account");
  return (
    <>
      <Helmet title="Settings" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Settings</h1>

        <Tab.Container id="left-tabs-example" defaultActiveKey="Account">
          <Row>
            <Col md="3" xl="2">
              <Navigation activeTab={activeTab} setActiveTab={setActiveTab} />
            </Col>
            <Col md="9" xl="10">
              <Tab.Content>
                <Tab.Pane eventKey="Account">
                  <PublicInfo />
                </Tab.Pane>
                <Tab.Pane eventKey="Password">
                  <UpdatePassword />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </>
  );
};

export default Settings;
