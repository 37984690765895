import React, { useEffect, useState } from "react";
// import { Button } from "react-bootstrap";
import RTable from "../../components/table";
import { distributionEventFamilyColumns } from "../tables/columns/distributionEvents";
import { filterFamilies } from "../../services/families";

const columns = distributionEventFamilyColumns();

const DistributionEventDetails = (props) => {
  const [eventFamilies, setEventFamilies] = useState([]);

  useEffect(() => {
    if (!props.families) {
      return;
    }
    filterFamilies({
      distributionEventId: props.id,
      page: 0,
      pageSize: props.families,
    }).then((result) => setEventFamilies(result.items));
  }, [props.id, props.families]);

  return (
    <div>
      <div className="d-flex justify-content-between mb-2">
        <h4>Families</h4>
      </div>
      <div>
        <RTable
          data={eventFamilies}
          columns={columns}
          height={256}
          name={`distributionEventFamilies-${props.id}`}
          rowsPerPage={720}
          noDetails // we need details here we need details here we need details here we need details here we need details here
          shouldUpdateScroll={false}
        />
      </div>
    </div>
  );
};

export default DistributionEventDetails;
