import React from "react";

/**
 * @param {{ size?: number } & React.SVGAttributes<SVGSVGElement>} props
 */
const CampaignIcon = ({ size = 148, color = "currentColor", ...props }) => (
  <svg width={size} height={size} viewBox="16 16 116 116" {...props}>
    <g
      transform="translate(0.000000,148.000000) scale(0.100000,-0.100000)"
      fill={color}
      stroke="none"
    >
      <path d="M930 1157 c-14 -7 -33 -29 -43 -49 -25 -51 -75 -92 -142 -117 -48 -18 -78 -21 -211 -21 -167 0 -199 -7 -223 -52 -7 -13 -25 -29 -41 -35 -33 -12 -70 -66 -70 -101 0 -38 28 -88 58 -103 15 -7 43 -30 62 -50 19 -20 41 -38 48 -40 8 -3 20 -45 31 -106 21 -123 36 -145 94 -141 29 2 45 10 59 29 18 24 19 32 9 100 -7 41 -14 84 -18 97 -5 21 -2 22 53 22 64 0 141 -18 190 -43 50 -26 94 -67 94 -88 0 -31 44 -69 80 -69 19 0 40 9 55 25 l25 24 0 335 c0 333 0 335 -22 360 -29 31 -57 39 -88 23z m53 -39 c16 -12 17 -42 15 -342 -3 -301 -5 -330 -20 -340 -13 -8 -23 -8 -35 0 -16 10 -18 39 -21 339 -2 328 0 355 32 355 7 0 20 -6 29 -12z m-103 -345 c0 -234 -1 -245 -17 -231 -40 35 -128 70 -192 76 l-66 7 -3 153 -3 152 40 0 c53 0 149 31 196 63 22 15 41 27 42 27 2 0 3 -111 3 -247z m-320 1 l0 -156 -93 4 c-60 2 -100 8 -110 17 -14 11 -17 34 -17 134 0 157 0 157 127 157 l93 0 0 -156z m-260 1 c0 -41 -4 -75 -9 -75 -18 0 -51 53 -51 81 0 28 30 69 50 69 6 0 10 -32 10 -75z m216 -260 c18 -102 17 -123 -6 -135 -37 -20 -55 2 -69 82 -7 40 -15 86 -17 101 -6 28 -5 28 37 25 l43 -3 12 -70z" />
      <path d="M1163 1078 c-61 -24 -75 -54 -20 -45 51 9 107 36 107 52 0 19 -23 17 -87 -7z" />
      <path d="M1145 790 c-13 -21 12 -31 71 -28 44 2 59 7 59 18 0 19 -119 28 -130 10z" />
      <path d="M1115 520 c-4 -7 -3 -16 1 -20 16 -15 115 -50 125 -45 22 14 5 35 -43 51 -29 9 -58 19 -64 21 -7 2 -15 -1 -19 -7z" />
    </g>
  </svg>
);

export default CampaignIcon;
