import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Button, Spinner } from "react-bootstrap";

import HeaderTaskBar from "./HeaderTasksBar";
import RTable from "../../../components/table";
import { DetailsTable } from "../../../components/table/RCTable";
import DetailsSidebar from "../../../components/DetailsSidebar";
import CreateCustomer from "./CreateCustomer";

import {
  exportFamilies,
  filterFamilies,
  fetchIdsByFilter,
  updateFamily,
} from "../../../services/families";
import { removeCampaignResponse } from "../../../services/campaign";
import { TableSettingsContext } from "../../../contexts/TableSettingsContext";
import NotyfContext from "../../../contexts/NotyfContext";
import { UtilityContext } from "../../../contexts/UtilityContext";
import useStateWithRef from "../../../hooks/useStateWithRef";
import useScreenSize from "../../../hooks/useScreenSize";
import useLocalStorage from "../../../hooks/useLocalStorage";
import familyColumns from "../../tables/columns/family";
import { downloadFile, isValidJsonFilter, lowerFirst } from "../../../utils";
import keyBy from "lodash/keyBy";
import HistoryTab from "./HistoryTab";
import MessagesTab from "./MessagesTab";
import DistributionsTab from "./DistributionsTab";
import { Download, Upload } from "react-feather";
import DoordashModal from "./DoordashModal";
import ImportFamiliesModal from "./ImportFamiliesModal";
import MoreFilters from "./MoreFilters";
import SendMessageToFamilies from "./SendMessageToFamilies";
import AttachSelectedFamiliesToDistribution from "./AttachSelectedFamiliesToDistribution";
import moment from "moment";
import { FilterModes, OR_FILTER } from "../../../constants";
import DoordashIcon from "../../../components/icons/DoordashIcon";

const formatFilters = (filters, columns) => {
  const newFilters = {};
  const newSearchParams = {};
  const columnByKey = keyBy(columns, "key");
  if (filters.saveFilter) {
    newFilters.name = filters.name;
    newFilters.saveFilter = filters.saveFilter;
    if (filters.filterId) {
      newFilters.filterId = filters.filterId;
    }
  }
  Object.keys(filters).forEach((_key) => {
    const key = lowerFirst(_key);
    const filterValue = filters[_key];
    const column = columnByKey?.[key] || {};
    if (filterValue || column.type === "boolean") {
      let jsonFilter;
      try {
        jsonFilter = JSON.parse(filterValue || "{}");
      } catch (_err) {}
      if (jsonFilter?.type === OR_FILTER) {
        newFilters[key] = jsonFilter;
        newSearchParams[key] = filterValue;
        return;
      }
      if (["detailsPopup", "savedFilter", "filterId"].includes(key)) {
        newSearchParams[key] = filterValue;
        return;
      }
      if (["name", "saveFilter"].includes(key)) {
        return;
      }
      if (["sortOptions"].includes(key)) {
        const options = JSON.parse(filterValue || "{}");
        if (!filterValue) {
          return;
        }
        if (!options.property) {
          delete newFilters[key];
          delete newSearchParams[key];
          return;
        }
        newFilters[key] = options;
        newSearchParams[key] = filterValue;
        return;
      }
      switch (column.type) {
        case "number":
        case "date": {
          const filter = JSON.parse(filterValue || "{}");
          if (!filterValue || !isValidJsonFilter(filter)) {
            break;
          }
          newFilters[key] = filter;
          newSearchParams[key] = filterValue;
          break;
        }
        case "enum": {
          const filter = JSON.parse(filterValue || "[]");
          if (!filterValue || !filter.length) {
            break;
          }
          if (filter.length === 1 && filter[0] === -1) {
            newFilters[key] = {
              value: "",
              mode: FilterModes.IsEmpty,
            };
          } else if (column.enumType === "checkbox") {
            newFilters[key] = filter.length === 1 ? filter[0] : filter;
          } else {
            newFilters[key] = filter[0];
          }
          newSearchParams[key] = filterValue;
          break;
        }
        case "boolean": {
          const booleanValue =
            filterValue === "true"
              ? "1"
              : filterValue === "false"
              ? "0"
              : filterValue;
          newFilters[key] = booleanValue;
          newSearchParams[key] = booleanValue;
          break;
        }
        default: {
          newFilters[key] = filterValue;
          newSearchParams[key] = filterValue;
        }
      }
    }
  });

  return { newFilters, newSearchParams };
};

const Default = () => {
  const [families, setFamilies] = useStateWithRef([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useStateWithRef(0);
  const [loading, setLoading] = useStateWithRef(false);
  const [totalRows, setTotalRows] = useStateWithRef(0);
  const [localQuery, setLocalQuery] = useLocalStorage("customers-query", {});
  const [searchTerm, setSearchTerm] = useState("");
  const [showDoordashModal, setShowDoordashModal] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);

  const { hiddenColumns } = useContext(TableSettingsContext);
  const {
    dropdownValues,
    initDropdownValues,
    initExceptionOptions,
    exceptionOptions,
  } = useContext(UtilityContext);
  const notyf = useContext(NotyfContext);
  const detailsSidebarRef = useRef({
    init() {},
    handleShowDetails() {},
    update() {},
  });
  const lastFilterStr = useRef("");
  const trBottomRef = useRef();
  const trTopRef = useRef();
  const { height } = useScreenSize();
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const selectedCount = allChecked ? totalRows.current : checkedKeys.length;
  const tableHeight = Math.max(height - (selectedCount ? 250 : 230), 360);

  useEffect(() => {
    initExceptionOptions();
  }, [initExceptionOptions]);

  const columns = useMemo(
    () =>
      familyColumns(dropdownValues, exceptionOptions)
        .filter((column) => !hiddenColumns.families?.[column.key])
        .map((column) => {
          if (column.type === "enum" && !column.values?.length) {
            return {
              ...column,
              values: dropdownValues[column.key] || [],
            };
          }

          return column;
        }),
    [hiddenColumns.families, dropdownValues, exceptionOptions]
  );

  const queryParams = useMemo(() => {
    const _queryParams = {};
    searchParams.forEach((value, key) => {
      if (!value) {
        return;
      }
      _queryParams[key] = value;
    });
    return _queryParams;
  }, [searchParams]);

  const getData = useCallback(
    async (filters, reachedBottom = false) => {
      try {
        if (
          reachedBottom &&
          (loading.current || families.current.length === totalRows.current)
        ) {
          return;
        }
        setLoading(true);
        const { newFilters, newSearchParams } = formatFilters(filters, columns);
        const newLastFilterStr = JSON.stringify(newFilters);
        if (newLastFilterStr === lastFilterStr.current && !reachedBottom) {
          setLoading(false);
          return;
        }
        setSearchParams(newLastFilterStr === "{}" ? {} : newSearchParams, {
          replace: true,
        });
        setLocalQuery(newLastFilterStr === "{}" ? {} : newSearchParams);
        const pageNumber = reachedBottom ? page.current + 1 : 0;
        try {
          const result = await filterFamilies({
            ...newFilters,
            page: pageNumber,
          });
          lastFilterStr.current = newLastFilterStr;
          const newData = [...result.items];
          if (reachedBottom) {
            // if (customers.current.length > 2 * 30) {
            //   trBottomRef.current.scrollIntoView();
            //   const newHiddenItemsTop = customers.current.splice(0, 30);
            //   hiddenItemsTop.current.push(...newHiddenItemsTop);
            //   setHiddenItemsTop([...hiddenItemsTop.current]);
            // }
            newData.unshift(...families.current);
          }
          setTotalRows(result.count);
          setFamilies(newData);
          setPage(pageNumber);
          setLoading(false);
          return newData;
        } catch (err) {
          console.error("The Error", err);
          setLoading(false);
          return [];
        }
      } catch (error) {
        const message =
          error?.[0]?.description || error.message || "Something went wrong";
        notyf.error(message);
        setLoading(false);
      }
    },
    [queryParams, columns]
  );

  const getIdsByFilter = useCallback(
    async (filters = queryParams) => {
      try {
        if (!allChecked) {
          return checkedKeys;
        }
        const { newFilters } = formatFilters(filters, columns);
        const result = await fetchIdsByFilter({
          ...newFilters,
          pageSize: totalRows.current,
        });
        return result;
      } catch (error) {
        const message =
          error?.[0]?.description || error.message || "Something went wrong";
        notyf.error(message);
        return [];
      }
    },
    [queryParams, allChecked, checkedKeys, columns]
  );

  useEffect(() => {
    if (allChecked) {
      setCheckedKeys(families.current.map((item) => item.id));
    }
  }, [families.current, allChecked]);

  useEffect(() => {
    setSearchTerm(queryParams.searchTerm || "");
  }, [queryParams.searchTerm]);

  useEffect(() => {
    if (!dropdownValues.inited) {
      initDropdownValues();
      return;
    }

    getData(!Object.keys(queryParams).length ? localQuery : queryParams).then(
      (data) => {
        const detailsPopupId = searchParams.get("detailsPopup");
        if (!detailsPopupId || !data) {
          return;
        }
        const detailsItem = data.find((item) => item.id === detailsPopupId);
        if (!detailsItem) {
          return;
        }
        detailsSidebarRef.current.init(detailsItem);
      }
    );
    // eslint-disable-next-line
  }, [dropdownValues.inited]);

  const onItemChange = useCallback(
    (itemData, propertyName, newValue) => {
      const customerIndex = families.current.findIndex(
        (customer) => customer.id === itemData.id
      );
      if (propertyName === "exception") {
        initExceptionOptions(true);
      }
      if (["deleted", "active"].includes(propertyName)) {
        if (propertyName === "active") {
          families.current[customerIndex] = {
            ...families.current[customerIndex],
            active: newValue,
          };
          detailsSidebarRef.current.update(families.current[customerIndex]);
        } else {
          detailsSidebarRef.current.handleShowDetails(
            families.current[customerIndex]
          );
          families.current.splice(customerIndex, 1);
        }
        setFamilies([...families.current]);
        return;
      }
      updateFamily({
        id: itemData.id,
        propertyName,
        newValue,
        currentValue: String(families.current[customerIndex][propertyName]),
      }).then(() => {
        families.current[customerIndex] = {
          ...families.current[customerIndex],
          [propertyName]: newValue,
        };
        detailsSidebarRef.current.update(families.current[customerIndex]);
        setFamilies([...families.current]);
        const column = columns.find((item) => item.key === propertyName);
        notyf.success(`${column?.title} saved!`);
      });
    },
    [columns, initExceptionOptions]
  );

  const handleBottomReached = useCallback(() => {
    getData(queryParams, true);
  }, [getData, queryParams]);

  // const handleTopReached = useCallback(() => {
  //   if (!hiddenItemsTop.current.length) {
  //     return;
  //   }
  //   const items = hiddenItemsTop.current.splice(
  //     hiddenItemsTop.current.length - 30
  //   );
  //   customers.current.splice(customers.current.length - 30);
  //   setCustomers([...items, ...customers.current]);
  //   setHiddenItemsTop([...hiddenItemsTop.current]);
  //   trTopRef.current.scrollIntoView();
  // }, []);

  const handleColumnFilter = useCallback(
    (column, value) => getData({ ...queryParams, [column]: value }),
    [getData, queryParams]
  );

  const handleColumnFilterClear = useCallback(
    (column) => {
      delete queryParams[column];
      return getData({ ...queryParams });
    },
    [getData, queryParams]
  );

  const handleFamiliesExport = async (exportType, doordashModel) => {
    try {
      const { newFilters } = formatFilters(queryParams, columns);
      const data = await exportFamilies(
        {
          pageSize: totalRows.current,
          ...newFilters,
          doordashModel,
        },
        exportType
      );
      downloadFile(
        data,
        `${exportType === 2 ? "doordash" : "masbia-export"}-${moment().format(
          "YY-MM-DD-h-m"
        )}.csv`
      );
    } catch (error) {
      const message =
        error?.[0]?.description || error.message || "Something went wrong";
      notyf.error(message);
    }
  };

  const handleCheck = (e) => {
    const id = Number(e.target.name);
    const newCheckedKeys = e.target.checked
      ? [...checkedKeys, id]
      : checkedKeys.filter((item) => item !== id);

    if (newCheckedKeys.length === families.current.length) {
      setAllChecked(true);
    } else if (allChecked) {
      setAllChecked(false);
    }
    setCheckedKeys(newCheckedKeys);
  };

  const handleCheckAll = (e) => {
    const checked = e.target.checked;
    setAllChecked(checked);
    if (!checked) {
      setCheckedKeys([]);
    }
  };

  const onRemoveCampaignResponse = async (familyId) => {
    const familyIndex = families.current.findIndex(
      (item) => item.id === familyId
    );
    const family = families.current[familyIndex];
    if (!family) {
      return;
    }
    try {
      await removeCampaignResponse(queryParams.campaignId, familyId);
      detailsSidebarRef.current.handleShowDetails(family);
      families.current.splice(familyIndex, 1);
      setFamilies([...families.current]);
    } catch (err) {}
  };

  return (
    <>
      <Helmet title="Families" />
      <DoordashModal
        show={showDoordashModal}
        onHide={() => setShowDoordashModal(false)}
        onSubmit={(data) => handleFamiliesExport(2, data)}
      />
      <ImportFamiliesModal
        show={showImportModal}
        onHide={() => setShowImportModal(false)}
      />
      <HeaderTaskBar
        queryParams={queryParams}
        onSubmit={getData}
        rightComponent={
          <div className="d-flex gap-2 flex-wrap">
            <Button
              variant="success"
              className="shadow"
              onClick={() => handleFamiliesExport(1)}
            >
              <Download size={20} className="me-1" /> Export
            </Button>
            <Button
              variant="info"
              className="shadow"
              onClick={() => setShowDoordashModal(true)}
            >
              <DoordashIcon size={20} color="white" className="me-1" />
              Doordash
            </Button>
            <Button
              variant="info"
              className="shadow"
              onClick={() => setShowImportModal(true)}
            >
              <Upload size={20} className="me-1" /> Import
            </Button>
            <CreateCustomer afterCreate={() => getData(queryParams)} />
          </div>
        }
        leftComponent={
          <MoreFilters
            filters={queryParams}
            searchTerm={searchTerm}
            onSearchTermChange={setSearchTerm}
            onSearch={() => getData({ ...queryParams, searchTerm })}
            onFilter={(newFilters) =>
              getData({ ...queryParams, ...newFilters })
            }
            onFilterClear={handleColumnFilterClear}
          />
        }
        filterType={1}
      />
      {!!selectedCount && (
        <div className="d-flex justify-content-between align-items-center pb-2 pt-1">
          <div>{`Selected count: ${selectedCount}`}</div>
          <div className="d-flex gap-2">
            <AttachSelectedFamiliesToDistribution
              count={selectedCount}
              getIdsByFilter={getIdsByFilter}
            />
            <SendMessageToFamilies
              count={selectedCount}
              getIdsByFilter={getIdsByFilter}
            />
          </div>
        </div>
      )}
      <div>
        <CustomerDetailsSidebar
          ref={detailsSidebarRef}
          {...{
            queryParams,
            tableHeight,
            onItemChange,
            onRemoveCampaignResponse,
          }}
        />
        <RTable
          data={families.current}
          columns={columns}
          onItemChange={onItemChange}
          height={tableHeight}
          name="customers"
          onBottomReached={handleBottomReached}
          rowsPerPage={30}
          onBottomTrInit={(ref) => (trBottomRef.current = ref)}
          onTopTrInit={(ref) => (trTopRef.current = ref)}
          onColumnFilter={handleColumnFilter}
          onColumnFilterClear={handleColumnFilterClear}
          filters={queryParams}
          handleShowDetails={detailsSidebarRef.current.handleShowDetails}
          withCheckbox
          checkedKeys={checkedKeys}
          onCheck={handleCheck}
          allChecked={allChecked}
          onCheckAll={handleCheckAll}
        />
        <div className="d-flex justify-content-between mt-2 ms-2">
          {loading.current && (
            <div className="mt-1">
              <Spinner animation="border" size="sm" /> Loading . . .
            </div>
          )}
          <span />
          {!!totalRows.current && <span>Total: {totalRows.current}</span>}
        </div>
      </div>
    </>
  );
};

const CustomerDetailsSidebar = forwardRef(
  (
    { queryParams, tableHeight, onItemChange, onRemoveCampaignResponse },
    ref
  ) => {
    const { dropdownValues, exceptionOptions } = useContext(UtilityContext);
    const [selectedItem, setSelectedItem] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();
    const detailsRef = useRef({ toggle() {} });
    const [activeKey, setActiveKey] = useState("pDetails");
    const id = selectedItem.id;

    const handleSetItem = useCallback(
      (item) => {
        detailsRef.current.toggle(item.id !== id);
        setSelectedItem(item);
      },
      [id]
    );

    const handleShowDetails = useCallback(
      (item) => {
        handleSetItem(item);
        const detailsPopupId = searchParams.get("detailsPopup");
        if (!detailsPopupId || item.id !== id) {
          setSearchParams(
            {
              ...queryParams,
              detailsPopup: item.id,
            },
            { replace: true }
          );
        } else {
          delete queryParams.detailsPopup;
          setSearchParams({ ...queryParams }, { replace: true });
        }
      },
      // `searchParams` and `setSearchParams` are dependencies of `queryParams`
      // `id` is dependency of `handleSetItem`
      // eslint-disable-next-line
      [handleSetItem, queryParams]
    );

    useImperativeHandle(
      ref,
      () => ({
        init: handleSetItem,
        handleShowDetails,
        update: (newValue) =>
          setSelectedItem((oldValue) => ({ ...oldValue, ...newValue })),
      }),
      [handleSetItem, handleShowDetails]
    );

    const columns = useMemo(
      () => familyColumns(dropdownValues, exceptionOptions),
      [dropdownValues, exceptionOptions]
    );

    return (
      <DetailsSidebar
        ref={detailsRef}
        height={tableHeight}
        activeKey={activeKey}
        setActiveKey={setActiveKey}
        onCloseRequest={() => handleShowDetails(selectedItem)}
        tabs={[
          {
            key: "cDetails",
            title: "Family details",
            Content: () => (
              <DetailsTable
                columns={columns}
                item={selectedItem}
                idKey="id"
                onItemChange={onItemChange}
                hasCampaignFilter={!!queryParams.campaignId}
                onRemoveCampaignResponse={onRemoveCampaignResponse}
              />
            ),
          },
          {
            key: "fHistory",
            title: "Family history",
            Content: () => <HistoryTab item={selectedItem} />,
          },
          {
            key: "messages",
            title: "Messages",
            Content: () => <MessagesTab item={selectedItem} />,
          },
          {
            key: "distributions ",
            title: "Distributions ",
            Content: () => <DistributionsTab item={selectedItem} />,
          },
        ]}
      />
    );
  }
);

export default Default;
