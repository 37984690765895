import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Accordion, Button, Card } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Edit2, Trash2 } from "react-feather";
import FormModal from "../../components/FormModal";
import Popconfirm from "../../components/Popconfirm";
import { DistributionContext } from "../../contexts/DistributionContext";
import { UtilityContext } from "../../contexts/UtilityContext";
import { TableSettingsContext } from "../../contexts/TableSettingsContext";
import { MessagesQueueContext } from "../../contexts/MessagesQueueContext";
import { Link, useParams } from "react-router-dom";
import DistributionEventFrom from "./DistributionEventFrom";
import DistributionEventDetails from "./DistributionEventDetails";
import {
  createEventFromDoordashFile,
  getDistributionEvents,
  updateDistributionEvent,
  deleteDistributionEvent,
} from "../../services/distributions";
import distributionColumns from "../tables/columns/distribution";
import { formatDate } from "../../utils";

const DistributionDetails = () => {
  const { id } = useParams();
  const [distributionEvents, setDistributionEvents] = useState([]);
  const [activeEventKey, setActiveEventKey] = useState(null);
  const [eventIdToDelete, setEventIdToDelete] = useState(0);

  const { dropdownValues, initDropdownValues } = useContext(UtilityContext);
  const { distributions, loadDistributions, updateDistribution } =
    useContext(DistributionContext);
  const { hiddenColumns } = useContext(TableSettingsContext);
  const { fetchQueueLength } = useContext(MessagesQueueContext);

  const createEventFormRef = useRef({ open() {} });
  const distributionFormRef = useRef({ open() {} });

  const distribution = useMemo(
    () => distributions.find((item) => item.id === Number(id)),
    [id, distributions]
  );

  const columns = useMemo(
    () =>
      distributionColumns(dropdownValues)
        .filter((column) => !hiddenColumns.distributions?.[column.key])
        .map((column) => {
          if (column.type === "enum" && !column.values?.length) {
            return {
              ...column,
              values: dropdownValues[column.key] || [],
            };
          }

          return column;
        }),
    [hiddenColumns.distributions, dropdownValues]
  );

  useEffect(() => {
    getDistributionEvents(id).then(setDistributionEvents);
  }, [id]);

  useEffect(() => {
    loadDistributions();
  }, [loadDistributions]);

  useEffect(() => {
    if (!dropdownValues.inited) {
      initDropdownValues();
    }
  }, [dropdownValues.inited]);

  const handleCreate = async (data) => {
    // change to form data
    data.messageId = data.messageId || 0;
    if (data.id) {
      updateDistributionEvent({
        id: data.id,
        name: data.eventName,
        notes: data.notes,
        distributionId: Number(id),
      }).then(() => {
        const index =
          distributionEvents.findIndex((item) => item.id === data.id) || {};
        if (index !== -1) {
          distributionEvents[index] = {
            ...distributionEvents[index],
            name: data.eventName,
            notes: data.notes,
          };
          setDistributionEvents([...distributionEvents]);
        }
      });
      return;
    }
    createEventFromDoordashFile({
      ...data,
      distributionId: id,
      createMessageQueue: true,
    }).then(() => {
      fetchQueueLength();
      setDistributionEvents([
        ...distributionEvents,
        {
          name: data.eventName,
          families: 0,
          created: new Date().toISOString(),
          notes: data.notes,
        },
      ]);
    });
    //await createDistributionEvent(id, data.name);
  };

  const handleUpdateDistribution = (data) =>
    updateDistribution(distribution.id, data.locationId, data.name);

  const handleDistributionEventDelete = async () => {
    try {
      await deleteDistributionEvent(eventIdToDelete);
      setDistributionEvents((oldValues) =>
        oldValues.filter((item) => item.id !== eventIdToDelete)
      );
    } catch (_err) {}
    setEventIdToDelete(0);
  };

  return (
    <>
      <Helmet title="Distribution Details" />
      <DistributionEventFrom ref={createEventFormRef} onCreate={handleCreate} />
      <Popconfirm
        title="Are you sure?"
        show={!!eventIdToDelete}
        onCancel={() => setEventIdToDelete(0)}
        onOk={handleDistributionEventDelete}
      />
      <FormModal
        ref={distributionFormRef}
        title="Update Distribution"
        columns={columns}
        onSubmit={handleUpdateDistribution}
        submitText="UPDATE"
      />
      <h3>Distribution Details</h3>
      <div>
        <Card className="mb-0">
          <Card.Body className="d-flex justify-content-between">
            <div>
              <div>
                <h4>{distribution?.name}</h4>
              </div>
              <div>
                <h4>
                  {
                    dropdownValues.masbiahLocationIdMap?.[
                      distribution?.locationId
                    ]
                  }
                </h4>
              </div>
              <div>
                <h4>{distribution?.events || "No"} Events</h4>
              </div>
              <div>
                <h4>
                  Created At:{" "}
                  {distribution?.created && formatDate(distribution.created)}
                </h4>
              </div>
            </div>
            <Button
              variant="link"
              onClick={() => distributionFormRef.current.open(distribution)}
            >
              <Edit2 size={24} />
            </Button>
          </Card.Body>
        </Card>
        <hr />
        <div>
          <div className="d-flex justify-content-between mb-2">
            <h3>Events</h3>
            <Button onClick={() => createEventFormRef.current.open()}>
              Create Event
            </Button>
          </div>
          <Accordion
            activeKey={activeEventKey}
            flush
            onSelect={setActiveEventKey}
          >
            {distributionEvents.map((distributionEvent, index) => (
              <Accordion.Item key={index} eventKey={index}>
                <Accordion.Header>
                  <div className="d-flex w-100">
                    <div
                      style={{ flex: 2 }}
                      className="d-flex justify-content-between"
                    >
                      <span className="text-nowrap me-2">
                        {distributionEvent.id && (
                          <Edit2
                            style={{ cursor: "pointer" }}
                            className="me-2"
                            size={20}
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              createEventFormRef.current.open({
                                ...distributionEvent,
                                eventName: distributionEvent.name,
                              });
                            }}
                          />
                        )}
                        <b>{distributionEvent.name}</b>
                        {distributionEvent.notes && (
                          <ul>
                            {distributionEvent.notes.split("\n").map((x) => (
                              <li>{x}</li>
                            ))}
                          </ul>
                        )}
                      </span>
                    </div>
                    <div style={{ flex: 1 }} className="ms-3">
                      <Link
                        to={
                          distributionEvent.id
                            ? `/families?distributionEventId=${distributionEvent.id}`
                            : "#"
                        }
                      >
                        {distributionEvent.families || "No"} Families
                      </Link>
                    </div>
                    <div style={{ flex: 1 }}>
                      {formatDate(distributionEvent.created)}
                    </div>
                    <div style={{ flex: 1 }} className="text-danger">
                      <Trash2
                        style={{
                          cursor: distributionEvent.id
                            ? "pointer"
                            : "not-allowed",
                          opacity: distributionEvent.id ? 1 : 0.5,
                        }}
                        size={18}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (distributionEvent.id) {
                            setEventIdToDelete(distributionEvent.id);
                          }
                        }}
                      />
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  {activeEventKey === index && (
                    <DistributionEventDetails {...distributionEvent} />
                  )}
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default DistributionDetails;
