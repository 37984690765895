import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import NotyfContext from "../../../contexts/NotyfContext";
import { DistributionContext } from "../../../contexts/DistributionContext";
import { attachFamiliesToDistribution } from "../../../services/distributions";

/**
 * @param {{
 *  count: number;
 *  getIdsByFilter: () => Promise<Array<number>>;
 * }} props
 */
const AttachSelectedFamiliesToDistribution = (props) => {
  const notyf = useContext(NotyfContext);
  const { distributions, loadDistributions } = useContext(DistributionContext);
  const [show, setShow] = useState(false);
  const [distributionId, setDistributionId] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadDistributions();
  }, [loadDistributions]);

  const handleClose = () => {
    setShow(false);
    setDistributionId(0);
  };

  const handleAttach = async () => {
    try {
      const familyIds = await props.getIdsByFilter();
      await attachFamiliesToDistribution(distributionId, familyIds);
      handleClose();
      notyf.success("Successfully attached!");
    } catch (err) {
      notyf.error("Failed to attach families!");
    }
    setLoading(false);
  };

  return (
    <div>
      <Modal show={show && !!props.count} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {`Attach ${props.count} ${
              props.count === 1 ? "family" : "families"
            } to distribution`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Distributions</Form.Label>
            <Form.Select
              name="distributionId"
              value={distributionId}
              onChange={(e) => setDistributionId(+e.target.value)}
            >
              <option key="0" value="0">
                -- select distribution --
              </option>
              {distributions.map((distribution) => (
                <option key={distribution.id} value={distribution.id}>
                  {distribution.name || distribution.message}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" disabled={loading} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={!distributionId || loading}
            onClick={handleAttach}
          >
            {loading && (
              <Spinner animation="border" size="sm" className="me-2" />
            )}
            ATTACH
          </Button>
        </Modal.Footer>
      </Modal>
      <Button variant="light" onClick={() => setShow(true)}>
        Attach to distribution
      </Button>
    </div>
  );
};

export default AttachSelectedFamiliesToDistribution;
