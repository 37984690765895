import React, { useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Formik } from "formik";
import * as Yup from "yup";

/**
 * @param {{
 *  show: boolean;
 *  onHide: () => void;
 *  onSubmit: (data: any) => Promise<void>;
 * }} props
 */
const DoordashModal = ({ show, onHide, onSubmit }) => {
  return (
    <Modal show={show} onHide={onHide}>
      <DoordashModalContent onHide={onHide} onSubmit={onSubmit} />
    </Modal>
  );
};

/**
 * @param {{
 *  onHide: () => void;
 *  onSubmit: (data: any) => Promise<void>;
 * }} props
 */
const DoordashModalContent = ({ onSubmit, onHide }) => {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Export Doordash</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            pickupLocation: "",
            orderIdStartNumber: "",
            dateOfDelivery: new Date(),
            pickupWindowStart: new Date().setMinutes(0),
            pickupWindowEnd: new Date().setMinutes(0),
            pickupPhoneNumber: "",
            orderVolume: "",
            numberOfItems: "",
          }}
          validationSchema={Yup.object().shape({
            // pickupLocation: Yup.string().required(
            //   "Pickup location is required"
            // ),
            // orderIdStartNumber: Yup.string().required(
            //   "Order id start number is required"
            // ),
            // dateOfDelivery: Yup.date().required("Date of delivery is required"),
            // pickupWindowStart: Yup.date().required(
            //   "Pickup window start is required"
            // ),
            // pickupWindowEnd: Yup.date().required(
            //   "Pickup window end is required"
            // ),
            // pickupPhoneNumber: Yup.string().required(
            //   "Pickup phone number is required"
            // ),
            // orderVolume: Yup.string().required("Order volume is required"),
            // numberOfItems: Yup.string().required("Number of items is required"),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            setLoading(true);
            
            const pickupWindowStart = new Date(values.pickupWindowStart);
            const pickupWindowStartString = pickupWindowStart.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
            const pickupWindowEnd = new Date(values.pickupWindowEnd);
            const pickupWindowEndString = pickupWindowEnd.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});

            try {
              await onSubmit({
                ...values,
                pickupWindowStart: pickupWindowStartString,
                pickupWindowEnd: pickupWindowEndString,
                orderIdStartNumber: Number(values.orderIdStartNumber),
                orderVolume: Number(values.orderVolume),
                numberOfItems: Number(values.numberOfItems),
              });
              onHide();
            } catch (error) {
              const message =
                error?.[0]?.description ||
                error.message ||
                "Something went wrong";

              setStatus({ success: false });
              setErrors({ submit: message });
              setSubmitting(false);
            }
            setLoading(false);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
          }) => (
            <Form id="doordash-export-form" onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Pickup location</Form.Label>
                <Form.Control
                  name="pickupLocation"
                  value={values.pickupLocation}
                  isInvalid={Boolean(
                    touched.pickupLocation && errors.pickupLocation
                  )}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {errors.pickupLocation && !!touched.pickupLocation && (
                  <Form.Control.Feedback type="invalid">
                    {errors.pickupLocation}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Order id start number</Form.Label>
                <Form.Control
                  name="orderIdStartNumber"
                  value={values.orderIdStartNumber}
                  type="number"
                  isInvalid={Boolean(
                    touched.orderIdStartNumber && errors.orderIdStartNumber
                  )}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {errors.orderIdStartNumber && !!touched.orderIdStartNumber && (
                  <Form.Control.Feedback type="invalid">
                    {errors.orderIdStartNumber}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Date of delivery</Form.Label>
                <DatePicker
                  className="form-control"
                  selected={values.dateOfDelivery}
                  onBlur={handleBlur}
                  onChange={(value) =>
                    handleChange({ target: { name: "dateOfDelivery", value } })
                  }
                />
                {errors.dateOfDelivery && !!touched.dateOfDelivery && (
                  <Form.Control.Feedback type="invalid">
                    {errors.dateOfDelivery}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Pickup window start</Form.Label>
                <DatePicker
                  className="form-control"
                  selected={values.pickupWindowStart}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={10}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  onChange={(value) =>
                    handleChange({
                      target: { name: "pickupWindowStart", value },
                    })
                  }
                />
                {errors.pickupWindowStart && !!touched.pickupWindowStart && (
                  <Form.Control.Feedback type="invalid">
                    {errors.pickupWindowStart}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Pickup window end</Form.Label>
                <DatePicker
                  className="form-control"
                  selected={values.pickupWindowEnd}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={10}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  onChange={(value) =>
                    handleChange({ target: { name: "pickupWindowEnd", value } })
                  }
                />
                {errors.pickupWindowEnd && !!touched.pickupWindowEnd && (
                  <Form.Control.Feedback type="invalid">
                    {errors.pickupWindowEnd}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Pickup phone number</Form.Label>
                <Form.Control
                  name="pickupPhoneNumber"
                  value={values.pickupPhoneNumber}
                  isInvalid={Boolean(
                    touched.pickupPhoneNumber && errors.pickupPhoneNumber
                  )}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {errors.pickupPhoneNumber && !!touched.pickupPhoneNumber && (
                  <Form.Control.Feedback type="invalid">
                    {errors.pickupPhoneNumber}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Order volume</Form.Label>
                <Form.Control
                  name="orderVolume"
                  value={values.orderVolume}
                  type="number"
                  isInvalid={Boolean(touched.orderVolume && errors.orderVolume)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {errors.orderVolume && !!touched.orderVolume && (
                  <Form.Control.Feedback type="invalid">
                    {errors.orderVolume}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Number of items</Form.Label>
                <Form.Control
                  name="numberOfItems"
                  value={values.numberOfItems}
                  type="number"
                  isInvalid={Boolean(
                    touched.numberOfItems && errors.numberOfItems
                  )}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {errors.numberOfItems && !!touched.numberOfItems && (
                  <Form.Control.Feedback type="invalid">
                    {errors.numberOfItems}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" disabled={loading} onClick={onHide}>
          Cancel
        </Button>
        <Button
          type="submit"
          variant="primary"
          disabled={loading}
          form="doordash-export-form"
        >
          {loading && <Spinner animation="border" size="sm" className="me-2" />}
          Export
        </Button>
      </Modal.Footer>
    </>
  );
};

export default DoordashModal;
