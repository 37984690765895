import React from "react";

import PerfectScrollbar from "react-perfect-scrollbar";

import useSidebar from "../../hooks/useSidebar";
import SidebarNav from "./SidebarNav";
import TCSettingsModal from "../TCSettingsModal";
import Logo from "../../assets/img/logoIcon.png";

const Sidebar = ({ items }) => {
  const { isOpen } = useSidebar();

  return (
    <nav className={`sidebar ${!isOpen ? "collapsed" : ""}`}>
      <div className="sidebar-content">
        <TCSettingsModal />
        <PerfectScrollbar>
          <a className="sidebar-brand" href="/">
            <img src={Logo} width={32} className="me-1" alt="logo" />
            <span className="align-middle me-3">Masbia Portal</span>
          </a>

          <SidebarNav items={items} />
        </PerfectScrollbar>
      </div>
    </nav>
  );
};

export default Sidebar;
