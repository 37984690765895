import React, { useContext, useMemo } from "react";
import { useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { store } from "./redux/store";

import "./i18n";
import routes from "./routes";

import { ThemeProvider } from "./contexts/ThemeContext";
import { SidebarProvider } from "./contexts/SidebarContext";
import { LayoutProvider } from "./contexts/LayoutContext";
import { PresetMessagesProvider } from "./contexts/PresetMessagesContext";
import { UtilityProvider } from "./contexts/UtilityContext";
import { TableSettingsProvider } from "./contexts/TableSettingsContext";
import ChartJsDefaults from "./utils/ChartJsDefaults";

import { AuthContext, AuthProvider } from "./contexts/JWTContext";
import { DistributionProvider } from "./contexts/DistributionContext";
import { CampaignProvider } from "./contexts/CampaignContext";
import { MessagesQueueProvider } from "./contexts/MessagesQueueContext";

const App = () => (
  <HelmetProvider>
    <Helmet titleTemplate="%s | Masbia" defaultTitle="Masbia" />
    <Provider store={store}>
      <ThemeProvider>
        <SidebarProvider>
          <LayoutProvider>
            <ChartJsDefaults />
            <AuthProvider>
              <UtilityProvider>
                <TableSettingsProvider>
                  <MessagesQueueProvider>
                    <DistributionProvider>
                      <CampaignProvider>
                        <PresetMessagesProvider>
                          <Content />
                        </PresetMessagesProvider>
                      </CampaignProvider>
                    </DistributionProvider>
                  </MessagesQueueProvider>
                </TableSettingsProvider>
              </UtilityProvider>
            </AuthProvider>
          </LayoutProvider>
        </SidebarProvider>
      </ThemeProvider>
    </Provider>
  </HelmetProvider>
);

const Content = () => {
  const { user } = useContext(AuthContext);

  const routesFiltered = useMemo(
    () =>
      routes.map((item) => ({
        path: item.path,
        element: item.element,
        children: item.children.filter(
          (child) => !child.role || (user && child.role.includes(user.role))
        ),
      })),
    [user]
  );
  const content = useRoutes(routesFiltered);

  return content;
};

export default App;
