import { createContext, useEffect, useReducer } from "react";
import { getUsers, login, me } from "../services/users";

import { isValidToken, setSession } from "../utils/jwt";

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const REFRESH_USERS = "REFRESH_USERS";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  users: [],
  fullUser: {},
};

const JWTReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
        users: action.payload.users,
        fullUser: action.payload.fullUser,
      };
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        users: action.payload.users,
        fullUser: action.payload.fullUser,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    case REFRESH_USERS:
      return {
        ...state,
        user: action.payload.user,
        users: action.payload.users,
        fullUser: action.payload.fullUser,
      };

    default:
      return state;
  }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  async function refreshUsers() {
    const user = await me();
    const users = await getUsers();
    const fullUser = users.find(
      (item) => item.email === user.email && item.userName === user.userName
    );
    dispatch({ type: REFRESH_USERS, payload: { user, users, fullUser } });
  }

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const user = await me();
          const users = await getUsers();
          const fullUser = users.find(
            (item) =>
              item.email === user.email && item.userName === user.userName
          );

          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: true,
              user,
              users,
              fullUser,
            },
          });
        } else {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const signIn = async (email, password) => {
    const { token: accessToken } = await login(email, password);
    setSession(accessToken);
    const user = await me();
    const users = await getUsers();
    const fullUser = users.find(
      (item) => item.email === user.email && item.userName === user.userName
    );

    dispatch({
      type: SIGN_IN,
      payload: {
        user,
        users,
        fullUser,
      },
    });
  };

  const signOut = async () => {
    setSession(null);
    dispatch({ type: SIGN_OUT });
  };

  const resetPassword = (email) => console.log(email);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        signIn,
        signOut,
        resetPassword,
        refreshUsers,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
