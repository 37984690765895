//   "RPIEReasonNotFiled"
//   "InactivityReason"
//   "ClipadYesNewOptions",

/**
 * @param {Record<string, Array<string>>} optionArrays
 * @param {Record<string, Array<string>>} enums
 * @param {Record<string, Record<string, string>>} backgroundByValue
 * @param {import("react").FC<{ item: any }>} NotesIcon
 * @returns {Array<import("./family").TableColumnConfig>}
 */
const getRPIEColumns = (optionArrays, enums, backgroundByValue, NotesIcon) => {
  const ShowValue = ({ value = null, enumKey }) =>
    enums?.[enumKey]?.[value] ? (
      <span title={enums?.[enumKey]?.[value]?.key}>{value}</span>
    ) : (
      value
    );
  return [
    {
      key: "index",
      title: "No.",
      render: (keyData, rowData) => (
        <div className="d-flex justify-content-between">
          <span style={{ minWidth: 28 }}>{keyData}</span>
          <NotesIcon item={rowData} />
        </div>
      ),
      noResize: true,
      noSort: true,
      noFilter: true,
      fixed: "left",
      width: 54,
    },
    { key: "notes", title: "Notes", type: "string", simple: true },
    { key: "clientID", title: "Client ID", type: "string", simple: true },
    { key: "bbl", title: "BBL", type: "string", simple: true },
    {
      key: "propertyAddress",
      title: "Property Address",
      type: "string",
      simple: true,
    },
    {
      key: "representative",
      title: "Representative",
      type: "string",
      simple: true,
    },
    {
      key: "newOld",
      title: "New Old",
      type: "boolean",
      labels: { true: "New", false: "Old" },
      showValue: (value) => <ShowValue enumKey="OldNewOptions" value={value} />,
      simple: true,
    },
    {
      key: "rpieRequired",
      title: "RPIE Required",
      type: "enum",
      values: optionArrays?.RpieRequiredOptions || [],
      showValue: (value) => (
        <ShowValue enumKey="RpieRequiredOptions" value={value} />
      ),
      simple: true,
    },
    {
      key: "rpieRequired_LastYear",
      title: "RPIE Required Last Year",
      type: "enum",
      values: optionArrays?.RpieRequiredOptions || [],
      showValue: (value) => (
        <ShowValue enumKey="RpieRequiredOptions" value={value} />
      ),
      simple: true,
    },
    {
      key: "rpienrReason_LastYearText",
      title: "RPIENR Reason Text Last Year",
      type: "enum",
      values: optionArrays?.RPIENotRequiredReasonOptions || [],
      simple: true,
    },
    {
      key: "rpienrReasonText",
      title: "RPIENR Reason Text",
      type: "enum",
      values: optionArrays?.RPIENotRequiredReasonOptions || [],
      simple: true,
    },
    {
      key: "username",
      title: "Username",
      type: "string",
      simple: true,
    },
    {
      key: "password",
      title: "Password",
      type: "string",
      simple: true,
    },
    {
      key: "rpieRequired_PortalBased",
      title: "RPIE Required Portal Based",
      type: "enum",
      values: optionArrays?.RpieRequiredOptions || [],
      showValue: (value) => (
        <ShowValue enumKey="RpieRequiredOptions" value={value} />
      ),
      simple: true,
    },
    {
      key: "rpieRequired_TestBased",
      title: "RPIE Required Test Based",
      type: "string",
      simple: true,
    },
    {
      key: "rpienrReason_TestBasedText",
      title: "RPIE Required Test Based Text",
      type: "string",
      simple: true,
    },
    {
      key: "activityStatusText",
      title: "Activity Status Text",
      type: "enum",
      values: optionArrays?.ActivityStatusOptions || [],
      showValue: (value) => (
        <ShowValue enumKey="ActivityStatusOptions" value={value} />
      ),
      simple: true,
    },
    {
      key: "inactivityReasonText",
      title: "Inactivity Reason Text",
      type: "enum",
      values: optionArrays?.RPIEINactivityReason || [],
      showValue: (value) => (
        <ShowValue enumKey="RPIEINactivityReason" value={value} />
      ),
      simple: true,
    },
    {
      key: "ieRequired",
      title: "IE Required",
      type: "enum",
      values: ["Y", "N"],
      simple: true,
    },
    {
      key: "excType",
      title: "EXC Type",
      type: "enum",
      values: optionArrays?.ExclusionTypeOptions || [],
      simple: true,
    },
    {
      key: "excTypeLastYear",
      title: "EXC Type Last Year",
      type: "enum",
      values: optionArrays?.ExclusionTypeOptions || [],
      simple: true,
    },
    {
      key: "dateRecvdAuth",
      title: "Date Recvd Auth",
      type: "date",
      simple: true,
    },
    {
      key: "lateFiler",
      title: "Late Filer",
      type: "enum",
      values: optionArrays?.LateFilerOptions,
      showValue: (value) => (
        <ShowValue enumKey="LateFilerOptions" value={value} />
      ),
      simple: true,
    },
    {
      key: "filingStatus",
      title: "Filing Status",
      type: "enum",
      values: optionArrays?.FilingStatusOptions,
      backgroundByValue,
      showValue: (value) => (
        <ShowValue enumKey="FilingStatusOptions" value={value} />
      ),
    },
    {
      key: "ieStatus",
      title: "IE Status",
      type: "enum",
      values: optionArrays?.IEStatusOptions,
      backgroundByValue,
      showValue: (value) => (
        <ShowValue enumKey="IEStatusOptions" value={value} />
      ),
    },
    {
      key: "storefrontRegistryStatus",
      title: "Storefront Registry Status",
      type: "enum",
      values: optionArrays?.StorefrontRegistryStatus || [],
      backgroundByValue,
      showValue: (value) => (
        <ShowValue enumKey="StorefrontRegistryStatus" value={value} />
      ),
    },
    {
      key: "supplementalSFStatus",
      title: "Supplemental SF Status",
      type: "enum",
      values: optionArrays?.SupplementalSFStatus,
      backgroundByValue,
      showValue: (value) => (
        <ShowValue enumKey="SupplementalSFStatus" value={value} />
      ),
    },
    {
      key: "rrrStatus",
      title: "RRR Status",
      type: "enum",
      values: optionArrays?.RentRollStatus,
      backgroundByValue,
      simple: true,
    },
    {
      key: "crrStatus",
      title: "CRR Status",
      type: "enum",
      values: optionArrays?.RentRollStatus,
      backgroundByValue,
      simple: true,
    },
    {
      key: "rrType",
      title: "RR Type",
      type: "enum",
      values: optionArrays?.RentRoleTypeOptions,
      simple: true,
    },
    // { key: "username", title: "Username", type: "string", simple: true },
    // { key: "password", title: "Password", type: "string", simple: true },
    {
      key: "applicantName",
      title: "Applicant Name",
      type: "string",
      simple: true,
    },
    { key: "einNumber", title: "Ein Number", type: "string", simple: true },
    {
      key: "consolidateNumber",
      title: "Consolidate Number",
      type: "number",
      simple: true,
    },
    {
      key: "consolidateNumberLastYear",
      title: "Consolidate Number Last Year",
      type: "number",
      simple: true,
    },
    { key: "dateFiled", title: "Date Filed", type: "date", simple: true },
    {
      key: "rpiE_DOFConfirmation",
      title: "RPIE Dof Confirmation",
      type: "string",
      simple: true,
    },
    {
      key: "sF_DOFConfirmation",
      title: "SF Dof Confirmation",
      type: "string",
      simple: true,
    },
    {
      key: "currentActualTotal",
      title: "Current Actual Total",
      type: "number",
      simple: true,
    },
    {
      key: "buildingClass",
      title: "Building Class",
      type: "string",
      simple: true,
    },
    { key: "taxClass", title: "Tax Class", type: "string", simple: true },
    {
      key: "numberofResUnits",
      title: "Numberof Res Units",
      type: "number",
      simple: true,
    },
    {
      key: "numberofCommUnits",
      title: "Numberof Comm Units",
      type: "number",
      simple: true,
    },
    {
      key: "rpieGeneralNotes",
      title: "RPIE General Notes",
      type: "string",
      simple: true,
    },
    {
      key: "rpieAnnualNotes",
      title: "RPIE Annual Notes",
      type: "string",
      simple: true,
    },
    {
      key: "filedRPIELastYearYesNo",
      title: "Filed RPIE Last Year Yes No",
      type: "enum",
      values: optionArrays?.YesNoOptions || [],
      showValue: (value) => <ShowValue enumKey="YesNoOptions" value={value} />,
      simple: true,
    },
    {
      key: "reasonRPIELastYearnotfiledText",
      title: "Reason RPIE Last Year Not Filed Text",
      type: "string",
      values: optionArrays?.ReasonNotFiled || [],
      showValue: (value) => (
        <ShowValue enumKey="ReasonNotFiled" value={value} />
      ),
      simple: true,
    },
    {
      key: "filedResRRLastYearYesNo",
      title: "Filed Res RR Last Year Yes No",
      type: "enum",
      values: optionArrays?.YesNoOptions || [],
      showValue: (value) => <ShowValue enumKey="YesNoOptions" value={value} />,
      simple: true,
    },
    {
      key: "filedComRRLastYearYesNo",
      title: "Filed Com RR Last Year Yes No",
      type: "enum",
      values: optionArrays?.YesNoOptions || [],
      showValue: (value) => <ShowValue enumKey="YesNoOptions" value={value} />,
      simple: true,
    },
    {
      key: "filedSuppSFLastYearYesNo",
      title: "Filed Supp SF Last Year Yes No",
      type: "enum",
      values: optionArrays?.YesNoOptions || [],
      showValue: (value) => <ShowValue enumKey="YesNoOptions" value={value} />,
      simple: true,
    },
    { key: "sfTypeText", title: "SF Type Text", type: "string", simple: true },
    {
      key: "sfTypeLastYearText",
      title: "SF Type Last Year Text",
      type: "string",
      simple: true,
    },
    {
      key: "numberofRetailUnitsLastYear",
      title: "Numberof Retail Units Last Year",
      type: "number",
      simple: true,
    },
    { key: "tcAppDate", title: "TC App Date", type: "string", simple: true },
    { key: "tcHearing", title: "TC Hearing", type: "string", simple: true },
    {
      key: "tcPassReason",
      title: "TC Pass Reason",
      type: "enum",
      values: optionArrays?.PassReasonOptions || [],
      simple: true,
    },
    {
      key: "tcIeNrReason",
      title: "TC Ie Nr Reason",
      type: "string",
      simple: true,
    },
    {
      key: "dateBBLCreated",
      title: "Date BBL Created",
      type: "date",
      dateType: "day",
      simple: true,
    },
    {
      key: "numberRetailUnits",
      title: "Number Retail Units",
      type: "number",
      simple: true,
    },
    {
      key: "payStatus",
      title: "Pay Status",
      type: "enum",
      values: optionArrays?.PayStatus || [],
      backgroundByValue,
      showValue: (value) => <ShowValue enumKey="PayStatus" value={value} />,
    },
    {
      key: "rpieBillingType",
      title: "RPIE Billing Type",
      type: "string",
      simple: true,
    },
    {
      key: "storefrontType_NumberOfUnits",
      title: "Storefront Type Number Of Units",
      type: "enum",
      values: optionArrays?.RPIEStorefrontTypeOptions || [],
      showValue: (value) => (
        <ShowValue enumKey="RPIEStorefrontTypeOptions" value={value} />
      ),
      simple: true,
    },
    {
      key: "discountType",
      title: "Discount Type",
      type: "string",
      simple: true,
    },
    {
      key: "rpieBillingAmount",
      title: "RPIE Billing Amount",
      type: "string",
      simple: true,
    },
    {
      key: "storefrontBillingAmount",
      title: "Storefront Billing Amount",
      type: "string",
      simple: true,
    },
    {
      key: "discountAmount",
      title: "Discount Amount",
      type: "string",
      simple: true,
    },
    {
      key: "invoiceNumber",
      title: "Invoice Number",
      type: "string",
      simple: true,
    },
    { key: "paymentRef", title: "Payment Ref", type: "string", simple: true },
    {
      key: "oldBalanceText",
      title: "Old Balance Text",
      type: "string",
      simple: true,
    },
    { key: "totalUnits", title: "Total Units", type: "string", simple: true },
    {
      key: "hasStorefront",
      title: "Has Storefront",
      type: "string",
      simple: true,
    },
    { key: "grossSqft", title: "Gross Sqft", type: "number", simple: true },
    { key: "hotelSqft", title: "Hotel Sqft", type: "number", simple: true },
    { key: "officeSqft", title: "Office Sqft", type: "number", simple: true },
    {
      key: "residentialSqft",
      title: "Residential Sqft",
      type: "number",
      simple: true,
    },
    { key: "retailSqft", title: "Retail Sqft", type: "number", simple: true },
    { key: "loftSqft", title: "Loft Sqft", type: "number", simple: true },
    { key: "storageSqft", title: "Storage Sqft", type: "number", simple: true },
    { key: "garageSqft", title: "Garage Sqft", type: "number", simple: true },
    {
      key: "otherAreaSqft",
      title: "Other Area Sqft",
      type: "number",
      simple: true,
    },
    {
      key: "nycPortalError",
      title: "NYC Portal Error",
      type: "boolean",
      simple: true,
    },
    {
      key: "officialSignerName",
      title: "Official Signer Name",
      type: "string",
      simple: true,
    },
  ];
};

export default getRPIEColumns;
