import ReactAudioPlayer from "react-audio-player";

/**
 * @typedef {Object} TableColumnConfig
 * @property {string} key
 * @property {string} title
 * @property {"enum"|"number"|"string"|"boolean"|"date"} type
 * @property {"radio"|"checkbox"} enumType `radio` by default
 * @property {"day"|"dayTime"|"time"} [dateType]
 * @property {boolean|"left"|"right"} fixed
 * @property {number} [width]
 * @property {number} [flexGrow]
 * @property {boolean} [noResize]
 * @property {(value: any) => string} [getClassName]
 * @property {boolean} [simple] makes field not editable
 * @property {boolean} [noFilter]
 * @property {boolean} [raw]
 * @property {boolean} [noEmpty]
 * @property {boolean} [labels] Labels of `true` and `false` for boolean fields
 * @property {boolean} [hidden] currently does not hide field in filters
 * @property {(keyData: any, rowData: any, index: number, actualIndex: number) => import("react").ReactNode} [render]
 * @property {import("rsuite-table/lib/Column").ColumnProps} [columnProps]
 * @property {(value: any) => import("react").ReactNode} [showValue]
 * @property {Array<string|number|{ title: string; value: string|number }>} [values] has effect only when `type="enum"`
 * @property {Record<string, string>} [backgroundByValue]
 * @property {object} [form]
 * @property {boolean} [form.required]
 * @property {boolean} [form.radio] has effect only when `type="enum"`
 * @property {boolean} [form.allowCreate] has effect only when `type="enum"`
 * @property {(values: any) => boolean} [form.hide]
 */
/**
 * @type {(enums: any) => Array<TableColumnConfig>}
 */
const familyColumns = (enums, exceptionOptions = []) => {
  const ShowValue = ({ value = null, enumKey }) =>
    enums?.[`${enumKey}Map`] ? (
      <span>{enums?.[`${enumKey}Map`]?.[value]}</span>
    ) : (
      value
    );
  return [
    { key: "id", title: "ID", type: "string", simple: true },
    {
      key: "firstName",
      title: "First Name",
      type: "string",
      form: {},
      // fixed: "left",
    },
    {
      key: "lastName",
      title: "Last Name",
      type: "string",
      form: {},
      // getClassName: (value) =>
      // value && "edit-placeholder w-auto h-auto badge bg-success ps-1 pe-1",
      // values: ["", "A", "B", "C", "D", "E", "F", "G"],
    },
    {
      key: "phone",
      title: "Phone",
      type: "string",
      form: {},
    },
    { key: "phoneCarrier", title: "Phone Carrier", type: "string" },
    { key: "phoneType", title: "Phone Type", type: "string", simple: true },
    {
      key: "phone2",
      title: "Phone 2",
      type: "string",
      form: {},
    },
    {
      key: "phone3",
      title: "Phone 3",
      type: "string",
      form: {},
    },
    {
      key: "phone4",
      title: "Phone 4",
      type: "string",
      form: {},
    },
    {
      key: "email",
      title: "Email",
      type: "string",
      form: {},
    },
    {
      key: "addressLine1",
      title: "Address",
      type: "string",
      form: {},
    },
    {
      key: "appartmentNumber",
      title: "Appartment Number",
      type: "string",
      form: {},
    },
    { key: "city", title: "City", type: "string", form: {} },
    { key: "state", title: "State", type: "string", form: {} },
    { key: "zip", title: "Zip", type: "string", form: {} },
    { key: "deliveryNote", title: "Delivery Note", type: "string" },
    { key: "active", title: "Active", type: "boolean" },
    {
      key: "numberOfSeniors",
      title: "Number of Seniors",
      type: "number",
      form: {},
    },
    {
      key: "numberOfAdults",
      title: "Number of Adults",
      type: "number",
      form: {},
    },
    {
      key: "numberOfChildren",
      title: "Number of Children",
      type: "number",
      form: {},
    },
    {
      key: "totalHouseholdSize",
      title: "Total Household",
      type: "number",
      form: {},
    },
    { key: "boysYeshivah", title: "Boys Yeshiva", type: "string", form: {} },
    { key: "girlsYeshivah", title: "Girls School", type: "string", form: {} },
    { key: "dob", title: "Date of Birth", type: "string", form: {} },
    {
      key: "masbiahLocationId",
      title: "Masbia Location",
      type: "enum",
      showValue: (value) => (
        <ShowValue enumKey="masbiahLocationId" value={value} />
      ),
      values: enums?.masbiahLocationId || [],
      form: {},
    },
    { key: "optedIn", title: "Opted In", type: "boolean" },
    {
      key: "updateAddressAudioUrl",
      title: "Update Address Audio URL",
      type: "string",
      width: 325,
      showValue: (src) =>
        src && (
          <ReactAudioPlayer src={src} controls style={{ height: "30px" }} />
        ),
    },
    { key: "recordingDateTime", title: "Recording Date", type: "date" },
    {
      key: "updateAddressTranscription",
      title: "Update Address Transcription",
      type: "string",
    },
    {
      key: "newAddressAwaitingToBeSaved",
      title: "Address awaiting to be saved",
      type: "boolean",
    },
    { key: "optedOut", title: "Opted Out", type: "boolean" },
    {
      key: "phoneContactMethod",
      title: "Phone contact method",
      type: "enum",
      showValue: (value) => (
        <ShowValue enumKey="phoneContactMethod" value={value} />
      ),
      values: enums?.phoneContactMethod || [],
    },
    { key: "lastEditedBy", title: "Last edited by", type: "string" },
    { key: "note", title: "Note", type: "string" },
    {
      key: "dateEnteredInPlantiful",
      title: "Date entered plantiful",
      type: "string",
    },
    { key: "isTest", title: "isTest", type: "boolean" },
    { key: "source", title: "Source", type: "string" },
    {
      key: "exception",
      title: "Exception",
      type: "enum",
      values: exceptionOptions,
      form: { allowCreate: true },
      enumType: "checkbox",
    },
    { key: "signupSunset", title: "Allowed Activation End Date", type: "date" },
    { key: "deleted", title: "Deleted", type: "boolean", simple: true },
    { key: "created", title: "Created at", type: "date", simple: true },
    { key: "updated", title: "Updated at", type: "date", simple: true },
  ];
};

export default familyColumns;
