import React, { useState } from "react";
import { Form } from "react-bootstrap";

const FilterBoolean = ({
  name,
  value: pValue,
  onChange,
  labels = { true: "Yes", false: "No", all: "All" },
}) => {
  const [value, setValue] = useState(() =>
    pValue === "true" ? true : pValue === "false" ? false : pValue
  );

  const setBooleanishValue = (newValue) => {
    setValue(newValue);
    onChange({ target: { value: newValue, name } });
  };

  return (
    <Form.Group className="mb-2">
      <Form.Check
        id={`radio-true-${name}`}
        inline
        label={labels.true}
        checked={value === "1"}
        onChange={() => setBooleanishValue("1")}
        type="radio"
        name={`radio-true-name-${name}`}
      />
      <Form.Check
        id={`radio-false-${name}`}
        inline
        label={labels.false}
        checked={value === "0"}
        onChange={() => setBooleanishValue("0")}
        type="radio"
        name={`radio-false-name-${name}`}
      />
      <Form.Check
        id={`radio-all-${name}`}
        inline
        label={labels.all}
        checked={!value}
        onChange={() => setBooleanishValue("")}
        type="radio"
        name={`radio-all-name-${name}`}
      />
    </Form.Group>
  );
};

export default FilterBoolean;
