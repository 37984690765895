import React, { useState } from "react";
import { OverlayTrigger } from "react-bootstrap";
import { Filter as FilterIcon } from "react-feather";
import FilterPopup from "./FilterPopup";

/**
 * @param {{
 *  onFilter: () => void;
 *  onFilterEmpty: () => void;
 *  onFilterClear: () => void;
 *  noEmpty: boolean;
 *  enumType: "radio"|"checkbox";
 *  filter: any;
 *  type: import("../../pages/tables/columns/family").TableColumnConfig["type"];
 *  options?: Array<any>;
 * }} param0
 */
const Filter = ({
  onFilter,
  onFilterClear,
  noEmpty,
  enumType,
  labels,
  filter,
  type,
  options = [],
  height,
}) => {
  const [show, setShow] = useState(false);

  return (
    <OverlayTrigger
      trigger="click"
      show={show}
      onToggle={setShow}
      placement="bottom"
      overlay={(props) => (
        <FilterPopup
          {...props}
          close={() => setShow(false)}
          onFilter={onFilter}
          onFilterClear={onFilterClear}
          noEmpty={noEmpty}
          enumType={enumType}
          labels={labels}
          filter={filter}
          type={type}
          options={options}
          height={height}
        />
      )}
    >
      <FilterIcon
        fill={filter ? "lightgray" : "none"}
        onClick={(e) => e.stopPropagation()}
        className="feather-sm me-2 hoverable"
      />
    </OverlayTrigger>
  );
};

export default Filter;
