import React, { useCallback, useMemo, useState } from "react";
import { Table, Form, Button, Modal, Spinner } from "react-bootstrap";
import {
  deleteByPhoneNumber,
  disableFamily,
  enableFamily,
} from "../../services/families";
import ContentEditable from "../contentEditable";
import Popconfirm from "../Popconfirm";

const DetailRow = ({ column, item, onItemChange, idKey }) => {
  const onChange = useCallback(
    (value) => {
      onItemChange(item, column.key, value);
    },
    [item[idKey]]
  );

  return (
    <tr>
      <td className="details-table-column-title">{column.title}</td>
      <td>
        <ContentEditable
          value={item[column.key]}
          placeholder="-"
          onChange={onChange}
          column={column}
        />
      </td>
    </tr>
  );
};

const DetailsTable = ({
  item,
  columns,
  onItemChange,
  idKey,
  hasCampaignFilter,
  onRemoveCampaignResponse,
}) => {
  const [search, setSearch] = useState("");
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showDisableConfirm, setShowDisableConfirm] = useState(false);
  const [reason, setReason] = useState("");
  const [enableConfirm, setEnableConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [campaignLoading, setCampaignLoading] = useState(false);

  const filteredColumns = useMemo(() => {
    const notHidden = columns.filter((column) => !column.hidden);
    const searched = notHidden.filter(
      (column) =>
        !search ||
        column.key.toLowerCase().includes(search.toLowerCase()) ||
        (item[column.key] &&
          item[column.key]
            .toString()
            .toLowerCase()
            .includes(search.toLowerCase()))
    );
    return searched;
  }, [item[idKey], search]);

  const closeDeleteModal = () => {
    setShowDeleteConfirm(false);
    setShowDisableConfirm(false);
  };

  const closeEnableModal = () => setEnableConfirm(false);

  const handleFamilyDeleteOrDisable = async () => {
    setLoading(true);
    try {
      if (showDeleteConfirm) {
        await deleteByPhoneNumber(item.phone, reason);
        onItemChange(item, "deleted", true);
      } else {
        await disableFamily(item.id, reason);
        onItemChange(item, "active", false);
      }
      setReason("");
      closeDeleteModal();
    } catch (err) {}
    setLoading(false);
  };

  const handleFamilyEnable = async () => {
    await enableFamily(item.id);
    onItemChange(item, "active", true);
    closeEnableModal();
  };

  const handleRemoveCampaignResponse = async (id) => {
    setCampaignLoading(true);
    await onRemoveCampaignResponse(id);
    setCampaignLoading(false);
  };

  return (
    <>
      <div className="my-2">
        <Popconfirm
          title="Are you sure?"
          show={enableConfirm}
          onCancel={closeEnableModal}
          onOk={handleFamilyEnable}
        />
        <Modal
          show={showDeleteConfirm || showDisableConfirm}
          onHide={closeDeleteModal}
          size="sm"
        >
          <Modal.Header closeButton className="text-white" />
          <Modal.Body className="text-center m-1">
            <Modal.Title className="mb-3">
              <h3>
                Please input the reason to{" "}
                {showDisableConfirm ? "disable" : "delete"} the family
              </h3>
            </Modal.Title>
            <Form.Control
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              placeholder="Reason"
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeDeleteModal}>
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={handleFamilyDeleteOrDisable}
              disabled={!reason || loading}
            >
              {loading && (
                <Spinner animation="border" size="sm" className="me-2" />
              )}
              {showDisableConfirm ? "Disable" : "Delete"}
            </Button>
          </Modal.Footer>
        </Modal>
        {item.active && (
          <Button
            variant="warning"
            className="me-2"
            onClick={() => setShowDisableConfirm(true)}
            size="sm"
          >
            Disable
          </Button>
        )}
        {!item.active && (
          <Button
            variant="success"
            className="me-2"
            onClick={() => setEnableConfirm(true)}
            size="sm"
          >
            Enable
          </Button>
        )}
        <Button
          variant="danger"
          className="me-2"
          size="sm"
          onClick={() => setShowDeleteConfirm(true)}
        >
          Delete
        </Button>
        {hasCampaignFilter && (
          <Button
            variant="outline-danger"
            className="me-2"
            size="sm"
            onClick={() => handleRemoveCampaignResponse(item[idKey])}
          >
            {campaignLoading && (
              <Spinner animation="border" size="sm" className="me-2" />
            )}
            Remove campaign response
          </Button>
        )}
      </div>
      <Form.Group controlId={`retax-search-${idKey}`}>
        <Form.Control
          value={search}
          size="sm"
          className="mt-2 mb-2"
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search . . ."
        />
      </Form.Group>
      <Table size="sm">
        <tbody>
          {filteredColumns.map((column) => (
            <DetailRow
              key={`${idKey}-${column.key}`}
              column={column}
              item={item}
              idKey={idKey}
              onItemChange={onItemChange}
            />
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default DetailsTable;
