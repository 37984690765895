import { useEffect } from "react";

export default function useClickOutside(ref, callback = () => {}) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setTimeout(callback);
      }
    }

    document.addEventListener("mouseup", handleClickOutside);
    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
    // eslint-disable-next-line
  }, [ref.current]);
}
