import React from "react";
import { Container, Spinner } from "react-bootstrap";

const Loader = () => (
  <Container
    fluid
    className="h-100 d-flex justify-content-center align-items-center"
  >
    <Spinner animation="border" variant="primary" />
  </Container>
);

export default Loader;
