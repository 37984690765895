import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import RTable from "../../components/table";
import FormModal from "../../components/FormModal";
import Popconfirm from "../../components/Popconfirm";
import { DistributionContext } from "../../contexts/DistributionContext";
import { TableSettingsContext } from "../../contexts/TableSettingsContext";
import { UtilityContext } from "../../contexts/UtilityContext";
import { useNavigate } from "react-router-dom";
import useScreenSize from "../../hooks/useScreenSize";
import distributionColumns from "../tables/columns/distribution";

const Distributions = () => {
  const { hiddenColumns } = useContext(TableSettingsContext);
  const { dropdownValues, initDropdownValues } = useContext(UtilityContext);
  const {
    distributions,
    loadDistributions,
    addDistribution,
    deleteDistribution,
    loading,
  } = useContext(DistributionContext);
  const [idToDelete, setIdToDelete] = useState(0);

  const createFormRef = useRef({ open() {} });
  const navigate = useNavigate();

  const { height } = useScreenSize();
  const tableHeight = Math.max(height - 230, 360);

  const columns = useMemo(
    () =>
      distributionColumns(dropdownValues, {
        onDeleteClick: (data) => setIdToDelete(data.id),
      })
        .filter((column) => !hiddenColumns.distributions?.[column.key])
        .map((column) => {
          if (column.type === "enum" && !column.values?.length) {
            return {
              ...column,
              values: dropdownValues[column.key] || [],
            };
          }

          return column;
        }),
    // eslint-disable-next-line
    [hiddenColumns.distributions, dropdownValues]
  );

  useEffect(() => {
    if (!dropdownValues.inited) {
      initDropdownValues();
      return;
    }
    loadDistributions({ forceLoad: true });
  }, [dropdownValues.inited, loadDistributions]);

  const handleCreate = (data) => addDistribution(data.locationId, data.name);
  const handleDelete = () => {
    deleteDistribution(idToDelete);
    setIdToDelete(0);
  };

  return (
    <>
      <Helmet title="Distributions" />
      <FormModal
        ref={createFormRef}
        title="Create Distribution"
        columns={columns}
        onSubmit={handleCreate}
      />
      <Popconfirm
        title="Are you sure?"
        show={!!idToDelete}
        onOk={handleDelete}
        onCancel={() => setIdToDelete(0)}
      />
      <div className="d-flex justify-content-between">
        <h3>Distributions</h3>
        <Button
          className="shadow btn ms-2"
          onClick={() =>
            createFormRef.current.open({
              locationId: dropdownValues.masbiahLocationId[0].value,
            })
          }
        >
          Create Distribution
        </Button>
      </div>
      <hr />
      <RTable
        data={distributions}
        columns={columns}
        height={tableHeight}
        name="distributions"
        rowsPerPage={300}
        noDetails
        loading={loading}
        onRowClick={(distribution) =>
          navigate(`/distributions/${distribution.id}`)
        }
      />
    </>
  );
};

export default Distributions;
