import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, ButtonGroup } from "react-bootstrap";
import { CheckCircle, Trash, Edit } from "react-feather";
import ActionButton from "./ActionButton";
import { formatDate, objToQuery } from "../../../utils";

/**
 * @typedef {import("../../../services/notes").Note} Note
 * @typedef {import("../../../services/users").User} User
 */
/**
 * @param {Object} props
 * @param {Note} props.note
 * @param {boolean} [props.full]
 * @param {Record<string, User>} props.usersMap
 * @param {(id: number) => void} props.onDelete
 * @param {(id: number) => void} props.onEdit
 * @param {(id: number) => void} props.onResolve
 * @param {(action: "edit"|"delete"|"resolve", id: number) => void} props.onAction
 */
const NoteCard = ({ full = false, note, usersMap, onAction }) => {
  const handleAction = (action) => () => onAction(action, note.id);

  return (
    <Container className="mt-3">
      <Row className="mt-1">
        <Col md={8}>
          <span className="text-dark me-2">
            {usersMap[note.assignedBy]?.userName}
          </span>
          <small>{formatDate(note.created)}</small>
        </Col>
        <Col md={4} className="d-flex justify-content-end">
          {note.noteType === 2 && <small className="pe-2">assignee</small>}
        </Col>
      </Row>
      <Row className="mt-1">
        <Col md={8}>
          <big className="text-dark">{note.text}</big>
          {full && (
            <div className="d-flex flex-column mt-2">
              {!!note.clientId && (
                <small>
                  Customer:
                  <Link
                    to={`/customers?${objToQuery({ clientId: note.clientId })}`}
                    className="ms-1"
                  >
                    {note.clientId}
                  </Link>
                </small>
              )}
              {!!note.bbl && (
                <small>
                  Property:
                  <Link
                    to={`/properties?${objToQuery({ id1: note.bbl })}`}
                    className="ms-1"
                  >
                    {note.bbl}
                  </Link>
                </small>
              )}
              {!!note.year && <small>Year: {note.year}</small>}
            </div>
          )}
        </Col>
        <Col
          md={4}
          className="d-flex justify-content-between flex-column align-items-end"
        >
          {note.noteType === 2 && (
            <span className="text-dark me-2">
              {usersMap[note.assignedTo]?.userName}
            </span>
          )}
          {!!note.completedDate && (
            <>
              <Row>
                <small className="me-2">resolved</small>
              </Row>
              <Row className="pe-2">
                <small className="text-dark mt-1">
                  {formatDate(note.completedDate)}
                </small>
              </Row>
            </>
          )}
          <ButtonGroup className="mt-1" style={{ maxWidth: 128 }}>
            <ActionButton
              Icon={CheckCircle}
              disabled={!!note.completedDate || note.noteType === 1}
              hint="Resolve"
              onClick={handleAction("resolve")}
            />
            <ActionButton
              Icon={Edit}
              hint="Edit"
              onClick={handleAction("edit")}
            />
            <ActionButton
              Icon={Trash}
              hint="Delete"
              onClick={handleAction("delete")}
            />
          </ButtonGroup>
        </Col>
      </Row>
      <hr />
    </Container>
  );
};

export default NoteCard;
