import React from "react";

/**
 * @param {{ size?: number } & React.SVGAttributes<SVGSVGElement>} props
 */
const DoordashIcon = ({ size = 148, color = "black", ...props }) => (
  <svg width={size} height={size} viewBox="8 8 140 140" {...props}>
    <g
      transform="translate(0.000000,148.000000) scale(0.100000,-0.100000)"
      fill={color}
      stroke="none"
    >
      <path
        stroke={color}
        strokeWidth={16}
        d="M136 1081 c-5 -7 163 -11 491 -13 l498 -3 55 -26 c224 -105 283 -386 119 -564 -88 -95 -172 -125 -358 -125 l-124 0 -115 114 c-69 67 -112 118 -108 125 5 8 79 11 248 11 l240 0 34 34 c42 42 47 97 12 141 -42 53 -57 55 -445 55 l-358 1 -118 114 c-64 63 -120 115 -122 115 -28 0 82 -121 208 -228 l38 -32 370 0 371 0 30 -30 c26 -27 30 -36 25 -68 -4 -22 -17 -46 -32 -59 -26 -22 -32 -23 -264 -23 -318 0 -319 -1 -143 -174 l117 -117 160 3 c156 3 162 4 227 35 118 56 193 152 218 281 32 159 -57 330 -208 404 l-67 33 -497 3 c-323 2 -498 -1 -502 -7z"
      />
      <path
        stroke={color}
        strokeWidth={16}
        d="M368 1013 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"
      />
      <path
        stroke={color}
        strokeWidth={16}
        d="M448 1013 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"
      />
      <path
        stroke={color}
        strokeWidth={16}
        d="M683 1013 c76 -2 198 -2 270 0 73 1 11 3 -138 3 -148 0 -208 -2 -132 -3z"
      />
      <path
        stroke={color}
        strokeWidth={16}
        d="M1098 1003 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"
      />
      <path
        stroke={color}
        strokeWidth={16}
        d="M1140 990 c8 -5 20 -10 25 -10 6 0 3 5 -5 10 -8 5 -19 10 -25 10 -5 0 -3 -5 5 -10z"
      />
      <path
        stroke={color}
        strokeWidth={16}
        d="M1229 934 c32 -28 64 -69 77 -99 13 -27 24 -44 24 -37 0 41 -80 145 -132 173 -13 7 1 -10 31 -37z"
      />
      <path
        stroke={color}
        strokeWidth={16}
        d="M1332 755 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"
      />
      <path
        stroke={color}
        strokeWidth={16}
        d="M1330 676 c0 -35 -20 -86 -54 -136 -16 -25 -22 -39 -12 -31 30 25 66 96 72 144 4 26 4 47 0 47 -3 0 -6 -11 -6 -24z"
      />
    </g>
  </svg>
);

export default DoordashIcon;
