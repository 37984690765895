import React, { useEffect, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { Upload } from "react-feather";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { Cell, Column, HeaderCell, Table } from "rsuite-table";
import useScreenSize from "../../hooks/useScreenSize";
import { attachFamiliesToDistributionWithFile } from "../../services/distributions";
import { filterFamilies } from "../../services/families";

const CheckCell = ({ rowData, onChange, checkedKeys, ...props }) => (
  <Cell {...props} style={{ padding: 0 }}>
    <Form.Check
      name={rowData.id}
      className="mt-2"
      onChange={onChange}
      checked={checkedKeys.some((item) => item === rowData.id)}
    />
  </Cell>
);

const DistributionFamilies = () => {
  const { id } = useParams();
  const [distributionFamilies, setDistributionFamilies] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);

  const { height } = useScreenSize();
  const tableHeight = Math.max(height - 230, 360);

  useEffect(() => {
    filterFamilies({ distributionId: id }).then((result) => {
      setDistributionFamilies(result.items);
    });
  }, [id]);

  const handleCheck = (e) => {
    const id = Number(e.target.name);
    const newCheckedKeys = e.target.checked
      ? [...checkedKeys, id]
      : checkedKeys.filter((item) => item !== id);

    if (newCheckedKeys.length === distributionFamilies.length) {
      setAllChecked(true);
    } else if (allChecked) {
      setAllChecked(false);
    }
    setCheckedKeys(newCheckedKeys);
  };

  const handleCheckAll = (e) => {
    const checked = e.target.checked;
    setAllChecked(checked);
    setCheckedKeys(checked ? distributionFamilies.map((item) => item.id) : []);
  };

  const onSelectFile = (e) => {
    if (!e.target.files?.length) {
      return;
    }

    setUploadLoading(true);
    attachFamiliesToDistributionWithFile(id, e.target.files[0])
      .then(() => filterFamilies({ distributionId: id }))
      .then((result) => setDistributionFamilies(result.items))
      .finally(() => setUploadLoading(false));
    e.target.value = null;
  };

  return (
    <>
      <Helmet title="Distribution Families" />
      <div className="d-flex justify-content-between mb-3">
        <h3>Manage participating families</h3>
        <input
          id="families-file-upload"
          type="file"
          hidden
          onChange={onSelectFile}
        />
        <label
          htmlFor="families-file-upload"
          className="btn btn-primary mb-3"
          style={{ cursor: "pointer" }}
        >
          {uploadLoading && (
            <Spinner animation="border" size="sm" className="me-2" />
          )}
          <span className="me-2">Import Families</span>
          <Upload size={24} />
        </label>
      </div>
      <Table
        height={tableHeight}
        data={distributionFamilies}
        id="distributionFamiliesTable"
      >
        <Column width={50} align="center">
          <HeaderCell style={{ padding: 0 }}>
            <div style={{ lineHeight: "40px" }}>
              <Form.Check
                className="mt-2"
                checked={allChecked}
                onChange={handleCheckAll}
                disabled={!distributionFamilies.length}
              />
            </div>
          </HeaderCell>
          <CheckCell
            dataKey="id"
            checkedKeys={checkedKeys}
            onChange={handleCheck}
          />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>First Name</HeaderCell>
          <Cell dataKey="firstName" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Last Name</HeaderCell>
          <Cell dataKey="lastName" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Address</HeaderCell>
          <Cell dataKey="addressLine1" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Phone</HeaderCell>
          <Cell dataKey="phone" />
        </Column>
      </Table>
    </>
  );
};

export default DistributionFamilies;
