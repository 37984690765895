import {
  Home,
  CheckCircle,
  Users,
  Box,
  MessageCircle,
  AlertCircle,
} from "react-feather";
import CampaignIcon from "../icons/CampaignIcon";
import TCSettingsIcon from "../TCSettingsIcon";
// import roles from "../../utils/roles";

const pagesSection = [
  {
    href: "/dashboard",
    icon: Box,
    title: "Dashboard",
  },
  {
    href: "/families",
    icon: Home,
    title: (
      <>
        Families <TCSettingsIcon path="families" />
      </>
    ),
  },
  {
    href: "/distributions",
    icon: CheckCircle,
    title: (
      <>
        Distributions <TCSettingsIcon path="distributions" />
      </>
    ),
  },
  {
    href: "/users",
    // role: [roles.ADMIN], // uncomment this when roles are implemented,
    icon: Users,
    title: "Users",
  },
  {
    href: "/messages",
    icon: MessageCircle,
    title: "Messages",
  },
  {
    href: "/campaign",
    icon: CampaignIcon,
    title: "Campaign",
  },
  {
    href: "/notRecievedReports",
    icon: AlertCircle,
    title: "Not Recieved Reports",
  },
];

const navItems = [
  {
    title: "",
    pages: pagesSection,
  },
];

export default navItems;
