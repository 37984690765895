import React, { useState } from "react";
import useLocalStorage from "../hooks/useLocalStorage";

const initialState = {
  path: "",
  setPath() {},
  hiddenColumns: {},
  setHiddenColumns() {},
};

const TableSettingsContext = React.createContext(initialState);

function TableSettingsProvider({ children }) {
  const [path, setPath] = useState(initialState.path);
  const [hiddenColumns, setHiddenColumns] = useLocalStorage(
    "hidden-columns",
    {}
  );

  return (
    <TableSettingsContext.Provider
      value={{
        path,
        setPath,
        hiddenColumns,
        setHiddenColumns,
      }}
    >
      {children}
    </TableSettingsContext.Provider>
  );
}

export { TableSettingsProvider, TableSettingsContext };
