/**
 *
 * @param {Record<string, Array<string>>} optionArrays
 * @param {Record<string, Array<string>>} enums
 * @param {Record<string, Record<string, string>>} backgroundByValues
 * @param {import("react").FC<{ item: any }>} NotesIcon
 * @returns {Array<import("./family").TableColumnConfig>}
 */
const getTaxCommissionColumns = (
  optionArrays,
  enums,
  backgroundByValues,
  NotesIcon
) => {
  const numberFormatter = new Intl.NumberFormat();
  const ShowValue = ({ value = null, enumKey }) =>
    enums?.[enumKey]?.[value] ? (
      <span title={enums?.[enumKey]?.[value]?.name}>
        {enums?.[enumKey]?.[value]?.displayNumber}
      </span>
    ) : (
      value
    );
  return [
    {
      key: "index",
      title: "No.",
      render: (keyData, rowData) => (
        <div className="d-flex justify-content-between">
          <span style={{ minWidth: 28 }}>{keyData}</span>
          <NotesIcon item={rowData} />
        </div>
      ),
      noResize: true,
      noSort: true,
      noFilter: true,
      fixed: "left",
      width: 54,
    },
    { key: "notes", title: "Notes", type: "string" },
    { key: "dateSent", title: "Date Sent", type: "date", dateType: "day" },
    {
      key: "dateReceived",
      title: "Date Received",
      type: "date",
      dateType: "day",
    },
    {
      key: "dateReceivedIncomeExpenses",
      title: "Date Received Income Expenses",
      type: "date",
      dateType: "day",
    },
    {
      key: "newClientStatus",
      title: "New Client Status",
      // title: (
      //   <span
      //     style={{
      //       writingMode: "vertical-rl",
      //       transform: "rotate(180deg)",
      //       backgroundColor: "white",
      //     }}
      //   >
      //     New Client Status
      //   </span>
      // ),
      type: "enum",
      values: optionArrays?.newClientStatus || [],
      backgroundByValue: backgroundByValues?.newClientStatus,
      showValue: (value) => (
        <ShowValue enumKey="newClientStatus" value={value} />
      ),
    },
    {
      key: "applicationStatus",
      title: "Application Status",
      type: "enum",
      values: optionArrays?.applicationStatus || [],
      backgroundByValue: backgroundByValues?.applicationStatus,
      showValue: (value) => (
        <ShowValue enumKey="applicationStatus" value={value} />
      ),
    },
    {
      key: "reasonForNotFilling",
      title: "Reason For Not Filling",
      type: "string",
    },
    {
      key: "inHouseStatus",
      title: "In House Status",
      type: "enum",
      values: optionArrays?.inHouseStatus || [],
      backgroundByValue: backgroundByValues?.inHouseStatus,
      showValue: (value) => (
        <ShowValue enumKey="applicationStatus" value={value} />
      ),
    },
    {
      key: "clipadData.currentYearPassReason",
      title: "Current Year Pass Reason",
      type: "enum",
      values: optionArrays?.["clipadData.currentYearPassReason"] || [],
      backgroundByValue:
        backgroundByValues?.["clipadData.currentYearPassReason"],
      showValue: (value) => (
        <ShowValue enumKey="clipadData.currentYearPassReason" value={value} />
      ),
    },
    {
      key: "clipadData.previousYearPassReason",
      title: "Previous Year Pass Reason",
      type: "enum",
      values: optionArrays?.["clipadData.currentYearPassReason"] || [],
      backgroundByValue:
        backgroundByValues?.["clipadData.currentYearPassReason"],
      showValue: (value) => (
        <ShowValue enumKey="clipadData.currentYearPassReason" value={value} />
      ),
    },
    {
      key: "iestatus",
      title: "I & E Status",
      type: "enum",
      values: optionArrays?.["iestatus"] || [],
      backgroundByValue: backgroundByValues?.["iestatus"],
      showValue: (value) => <ShowValue enumKey="iestatus" value={value} />,
    },
    {
      key: "clipadData.iestatusNotRequiredReason",
      title: "I & E Status Not Required Reason",
      type: "enum",
      values: optionArrays?.["clipadData.iestatusNotRequiredReason"],
      backgroundByValue:
        backgroundByValues?.["clipadData.iestatusNotRequiredReason"],
      showValue: (value) => (
        <ShowValue
          enumKey="clipadData.iestatusNotRequiredReason"
          value={value}
        />
      ),
    },
    {
      key: "rrstatus",
      title: "RR Status",
      type: "enum",
      values: optionArrays?.rrstatus,
      backgroundByValue: backgroundByValues?.rrstatus,
      showValue: (value) => <ShowValue enumKey="rrstatus" value={value} />,
    },
    {
      key: "incomeExpensesQuickReview",
      title: "Income Expenses Quick Review",
      type: "boolean",
    },
    {
      key: "tc200type",
      title: "TC 200 Type",
      type: "enum",
      values: optionArrays?.["clipadData.tc200status"],
      backgroundByValue: backgroundByValues?.["clipadData.tc200status"],
      showValue: (value) => (
        <ShowValue enumKey="clipadData.tc200status" value={value} />
      ),
    },
    // {
    //   key: "clipadData.tc200status",
    //   title: "TC 200 Status",
    //   type: "string",
    // },
    {
      key: "clipadData.tc200reason",
      title: "TC 200 Reason",
      type: "enum",
      values: optionArrays?.["clipadData.tc200reason"],
      backgroundByValue: backgroundByValues?.["clipadData.tc200reason"],
      showValue: (value) => (
        <ShowValue enumKey="clipadData.tc200reason" value={value} />
      ),
      width: 200,
    },
    {
      key: "tc230type",
      title: "TC 230 Type",
      type: "enum",
      values: optionArrays?.["clipadData.tc200status"],
      backgroundByValue: backgroundByValues?.["clipadData.tc200status"],
      showValue: (value) => (
        <ShowValue enumKey="clipadData.tc200status" value={value} />
      ),
    },
    // {
    //   key: "clipadData.tc230status",
    //   title: "TC 230 Status",
    //   type: "string",
    // },
    {
      key: "tc150type",
      title: "TC 150 Type",
      type: "enum",
      values: optionArrays?.["clipadData.tc150status"],
      backgroundByValue: backgroundByValues?.["clipadData.tc150status"],
      showValue: (value) => (
        <ShowValue enumKey="clipadData.tc150status" value={value} />
      ),
    },
    // {
    //   key: "clipadData.tc150status",
    //   title: "TC 150 Status",
    //   type: "string",
    // },
    {
      key: "tc309type",
      title: "TC 309 Type",
      type: "enum",
      values: optionArrays?.["clipadData.tc309status"],
      backgroundByValue: backgroundByValues?.["clipadData.tc309status"],
      showValue: (value) => (
        <ShowValue enumKey="clipadData.tc309status" value={value} />
      ),
    },
    // {
    //   key: "clipadData.tc309status",
    //   title: "TC 309 Status",
    //   type: "string",
    // },
    {
      key: "clipadData.clientId",
      title: "Customer",
      type: "string",
      simple: true,
      noEmpty: true,
      width: 100,
    },
    {
      key: "bbl",
      title: "BBL",
      type: "string",
      simple: true,
      noEmpty: true,
      width: 100,
    },
    {
      key: "clipadData.propertyAddress",
      title: "Property Address",
      type: "string",
      simple: true,
      noEmpty: true,
      width: 150,
    },
    {
      key: "clipadData.tentActTotal",
      title: "Tent Act Total",
      type: "number",
      showValue: numberFormatter.format,
    },
    {
      key: "clipadData.propertyBldgClass",
      title: "BLDG Class",
      type: "enum",
      values: optionArrays?.["clipadData.propertyBldgClass"],
      backgroundByValue: backgroundByValues?.["clipadData.propertyBldgClass"],
      showValue: (value) => (
        <ShowValue enumKey="clipadData.propertyBldgClass" value={value} />
      ),
    },
    {
      key: "clipadData.propertyTaxClass",
      title: "Tax Class",
      type: "enum",
      values: optionArrays?.["clipadData.propertyTaxClass"],
      backgroundByValue: backgroundByValues?.["clipadData.propertyTaxClass"],
      showValue: (value) => (
        <ShowValue enumKey="clipadData.propertyTaxClass" value={value} />
      ),
    },
    {
      key: "email",
      title: "Email",
      type: "string",
    },
    {
      key: "email2",
      title: "Email 2",
      type: "string",
    },
    // {
    //   key: "workbookStatus",
    //   title: "Workbook Status",
    //   type: "string",
    // },
    {
      key: "workbookCreated",
      title: "Workbook Created",
      type: "boolean",
    },
    {
      key: "uploadNumber",
      title: "TC 10 Number",
      type: "string",
    },
    {
      key: "uploadDate",
      title: "Filling Date",
      type: "date",
      dateType: "day",
    },
    {
      key: "tc150uploadNumber",
      title: "TC 10 / 150 number",
      type: "string",
    },
    {
      key: "tc150uploadDate",
      title: "TC 150 Filling Date",
      type: "date",
      dateType: "day",
    },
    {
      key: "isVipClient",
      title: "VIP Client",
      type: "boolean",
    },
    {
      key: "clipadData.isVipProperty",
      title: "VIP Prop",
      type: "boolean",
    },
    {
      key: "applicationFee",
      title: "App Fee",
      type: "number",
    },
    {
      key: "paymentRef",
      title: "Payment Ref",
      type: "string",
    },
    // {
    //   key: "clipadData.previousYearAppForm",
    //   title: "Previous Year App Form",
    //   type: "string",
    // },
    {
      key: "clipadData.currentYearAppForm",
      title: "Current Year App Form",
      type: "string",
    },
    {
      key: "clipadData.previousYearPetitionFiled",
      title: "Previous Year Petition Filed",
      type: "boolean",
    },
    {
      key: "clipadData.previousYearHearingStatus",
      title: "Previous Year Hearing Status",
      type: "enum",
      values: optionArrays?.["clipadData.previousYearHearingStatus"],
      backgroundByValue:
        backgroundByValues?.["clipadData.previousYearHearingStatus"],
      showValue: (value) => (
        <ShowValue
          enumKey="clipadData.previousYearHearingStatus"
          value={value}
        />
      ),
    },
    {
      key: "clipadData.dateBblCreated",
      title: "Date BBL Created",
      type: "date",
      dateType: "day",
      simple: true,
      width: 100,
    },
    {
      key: "clipadData.managedBy",
      title: "Managed By",
      type: "string",
      simple: true,
    },
    {
      key: "clipadData.accountRepresentative",
      title: "Representative",
      type: "string",
      simple: true,
      width: 100,
    },
    {
      key: "clipadData.finalActualTotal",
      title: "Final Actual Total",
      type: "number",
    },
    {
      key: "clipadData.previousYearFinalActTotal",
      title: "Previous Year Final Act Total",
      type: "number",
    },
    { key: "clipadData.diff", title: "Diff", type: "number", simple: true },
    { key: "clipadData.tcnotes", title: "TC Notes", type: "string" },
    {
      key: "clipadData.__maxFilingYear",
      title: "Max Filing Year",
      type: "string",
      simple: true,
    },
    {
      key: "clipadData.previousYearAppForm",
      title: "Previous Year App Form",
      type: "string",
    },
    {
      key: "clipadData.previousYearPetitionFiledDate",
      title: "Previous Year Petition Filed Date",
      type: "date",
    },
    {
      key: "created",
      title: "Created",
      type: "date",
      dateType: "day",
      simple: true,
      noEmpty: true,
      width: 100,
    },
  ];
};

export default getTaxCommissionColumns;
