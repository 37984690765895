import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";

import { Button, Modal, Form, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import PasswordField from "./PasswordField";
import { getEnumOption } from "../utils";

/**
 * @type {React.ForwardRefRenderFunction<{ init: (values: any) => void }, {
 *  title?: React.ReactNode;
 *  submitText?: React.ReactNode;
 *  columns: Array<import("../pages/tables/columns/family").TableColumnConfig>;
 *  onSubmit: (values: any) => Promise<void>;
 * }>}
 */
const FormModal = forwardRef((props, ref) => {
  const { title, submitText = "CREATE", columns, onSubmit } = props;
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const initialValuesR = useRef({});
  const validationSchema = useMemo(() => {
    const additions = {};
    columns.forEach((column) => {
      if (column.form && !!column.form.required) {
        additions[column.key] = Yup.string()
          .max(255)
          .required(`${column.title} is required`);
      }
    });

    return Yup.object().shape(additions);
    // eslint-disable-next-line
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      open(values) {
        initialValuesR.current = values || {};
        setShow(true);
      },
    }),
    []
  );

  useEffect(() => {
    if (!show) {
      initialValuesR.current = {};
    }
  }, [show]);

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Formik
          initialValues={initialValuesR.current}
          validationSchema={validationSchema}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            setLoading(true);
            try {
              await onSubmit(values);
              setShow(false);
            } catch (error) {
              const message =
                error?.[0]?.description ||
                error.message ||
                "Something went wrong";

              setStatus({ success: false });
              setErrors({ submit: message });
              setSubmitting(false);
            }
            setLoading(false);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
          }) => (
            <Form id="my-form" onSubmit={handleSubmit}>
              {errors.submit && (
                <div className="text-danger mb-2">{errors.submit}</div>
              )}
              {columns
                .filter(
                  (column) =>
                    column.form &&
                    (!column.form.hide || !column.form.hide(values))
                )
                .map((column) => {
                  const fieldProps = {
                    name: column.key,
                    value: values[column.key] || "",
                    isInvalid: Boolean(
                      touched[column.key] && errors[column.key]
                    ),
                    onBlur: handleBlur,
                    onChange: handleChange,
                  };
                  return (
                    <Form.Group key={column.key} className="mb-3">
                      <Form.Label>{column.title}</Form.Label>
                      {column.type === "enum" ? (
                        column.form.radio ? (
                          <div>
                            {column.values.map((option, index) => {
                              const { value, title } = getEnumOption(option);
                              return (
                                <Form.Check
                                  inline
                                  checked={
                                    values[column.key]
                                      ? values[column.key] === value
                                      : index === 0
                                  }
                                  key={value}
                                  value={value}
                                  type="radio"
                                  {...fieldProps}
                                  onChange={() =>
                                    handleChange({
                                      target: {
                                        name: fieldProps.name,
                                        value: value,
                                      },
                                    })
                                  }
                                  id={column.key + title}
                                  label={title}
                                />
                              );
                            })}
                          </div>
                        ) : (
                          <Form.Select {...fieldProps}>
                            {column.values.map((option) => {
                              const { value, title } = getEnumOption(option);
                              return (
                                <option key={value} value={value}>
                                  {title}
                                </option>
                              );
                            })}
                          </Form.Select>
                        )
                      ) : column.key === "password" ? (
                        <PasswordField {...fieldProps} />
                      ) : (
                        <Form.Control {...fieldProps} />
                      )}
                      {column.form.required && !!touched[column.key] && (
                        <Form.Control.Feedback type="invalid">
                          {errors[column.key]}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  );
                })}
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          disabled={loading}
          onClick={() => setShow(false)}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="primary"
          disabled={loading}
          form="my-form"
        >
          {loading && <Spinner animation="border" size="sm" className="me-2" />}
          {submitText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
});

export default FormModal;
