import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { getMessagesForFamily } from "../../../services/families";

function MessagesTab({ item }) {
  const [currentMessage, setCurrentMessage] = useState([]);
  useEffect(() => {
    getMessagesForFamily(item.id).then((data) => {
      
      let items = [];
      data.fromMessages.forEach(x => {
        x.type= 'inbound message';
        x.time = new Date(x.dateCreated).getTime();
        items.push(x);
      });
      data.toMessages.forEach(x => {
        x.type= 'outbound message';
        x.time = new Date(x.dateCreated).getTime();
        items.push(x);
      });
      data.fromCalls.forEach(x => {
        x.type= 'inbound call';
        x.time = new Date(x.dateCreated).getTime();
        items.push(x);
      });
      data.toCalls.forEach(x => {
        x.type= 'outbound call';
        x.time = new Date(x.dateCreated).getTime();
        items.push(x);
      });
      
      items = items.sort((a, b) => a.time - b.time ).reverse();

      setCurrentMessage(items);
    })
  },[item.id]);

  return (
    <Table size="sm" style={{marginTop: 20}}>      
        {currentMessage?.map((x) => (          
          <tbody>
              <tr>
                <td>{x.type}</td>
                <td>
                  <span>{x.body}</span>
                </td>
              </tr>              
              <tr>
                <td>date time</td>
                <td>
                  <span>{new Date(x.dateCreated).toLocaleString()}</span>
                </td>
              </tr>
              {x.duration && (
                <tr>
                  <td>duration</td>
                  <td>
                    <span>{x.duration} sec</span>
                  </td>
                </tr>
              )}
          </tbody>
        ))}  
    </Table>
  );
}

export default MessagesTab;
