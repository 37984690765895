import React, { useState } from "react";
import { Button, Form, Row, Col, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import Select from "react-select";

/** @typedef {import("../../../services/users").User} User */
/**
 * @param {Object} props
 * @param {Array<User>} props.users
 * @param {(values: any) => Promise<void>} props.onSubmit
 * @param {() => void} props.onClose
 * @param {1|2} [props.noteType]
 * @param {Object} [props.initialValues]
 * @param {string} [props.initialValues.text]
 * @param {1|2} [props.initialValues.noteType]
 * @param {Object} [props.initialValues.assignedTo] User formatted for `react-select`
 * @param {string} [props.initialValues.assignedTo.value] User ID
 * @param {string} [props.initialValues.assignedTo.label] User name to display
 */
const NoteForm = ({
  users,
  onSubmit,
  onClose,
  initialValues = { noteType: 1 },
  noteType,
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <Formik
      initialValues={{ ...initialValues, noteType }}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        setLoading(true);
        try {
          await onSubmit(values);
          onClose();
        } catch (error) {
          const message =
            error?.[0]?.description || error.message || "Something went wrong";
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          {errors.submit && (
            <div className="text-danger mb-2">{errors.submit}</div>
          )}
          <Row>
            <Col md={8}>
              <Form.Control
                as="textarea"
                name="text"
                autoFocus
                placeholder="Text . . ."
                style={{ height: 100 }}
                value={values.text}
                isInvalid={Boolean(touched.text && errors.text)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Col>
            <Col md={4}>
              {!noteType && (
                <div className="d-flex flex-row">
                  <Form.Check
                    id="form-radio-noteType-1"
                    name="noteType"
                    type="radio"
                    label="Note"
                    value={1}
                    checked={values.noteType === 1}
                    isInvalid={Boolean(touched.noteType && errors.noteType)}
                    onChange={() =>
                      handleChange({
                        target: {
                          name: "noteType",
                          value: 1,
                        },
                      })
                    }
                  />
                  <Form.Check
                    className="ms-3"
                    id="form-radio-noteType-2"
                    name="noteType"
                    type="radio"
                    label="Task"
                    value={2}
                    checked={values.noteType === 2}
                    isInvalid={Boolean(touched.noteType && errors.noteType)}
                    onChange={() =>
                      handleChange({
                        target: {
                          name: "noteType",
                          value: 2,
                        },
                      })
                    }
                  />
                </div>
              )}
              {values.noteType === 2 && (
                <Select
                  className="react-select-container mt-2"
                  classNamePrefix="react-select"
                  value={values.assignedTo}
                  placeholder="Assigned to"
                  options={users.map((user) => ({
                    value: user.id,
                    label: user.userName,
                  }))}
                  onBlur={handleBlur}
                  onChange={(value) =>
                    handleChange({ target: { value, name: "assignedTo" } })
                  }
                />
              )}
            </Col>
          </Row>
          <div className="d-flex justify-content-end mb-3">
            <Button
              variant="secondary"
              onClick={onClose}
              className="me-2"
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={
                loading ||
                !values.text ||
                (values.noteType === 2 && !values.assignedTo?.value)
              }
            >
              {loading && (
                <Spinner animation="border" size="sm" className="me-1" />
              )}
              Save
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default NoteForm;
