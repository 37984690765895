import axios from "../utils/axios";

export function getCampaignInfo() {
  return axios.get("/Campaign/GetAll");
}

export function createCampaign(data) {
  return axios.post("/Campaign/CreateCampaign", data);
}

export function createCampaignWithFile({ message, keyword }, file) {
  const formData = new FormData();
  formData.append("file", file);
  return axios.post(
    `/Campaign/CreateCampaignWithFile/${message}/${encodeURIComponent(
      keyword
    )}`,
    formData
  );
}

export function updateCampaign(data) {
  return axios.post("/Campaign/UpdateCampaign", data);
}

export function removeCampaignResponse(campaignId, familyId) {
  return axios.post(
    `/Campaign/RemoveCampaignResponse/${campaignId}/${familyId}`
  );
}
