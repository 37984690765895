/** @param {import("react").SVGProps<SVGSVGElement>} props */
const EmptyIcon = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    {...props}
  >
    <path d="M19.7907 0.714182L19.2858 0.209266C19.0068 -0.0697552 18.5546 -0.0697552 18.2755 0.209266L15.2501 3.23475C13.7987 2.10661 11.9804 1.42848 10 1.42848C5.26602 1.42848 1.42848 5.26602 1.42848 10C1.42848 11.9804 2.10661 13.7987 3.23475 15.2501L0.209266 18.276C-0.0697552 18.555 -0.0697552 19.0072 0.209266 19.2863L0.714182 19.7907C0.993203 20.0698 1.44544 20.0698 1.72446 19.7907L4.74994 16.7653C6.20085 17.8934 8.01962 18.5715 10 18.5715C14.734 18.5715 18.5715 14.734 18.5715 10C18.5715 8.01962 17.8934 6.2013 16.7653 4.74994L19.7907 1.72446C20.0698 1.44544 20.0698 0.992757 19.7907 0.714182V0.714182ZM3.57136 10C3.57136 6.45532 6.45532 3.57136 10 3.57136C11.3857 3.57136 12.6625 4.0227 13.7134 4.77137L4.77092 13.7134C4.02225 12.6625 3.57136 11.3862 3.57136 10ZM16.4286 10C16.4286 13.5447 13.5447 16.4286 10 16.4286C8.61382 16.4286 7.33747 15.9777 6.28612 15.2286L15.2286 6.28657C15.9777 7.33747 16.4286 8.61427 16.4286 10Z" />
  </svg>
);

export default EmptyIcon;
