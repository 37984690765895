import axios from "../utils/axios";

/**
 * @typedef {{
 *  id: string;
 *  created: string;
 *  updated: string;
 *  message: string;
 *  emailSubject: string;
 *  emailBody: string;
 *  playFileUrl: string;
 *  say: string;
 * }} PresetMessage
 */

/**
 * @returns {Promise<Array<PresetMessage>>}
 */
export function getPresetMessages() {
  return axios.get("/PresetMessages/Get");
}

/**
 * @param {Omit<PresetMessage, 'id'|'created'|'updated'>} data
 * @returns {Promise<PresetMessage>}
 */
export function createPresetMessage(data) {
  return axios.post("/PresetMessages/Create", data);
}

/**
 * @param {number} messageId
 * @param {File} file
 */
export function uploadAudioFile(messageId, file) {
  const data = new FormData();
  data.append("file", file);
  return axios.post(`/PresetMessages/UploadAudioFile/${messageId}`, data);
}

/**
 * @param {Omit<PresetMessage, 'created'|'updated'>} data
 */
export function updatePresetMessage(data) {
  return axios.post("/PresetMessages/Update", data);
}
