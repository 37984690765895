import React, { useEffect, useState } from "react";

const TextField = ({
  value: defaultValue,
  placeholder,
  onChange = () => {},
  onEditEnd,
  editClassName = "",
  column,
}) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    if (defaultValue !== value) {
      setValue(defaultValue);
    }
    // for `value` cuz this useEffect is used for watching changes of `defaultValue`
    // eslint-disable-next-line
  }, [defaultValue]);

  return (
    <input
      className={`edit me-0 pe-0 ${editClassName}`}
      spellCheck={false}
      autoFocus
      type={column.type === "number" ? "number" : "text"}
      placeholder={placeholder}
      onChange={(e) => setValue(e.target.value)}
      onBlur={() => {
        if (String(value) !== String(defaultValue)) {
          onChange(value);
        }
        onEditEnd();
      }}
      value={value || ""}
    />
  );
};

export default TextField;
